import React from "react";
import { ReactComponent as RingBoxSVG } from "./ring-box.svg";
import { ReactComponent as GiaCertSVG } from "./gia-cert.svg";
import { ReactComponent as DiamondSizingSVG } from "./diamond-sizing.svg";
import { ReactComponent as FinalReviewSVG } from "./final-review.svg";

export default function RingInfo() {
  return (
    <>
      <div className="ring-builder-info-holder">
        <div className="rbcol1">
          <img
            src={require("img/ring-placeholder-info.png").default}
            alt="ringsBox"
          />
        </div>
        <div className="rbcol2">
          <RingBoxSVG />
          <div className="mt30">
            <h3>Book a Virtual Consultation</h3>
            <p> Have questions about buying this ring?</p>
          </div>
          <div className="mt30">
            <a
              className="btn book-chat-btn"
              href="/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book Now
            </a>
          </div>
        </div>
      </div>

      <div className="ring-the-perfect-ring-container">
        <div className="ring-the-perfect-ring-text">
          The perfect ring to say “Yes” to.
        </div>
        <div className="ring-the-perfect-ring-imgs">
          <img src={require("img/wedding-picture.png").default} alt="pic" />
        </div>
      </div>
      <div className="ring-whats-next-container">
        <div className="ring-whats-next-title">What happens next?</div>
      </div>

      <div className="ring-whats-next-info-container">
        <div className="ring-whats-next-info-text">
          <div className="ring-whats-next-icon">
            <GiaCertSVG />
          </div>
          Your diamond and your ring will be delivered to our fullfilment center
          for inspection by our GIA certified gemologist.
        </div>
        <div className="ring-whats-next-info-text">
          <div className="ring-whats-next-icon">
            <DiamondSizingSVG />
          </div>
          Upon approval, your ring will be sized and the diamond will be hand
          set in to the ring. The ring will be checked for quality assurance.
        </div>
        <div className="ring-whats-next-info-text">
          <div className="ring-whats-next-icon">
            <FinalReviewSVG />
          </div>
          Our GIA certified gemologist will do a final review and place the ring
          for delivery directly to you, for your Happily ever after.
        </div>
      </div>
    </>
  );
}
