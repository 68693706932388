import React from "react";
import {withRouter, Link} from 'react-router-dom';
import PropTypes from "prop-types";

export class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.hideClicked = this.hideClicked.bind(this);
        this.state= {
            clicked: false
        }
    }

    hideClicked = (click) => {
        this.setState({clicked: !this.state.clicked})
        this.props.hideMenu(this.state.clicked);
    }


    render() {
        let url = window.location.href.toString();

        let bgHeaderClass = url.charAt(url.length-1) === '/' ? 'header -index -nobg' : 'header';
        let logoClass = url.charAt(url.length-1) === '/' ? 'logo -inverse' : 'logo';
        let logoTextClass = url.charAt(url.length-1) === '/' ? 'logo-text -accent' : 'logo-text';

		const currentPathname = this.props.location.pathname;
		const comparison = ["/compare-diamonds"].indexOf(currentPathname) !== -1;

        return (
            <div className={bgHeaderClass}>
                <div className="centered-block">
                    <a className={logoClass} href="/">
                        <span className={logoTextClass}>Joolez</span>
                    </a>
                    {comparison &&
                        <div className="header__comparison">
                            Comparison
                        </div>
                    }

                    <div className="header-ctrls">
                        <button className="ctrl -menu -lg" onClick={this.hideClicked}>
                            <span className="ico ico-Menu"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

HeaderComponent.propTypes = {
    clickHandler: PropTypes.func,
    showMenu: PropTypes.bool
  };

export default withRouter(HeaderComponent);
