import React, { Component } from "react";
import { Redirect, Prompt } from "react-router-dom";
import { connect } from "react-redux";

import Modal from "./modal/modal.component";
import Collapse from "components/collapse/collapse.component";
import Loader from "./loader.component";
import { SBIMGSlider } from "components/shop.by.component/productDetails/imgSlider.component";

import { CartService } from "./services/cartService";
import { CustomerService } from "./services/customer.service";
import { DiamondSearch } from "components/services/diamondsearch";
import {
  setGuestEmailOnCart,
  getCustomerCartId,
  setPaymentMethodOnCart,
  placeOrder,
} from "components/services/graphql";
import { AddToCartUtil } from "./utils/addtocart.util";
import { setDiamondId, setCart } from "../redux/diamond/actions";

import { setError } from "../redux/user/actions";

import { ReactComponent as CongratulationsIcon } from "img/congratulations-1.svg";
import config from "./configurations";
import DropIn from "braintree-web-drop-in-react";
import { gtmId } from "./consts";
import TagManager from "react-gtm-module";
import Hashids from "hashids";

import { formatDiamondName } from "components/utils/formatDiamondSize";

const AFFIRM_STATUS_MESSAGE = {
  READY: "Continue with Affirm",
  OPEN: "Loading...",
  SET_PAYMENT: "Finalizing payment",
  PLACE_ORDER: "Approved",
};

function formatAddress(address) {
  if (!address) return {};
  const {
    firstname,
    lastname,
    email,
    city,
    region_code: state,
    postcode: zipcode,
    country_id: country,
    street: [line1, line2],
  } = address;

  return {
    name: {
      full: `${firstname} ${lastname}`,
    },
    address: {
      line1,
      line2,
      city,
      state,
      zipcode,
      country,
    },
    email,
  };
}

class CardHolderReview extends Component {
  instance;
  constructor(props) {
    super(props);
    this.state = {
      paymentType: "credit-card", // affirm | credit-card
      checkoutType: "guest",
      affirmStatus: "READY", // READY | OPEN | SET_PAYMENT | PLACE_ORDER
      cardholderName: "",
      cardNumber0: "",
      cardNumber1: "",
      cardNumber2: "",
      cardNumber3: "",
      expirationMonthOption: 0,
      expirationYearOption: 0,
      cvv: "",
      showModal: false,
      payment: false,
      email: "",
      redirect: false,
      order_id: "",
      items: this.props.cart || [],
      step: 1,
      nonce: "",
      showConfirm: false,
      total: 0,
      emailLoaded: false,
      tax_total: 0,
      grand_total: 0,
      discount: 0,
      shouldBlockNavigation: true,
    };
    this.diamondSvc = new DiamondSearch();
    this.util = new AddToCartUtil();
  }

  componentDidMount() {
    const tagManagerViewArgs = {
      gtmId: gtmId,
      dataLayer: {
        event: "checkout",
        eventLabel: "Checkout View",
        ecommerce: {
          checkout: {
            actionField: { step: 3 },
          },
        },
      },
    };
    TagManager.dataLayer(tagManagerViewArgs);
    let customer = new CustomerService();
    let guestID = localStorage.getItem("guestCartId");

    customer.getCustomer().then((res) => {
      let cart = new CartService();
      cart = !!res.id ? cart.getCustomerCart() : cart.getGuestCart(guestID);
      const checkoutType = !!res.id ? "customer" : "guest";
      cart.then((res) => {
        const {
          billing_address: billingAddress,
          extension_attributes: {
            shipping_assignments: [
              {
                shipping: { address: shippingAddress },
              },
            ] = [{ shipping: {} }],
          } = {},
        } = res;
        this.setState({ checkoutType, shippingAddress, billingAddress });

        if (!res.items) {
          this.props.setError("Order was not placed", res.message);
          return;
        }

        let sum = 0;
        res.items.map((el) => (sum += el.price));

        if (res.billing_address && res.billing_address.email) {
          this.setState({
            email: res.billing_address.email,
            emailLoaded: true,
          });
        } else {
          this.setState({
            emailLoaded: false,
          });
        }

        let diamondsCall = [];
        res.items.forEach((el) => {
          if (el.sku.indexOf("ring") < 0)
            diamondsCall.push(this.diamondSvc.getDiamondById(el.sku));
        });
        Promise.all(diamondsCall).then((diamonds) => {
          res.items.forEach((el) => {
            if (el.sku.indexOf("ring") > -1)
              el.name = el.name.replace(/-/g, " ") + " size";
          });

          this.setState({
            items: res.items,
            total: sum,
            tax_total:
              this.props.tax_total || localStorage.getItem("joolezTaxValue"),
            grand_total:
              this.props.grand_total ||
              localStorage.getItem("joolezGrandTotal"),
            discount:
              this.props.discount || localStorage.getItem("joolezDiscount"),
          });

          this.props.setCart(res.items);
          new DiamondSearch().getDiamondById(res.items[0].sku).then((res) => {
            if (!!res.diamond) this.prepareDiamond(res.diamond);
          });
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.setError("");
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/order-received",
            state: { order_id: this.state.order_id, email: this.state.email },
          }}
        />
      );
    }
  };

  submitOrder = (e) => {
    e.preventDefault();
    this.setState({ shouldBlockNavigation: false, showModal: true });

    if (this.state.paymentType === "credit-card") {
      this.handleSubmitOrder();
    }
    if (this.state.paymentType === "affirm") {
      this.placeOrder();
    }
  };

  handleSubmitOrder = () => {
    let cart = new CartService();
    cart.getCustomerCart().then((res) => {
      if (!!res.id) {
        cart.collectTotalsCustomer().then((res) => {
          cart
            .customerCheckout({
              email: this.state.email,
              paymentMethod: {
                method: "mpbraintree",
                additional_data: {
                  payment_method_nonce: this.state.nonce,
                  storeInVaultOnSuccess: 0,
                },
              },
            })
            .then((res) => {
              if (res.message) {
                this.setState({ showModal: false });
                this.props.setError(res.message);
                return;
              }
              this.props.setCart([]);
              localStorage.removeItem("diamondId");
              this.setState({
                redirect: true,
                order_id: res,
              });
            });
        });
      } else {
        let guestId = localStorage.getItem("guestCartId");
        cart
          .guestCheckout(guestId, {
            email: this.state.email,
            paymentMethod: {
              method: "mpbraintree",
              additional_data: {
                payment_method_nonce: this.state.nonce,
                storeInVaultOnSuccess: 0,
              },
            },
          })
          .then((res) => {
            if (res.message) {
              this.setState({ showModal: false });
              this.props.setError(res.message);
              return;
            }

            this.props.setCart([]);
            localStorage.removeItem("guestCartId");
            localStorage.removeItem("diamondId");
            this.setState({
              redirect: true,
              order_id: res,
            });
          });
      }
    });
  };

  prepareDiamond = (diamond) => {
    diamond.image_file_url =
      diamond && diamond.image_file_url
        ? "http:" + diamond.image_file_url
        : require("./pics/diamond-1.png").default;
    diamond.productLabels = [
      { title: diamond.shape },
      { title: "Your pick", className: "-green" },
      { title: "Great choice!", className: "-rose" },
    ];
    this.setState({ diamond: diamond, productLabels: diamond.productLabels });
  };

  async authorize() {
    // get nonce
    const { nonce } = await this.instance.requestPaymentMethod();
    this.setState({
      nonce: nonce,
      showConfirm: true,
    });
  }

  handleClick(e) {
    const element = e.target;
    if (element.classList.contains("braintree-large-button")) {
      this.setState({
        showConfirm: false,
      });
    }
    if (element.classList.contains("braintree-method")) {
      this.setState({
        showConfirm: true,
      });
    }
  }

  diamondRedirect = (sku) => {
    const hashids = new Hashids();
    let encoded = hashids.encode(sku);
    this.props.setError(sku);
    this.props.history.push("/product-details/" + encoded);
  };

  openAffirm = async () => {
    const {
      email,
      checkoutType,
      total,
      tax_total,
      shippingAddress,
      billingAddress,
      items: cartItems,
    } = this.state;

    this.setState({
      shouldBlockNavigation: false,
      affirmStatus: "OPEN",
    });

    const guestCartId =
      checkoutType === "guest" ? localStorage.getItem("guestCartId") : null;
    const customerCartId =
      checkoutType === "customer" ? await getCustomerCartId() : null;

    if (checkoutType === "guest") {
      setGuestEmailOnCart({ cartId: guestCartId, email });
    }

    const shipping = formatAddress(shippingAddress);
    const billing = formatAddress(billingAddress);
    const items = cartItems.map((item) => ({
      sku: item.sku,
      display_name: item.name,
      unit_price: item.price * 100,
      qty: 1,
      item_type: "physical",
      item_url: "",
      item_image_url: "",
    }));

    window.affirm.checkout({
      merchant: {
        name: "Joolez",
        user_cancel_url: "",
        user_confirmation_url: "",
      },
      billing,
      shipping,
      items,
      metadata: {
        shipping_type: "",
        mode: "modal",
      },
      tax_amount: tax_total * 100,
      total: total * 100,
    });

    window.affirm.checkout.open({
      onFail: (error) => {
        const { reason } = error;
        if (reason !== "canceled") {
          this.props.setError("Something went wrong. Please try again.");
        }
        this.setState({ affirmStatus: "READY" });
      },
      onSuccess: async (res) => {
        const { checkout_token: affirmToken } = res;
        const cartId = checkoutType === "guest" ? guestCartId : customerCartId;

        this.setState({ affirmStatus: "SET_PAYMENT" });
        await setPaymentMethodOnCart({ cartId, affirmToken, checkoutType });
        this.setState({
          affirmStatus: "PLACE_ORDER",
          cartId,
          showConfirm: true,
        });
      },
    });
  };

  placeOrder = async () => {
    const { cartId, checkoutType } = this.state;
    const { orderNumber, error } = await placeOrder({ cartId, checkoutType });

    if (orderNumber) {
      this.props.setCart([]);
      this.setState({
        affirmStatus: "READY",
        order_id: orderNumber,
        redirect: true,
        showModal: false,
      });
      localStorage.removeItem("diamondId");

      if (checkoutType === "guest") {
        localStorage.removeItem("guestCartId");
      }
    }

    if (error) {
      this.props.setError(error);
      this.setState({
        affirmStatus: "READY",
        showModal: false,
        showConfirm: false,
      });
    }
  };

  handlePaymentMethodChange = (e) =>
    this.setState({ paymentType: e.target.id, showConfirm: false });

  render() {
    const { step, paymentType, affirmStatus } = this.state;

    let items = this.state.items.map((item) => {
      return (
        <div className="order-review__table-tr" key={item.item_id}>
          <div className="order-review__table-td _expand">
            <p>Sub total</p>
            <p>1X {formatDiamondName(item.name)}</p>
          </div>
          <div className="fw-600 order-review__table-td _c-green">
            ${this.util.priceFormatter(item.price)}
          </div>
        </div>
      );
    });

    return (
      <div className={`couple _step-${step}`}>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message={
            "Are you sure you want to leave?\nYou might lose your selected diamond"
          }
        />

        <div className="couple__item _step-2">
          <div className="payment-radio-button-container">
            <div className="payment-radio-button-header">
              <div className="checkbox">
                <input
                  readOnly
                  style={{ display: "none" }}
                  type="radio"
                  name="payment-method"
                  id="credit-card"
                  checked={paymentType === "credit-card"}
                  onChange={this.handlePaymentMethodChange}
                />
                <label htmlFor="credit-card">
                  <i /> <span>Credit Card</span>
                </label>
              </div>
            </div>
          </div>
          <div className="payment-radio-button-container">
            <div className="payment-radio-button-header">
              <div className="checkbox">
                <input
                  readOnly
                  style={{ display: "none" }}
                  type="radio"
                  name="payment-method"
                  id="affirm"
                  checked={paymentType === "affirm"}
                  onChange={this.handlePaymentMethodChange}
                />
                <label htmlFor="affirm">
                  <i /> <span>Affirm</span>
                </label>
              </div>
            </div>
          </div>

          <Collapse isOpened={paymentType === "credit-card"}>
            <div onClick={this.handleClick.bind(this)}>
              <DropIn
                options={{ authorization: config.braintreeAuthorizationProd }}
                onInstance={(instance) => (this.instance = instance)}
              />
              {!this.state.showConfirm && (
                <button
                  className="btn form__btn"
                  onClick={this.authorize.bind(this)}
                >
                  Authorize
                </button>
              )}
            </div>
          </Collapse>
          <Collapse isOpened={paymentType === "affirm"}>
            <div className="affirm-payment-container">
              <div className="affirm-logo">
                <img
                  src="https://cdn-assets.affirm.com/images/black_logo-transparent_bg.png"
                  alt="affirm-logo"
                />
                <span>Pay over time</span>
              </div>
              <p>
                You will be redirected to Affirm to securely complete your
                purchase. Just fill out a few pieces of basic information and
                get a real-time decision. Checking your eligibility won't affect
                your credit score.
              </p>
              <button
                className="btn form__btn"
                onClick={this.openAffirm}
                disabled={
                  this.state.items.length === 0 || affirmStatus !== "READY"
                }
              >
                {AFFIRM_STATUS_MESSAGE[affirmStatus]}
              </button>
            </div>
          </Collapse>

          {this.state.showConfirm && (
            <button
              type="submit"
              className="btn form__btn under-md"
              onClick={this.submitOrder}
            >
              Confirm payment
            </button>
          )}

          {this.renderRedirect()}
          {this.state.showModal &&
            (this.state.payment ? (
              <section className="success">
                <header className="success__header">
                  <div className="success__ico _success">
                    <CongratulationsIcon />
                  </div>
                  <h2 className="success__title">Payment successful</h2>
                </header>
                <div className="success__ico _ribbon">
                  <span className="ico ico-Ribbon"></span>
                </div>
              </section>
            ) : (
              <Modal
                canClose={this.state.payment}
                modalClicked={(e) => this.setState({ showModal: false })}
              >
                <Loader />
              </Modal>
            ))}
        </div>

        <div className="couple__item _step-1">
          <div className="order-review couple__review">
            <SBIMGSlider
              className="order-review__slider _bd-b"
              imgs={[
                (this.state.diamond && this.state.diamond.image_file_url) || "",
              ]}
              labels={this.state.productLabels}
              noSlider={true}
            />
            <div className="text-1 fw-300 order-review__table">
              {items}
              <div className="order-review__table-tr _divider">
                <div className="order-review__table-td _expand">Tax Amount</div>
                <div className="fw-600 order-review__table-td _c-green">
                  ${this.util.priceFormatter(this.state.tax_total)}
                </div>
                <div className="order-review__table-td"></div>
              </div>
              {this.state.discount !== 0 && (
                <div className="order-review__table-tr ">
                  <div className="order-review__table-td _expand">
                    Discount Amount
                  </div>
                  <div className="fw-600 order-review__table-td _c-green">
                    -$
                    {this.util
                      .priceFormatter(this.state.discount)
                      .replace("-", "")}
                  </div>
                  <div className="order-review__table-td"></div>
                </div>
              )}
              <div className="order-review__table-tr _divider">
                <div className="order-review__table-td _expand">
                  Grand Total
                </div>
                <div className="fw-600 order-review__table-td _c-green">
                  ${this.util.priceFormatter(this.state.grand_total)}
                </div>
              </div>
            </div>
            <div className="container__buttons couple__submit">
              <button
                className="btn under-md"
                onClick={() => this.setState({ step: 2 })}
              >
                Continue
              </button>
              {this.state.showConfirm && (
                <button
                  type="submit"
                  className="btn form__btn over-md"
                  onClick={this.submitOrder}
                >
                  Confirm payment
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.user.error,
  cart: state.diamond.cart,
  grand_total: state.diamond.grand_total,
  tax_total: state.diamond.tax_total,
  discount: state.diamond.discount,
});

const mapDispatchToProps = {
  setDiamondId,
  setCart,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardHolderReview);
