import React from "react";

const EducationInstructions = () => {
	return (
		<div className="education__instructions">
			<p>Follow the green bars</p>
			<div className="level">
				<span/><span/><span/><span/>
			</div>
		</div>
	)
};

export default EducationInstructions;
