import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fb } from './utils/fb';
import chatIcon from 'fonts/Joolez_font/Icons.svg'

class Footer extends Component {

    handleEducation = (e) => {
        this.props.toggleEducation(true);
    }

    handleChat = (e) => {
        // window.customerly('open');
        window.FB.CustomerChat.show();
    }

    render() {
        let activeToDiamond = (this.props.pathname.includes( '/shop-by') || this.props.pathname.includes( '/product-details')) && this.props.pathname !== '/cart'
        let activeToRing = this.props.pathname === '/ring-detail' || this.props.pathname.includes("/ring-builder");

        return (
            <div className="footer">
                <div className='portal-children'></div>
                <div className="centered-block">
                    <Link to='/shop-by' onClick={() => this.forceUpdate()}>
                        <button className="ctrl -lg no-focus">
                            <span className={`ico ico-Diamond ${activeToDiamond ? 'active' : ''}`} />
                        </button>
                    </Link>
                    <Link to='/ring-detail' onClick={() => this.forceUpdate()}>
                        <button className={`ctrl -lg no-focus`}>
                            <span className={`ico ico-Ring ${activeToRing ? 'active' : ''}`} />
                        </button>
                    </Link>

                    <button className="ctrl -lg footer__link" onClick={this.handleEducation}>
                        <div className="level">
                            <span /><span /><span /><span />
                        </div>
                    </button>
                    <a href="#" onClick={this.handleChat}>
                        <img id="chat-svg" src={chatIcon} />
                    </a>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    cart: state.diamond.cart
});

export default connect(mapStateToProps, null)(Footer);
