import {config} from "../../components/configurations";
import {ErrorHandlingService} from "../services/errorHandling.service";

export class GuestService {

    constructor(guestID) {
        this.guestCartPrefix = '/V1/guest-carts/';
        this.cartsPrefix = '/V1/carts/';
        this.diamondPrefix = '/V1/diamondsearch';
        this.customersPrefix = '/V1/customers/';
        this.token = guestID;
        this.error = new ErrorHandlingService();
    }


    getGuestAddress(type) {
        let addressLine = type === 'billing' ? 'billing-address' : 'shipping-methods';
        return fetch(config.magentoURL + this.guestCartPrefix + this.token +  '/'+ addressLine)
        .then( res => res.json())
        .catch( err => this.error.handleError(err));
    }

    setGuestAddress(type,payload) {
        let addressLine = type === 'billing' ? 'billing-address' : 'shipping-information';
        return fetch(config.magentoURL + this.guestCartPrefix + this.token +  '/'+ addressLine,{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then( res => res.json())
        .catch( err => this.error.handleError(err));
    }
}

export default GuestService;
