import React from "react";
import {config} from "../../configurations";
import {connect} from 'react-redux';
import {setCompareDiamonds} from "../../../redux/diamond/actions";

import Checkbox from "components/checkbox/checkbox.component";

export class SBGood extends React.Component {
	constructor(props) {
    super(props);
		this.iconMapping = config.goodsQualityIcons;
		this.state ={
			checked: false
		}
	}

	componentDidMount() {
		this.setState({checked: this.props.selectedDiamonds.indexOf(this.props.diamond_id)>-1})
	}

	checkboxSelected = (e) => {
		let id = this.props.diamond_id;
		let arr = this.props.selectedDiamonds;
    if (arr.indexOf(id)>-1) arr.splice(arr.indexOf(id),1); else arr.push(id);
		this.props.setCompareDiamonds(arr);
		this.setState({checked: this.props.selectedDiamonds.indexOf(this.props.diamond_id)>-1});
		this.props.check();
	}

	diamondSelected = () => {
		this.props.diamondSelected(this.props.diamond_id);
	}

	render() {
		const {
			isCompareCheckboxesVisible,
		} = this.props;

		let total = 0;
		Object.keys(this.props.diamondQuality).forEach(key => {
			if (key !== 'overall') total += this.props.diamondQuality[key];
		});

		return (
			<div className="good">
				<div className="good-inner">
					<div className="label good-label"  onClick={this.diamondSelected}>
						{this.props.label}
					</div>
					{isCompareCheckboxesVisible &&
						<Checkbox
							className="absolute-checkbox"
							name={"checkboxFor"+this.props.diamond_id}
							checked={this.state.checked}
							onChange={this.checkboxSelected}
						/>
					}
					<div className="good-pic" onClick={this.diamondSelected}>
						<img src={this.props.img} alt={`${this.props.label} Shaped Diamond`} className="good-img"/>
					</div>
					<div className="good-description" onClick={this.diamondSelected}>
						<div className="quality-meter good-quality-meter">
							<div
								className="quality-meter_span -cut"
								style={{"height": this.props.diamondQuality.cut/total * 100 + '%'}}
							></div>
							<div
								className="quality-meter_span -color"
								style={{"height": this.props.diamondQuality.color/total * 100 + '%'}}
							></div>
							<div
								className="quality-meter_span -clarity"
								style={{"height": this.props.diamondQuality.clarity/total * 100 + '%'}}
							></div>
							<div
								className="quality-meter_span -carat"
								style={{"height": this.props.diamondQuality.carat/total * 100 + '%'}}
							></div>
            			</div>
						<div className="good-description_line -no-wrap">
							<div className="good-feature">
								{this.props.feature}
							</div>
							<div className={`${this.iconMapping[4]} good-feature_level`}>
								<span/><span/><span/><span/>
							</div>
						</div>
						<div className="good-description_line -no-wrap" onClick={this.diamondSelected}>
							<div className="good-price">
								{this.props.price}
							</div>
							<div className="good-quality">
								{this.props.quality}
							</div>
						</div>
						<div className="good-note" onClick={this.diamondSelected}>{this.props.note}</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	selectedDiamonds: state.diamond.selected_diamonds
});

const mapDispatchToProps ={
	setCompareDiamonds
};

export default connect(mapStateToProps, mapDispatchToProps)(SBGood);
