import {config} from "../../components/configurations";
import {ErrorHandlingService} from "../services/errorHandling.service";

export class AuthorizedCallService {

    constructor() {
        this.storage = config.headerstorage;
        this.error = new ErrorHandlingService();
    }

    setAuthorizationHeader(customerToken) {
        window.localStorage.setItem(this.storage,customerToken);
    }

    removeAuthorizationHeader() {
        window.localStorage.removeItem(this.storage);
    }

    executeAuthorizedCall(url, body, method) {
         let payload = {};
         payload.headers =  {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            "Authorization" : "Bearer " + window.localStorage.getItem(this.storage)
        };
        if (method !== 'get') {
            payload.method = method;
            payload.body = JSON.stringify(body);
        }
        return fetch(url, payload)
        .then( res => res)

    }


}

export default AuthorizedCallService;
