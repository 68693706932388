import React, {Component} from 'react';

class SBShapeSelector extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.id;
    }
    selectShape = (e) => {
        e.preventDefault();
        this.props.shapeSelected(this.id);
    }

    render() {
        const {
            srcSet,
            alt,
            img,
        } = this.props;

        return (
            <button className="ctrl" onClick={this.selectShape}>
                <img
                    className="header-filter_img"
                    src={srcSet ? srcSet[0] : img}
                    srcSet={srcSet && (srcSet[1] ? `${srcSet[1]} 2x` : "") + (srcSet[2] ? `, ${srcSet[2]} 3x` : "")}
                    alt={alt}
                    title={alt}
                />
            </button>
        )
    }
}
export default SBShapeSelector;