import React, { useState } from "react";
import useClickOutside from "hooks/useClickOutside";

export default function RingOptionSelector({
  options = [],
  select,
  selectedOption,
}) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const close = () => setOpen(false);
  const ref = useClickOutside(close);

  const ringOptions = [
    { label: "All Rings", value: "all" },
    ...options,
  ].sort((a, b) =>
    a.value === "all"
      ? -1
      : b.value === "all"
      ? 1
      : a.label.localeCompare(b.label)
  );

  const [selectedLabel, selectedIndex] = ringOptions.reduce(
    (acc, option, index) => {
      if (option.value === selectedOption) {
        return [option.label, index];
      }
      return acc;
    },
    []
  );

  const handleOptionSelect = (e) => {
    select(e);
    close();
  };

  return (
    <div ref={ref} className="ring-options-selector-container">
      <button
        className="label product-info_shape-label -lg"
        onClick={toggleOpen}
        disabled={ringOptions.length === 1}
      >
        {selectedLabel}
        <span className="ico ico-ArrowBottom" />
      </button>
      {open && (
        <div className="shapes-dropdown shapes-dropdown-custom">
          {ringOptions.map((option, index) => (
            <div
              key={option.value}
              id={option.value}
              className={index === selectedIndex ? "selected" : ""}
              onClick={index === selectedIndex ? null : handleOptionSelect}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
