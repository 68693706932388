import React from "react";
import { string, arrayOf, shape } from "prop-types";
import { Link } from "react-router-dom";
import { useDiamond } from "queries/graphql";
import { AddToCartUtil } from "components/utils/addtocart.util";
import { formatDiamondName } from "components/utils/formatDiamondSize";

const cartUtil = new AddToCartUtil();

export default function RingDiamondSelected({ diamondShapes }) {
  const diamondSKU = localStorage.getItem("diamondId");
  const { data: diamond } = useDiamond(diamondSKU);

  const name = formatDiamondName(diamond?.name ?? "No description");
  const price = diamond?.price_range.minimum_price.regular_price.value ?? "";
  const image = diamond?.thumbnail.url;
  const shapeValue = diamond?.nwg_diamond_shape;
  const shapeLabel =
    diamondShapes?.find((shape) => +shape.value === +shapeValue)?.label ??
    "N/A";

  return diamondSKU ? (
    <div className="ring-diamond-selected-container">
      {diamond && (
        <>
          <div className="ring-diamond-selected-img-container">
            <img
              src={image ?? require("img/shape_round.png").default}
              alt="diamondImg"
            />
          </div>
          <div className="ring-diamond-selected-info-container">
            <div className="ring-diamond-selected-buttons">
              <div className="ring-diamond-selected-button-label">
                {shapeLabel}
              </div>
              <Link to="/cart" className="ring-diamond-selected-button-label">
                <img
                  src={require("img/shopping-bag.png").default}
                  alt="shoppingback"
                />
                Your Pick
              </Link>
            </div>
            <div className="ring-diamond-selected-details-containers">
              <div className="ring-diamond-selected-details-subcontainer">
                <div className="ring-diamond-selected-detail">{name}</div>
                <div className="ring-diamond-selected-price">
                  ${cartUtil.priceFormatter(price)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
}

RingDiamondSelected.propTypes = {
  diamondShapes: arrayOf(
    shape({
      value: string,
      label: string,
    })
  ),
};
