import React from "react";
import {AddToCartUtil} from "../../utils/addtocart.util";
import {gtmId} from "../../consts";
import TagManager from "react-gtm-module";
import ParamDescription from './productDetailItems/paramDescription/paramDescription.component'

export default class SBParamSection extends React.Component {
	constructor(props) {
		super(props);
		this.prop = this.props.title === "Price" ? "price" : "size";
		this.state = {width: this.props.percentage};
		this.util = new AddToCartUtil();
	}

	componentDidMount() {
		window.affirm.ui.refresh()
	}

	componentDidUpdate(prevProps) {
		const {
			title,
			value: newValue,
			percentage: newWidth
		} = this.props
		const {
			value: prevValue,
			percentage: prevWidth
		} = prevProps		

		if (title === 'Price' && newValue !== prevValue) {
			window.affirm.ui.refresh()
		}

		if (newWidth !== prevWidth) {
			this.setState ({ width: newWidth })
		}
	}

	onMinus = (item,currency) => {
		if (this.state.width <= 0) return;
		let type = item && item.name ? item.name : currency === 'Size ct' ? 'size' : currency === ' $' ? 'quality' : 'price';
		// if (type == 'Price' || type == 'Size') this.setState({width: this.state.width - 1});
		//  else this.setState({width: this.state.width - item.percent});
		this.props.reduce(type);

		const tagManagerMinusArgs = {
			gtmId: gtmId,
			dataLayer: {
				event: 'Product - Decrease',
				eventLabel: type
			},
		};
		TagManager.dataLayer(tagManagerMinusArgs);
	}

	onPlus = (item,currency) => {
		//if (this.state.width >= 100) return;
		let type = item && item.name ? item.name : currency === 'Size ct' ? 'size' : currency === ' $' ? 'quality' : 'price';
		// if (type == 'Price' || type == 'Size') this.setState({width: this.state.width + 1});
		// 	else this.setState({width: this.state.width + item.percent})
		this.props.increase(type);

		const tagManagerMinusArgs = {
			gtmId: gtmId,
			dataLayer: {
				event: 'Product - Increase',
				eventLabel: type
			},
		};
		TagManager.dataLayer(tagManagerMinusArgs);
	}

	renderHeader = (item) => {
		const {
			type,
			currency,
			style,
			stateofprod,
			loading,
			index,
			title,
			descriptions = []
		} = this.props;

		const isSimple = (item || type === "simple");

		const qualityTitle = descriptions.reduce((title, { name, param }) => {
			let paramTitle = name === 'Cut' ?
				param.split(' ').map(word => word.slice(0, 1)).join('') : param;
			return `${title} ${paramTitle}`
		}, '')

		if(isSimple) {
			let spanClass = "product-param_param " + style;

			return (
				<React.Fragment>
					<div className="product-param_title">
						{this.prop === 'price' &&
							<div>
								{currency}
								{" "}
								<span className={spanClass}>
									{this.util.priceFormatter(stateofprod[this.prop])}
								</span>
							</div>
						}
						{this.prop === 'size' &&
							<div>
								<span className={spanClass}>
									{stateofprod[this.prop]}
								</span>
								{" "}
								{currency}
							</div>
						}
						{this.prop === 'price' &&
							<div
								className="affirm-as-low-as param-section-affirm"
								data-page-type="category"
								data-amount={`${this.props.value * 100}`}
								data-learnmore-show="false"
							/>
						}
					</div>
					<div className="product-param_controls">
						<button
							className={`ctrl -bordered -sm -button-effect buttons-${index}`}
							onClick={() => this.onMinus(item,currency)}
							title={`Decrease ${this.props.title.toLowerCase()}`}
							disabled={loading}
						>
							<span className="ico ico-Minus" />
						</button>
						<span className="control-buttons-title">{this.props.title}</span>
						<button
							className={`ctrl -bordered -sm -button-effect buttons-${index}`}
							onClick={() => this.onPlus(item,currency)}
							title={`Increase ${this.props.title.toLowerCase()}`}
							disabled={loading}
						>
							<span className="ico ico-Plus" />
						</button>
					</div>
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					{title.toLowerCase() === 'quality' &&
						<div className="product-param_title">
							<span className="product-param_param">{qualityTitle}</span>
						</div>
					}
					<div className="product-param_controls">
						<button
							className={`ctrl -bordered -sm -button-effect buttons-${index}`}
							onClick={() => this.onMinus(item,currency)}
							title="Decrease quality"
							disabled={loading}
						>
							<span className="ico ico-Minus"></span>
						</button>
						<span className="control-buttons-title">Quality</span>
						<button
							className={`ctrl -bordered -sm -button-effect buttons-${index}`}
							onClick={() => this.onPlus(item,currency)}
							title="Increase quality"
							disabled={loading}
						>
							<span className="ico ico-Plus"></span>
						</button>
					</div>
				</React.Fragment>
			)
		}
	}

	renderContent = (item) => {
		const {
			type,
			title,
			description,
			moreOptions,
		} = this.props;

		const {
			width,
		} = this.state;

		const isSimple = (item || type === "simple");

		let lineClass = (item && `progress-bar ${item.style}`) || (title === "Price" ? "progress-bar" : "progress-bar -carat");
		let progressStyle = {width: `${(item && item.width) || width}%`};
		let descrStyle = (description || item) ? {} : {display: "none"};
		let icoClass = (item && item.style) || "-carat";

		if(isSimple) {
			return (
				<React.Fragment>
					<div className="progress -lg">
						<div className={lineClass} style={progressStyle} />
					</div>
					<div className="progress-bar_description">
						<div
              className="progress-bar_description-block"
              style={{...progressStyle, ...descrStyle}}
              onClick={() => this.props.openEducationModal("carat")}
            >
							<div className={`ico ico-DiamondSimple product-param_ico ${icoClass}`} />
							<ParamDescription type="carat" el={{}} />
						</div>
					</div>
				</React.Fragment>
			)
		} else {

			if(moreOptions) {

			} else {
				let start = 0;
				let index = 0;
				let lines = this.props.lines.map(el => {
					index++;
					let lineclassName = "progress-bar " + el.class;
					let lineStyle = start === 0 ? {width: el.width + "%"} : {width: el.width + "%", left: start + "%"};
					start += el.width;
					return (
						<div
							className={lineclassName}
							style={lineStyle}
							key={"line" + index}
						></div>
					)
				});

				index = 0;

				let blocks = this.props.descriptions.map(el => {
					index++;
					let blockStyle = {width: el.width + "%"};
					let blockclassName = "ico ico-DiamondSimple product-param_ico " + el.style;
					return (
						<div
							className="progress-bar_description-block"
							style={blockStyle} key={"block" + index}
							onClick={() => this.props.openEducationModal(el.name.toLowerCase())}
						>
							<div className={blockclassName} />
							<div className="progress-bar_description-param">{el.param}</div>
							<div className="progress-bar_description-name">{el.name}</div>
							<div className={`${el.chargeIcon} animate${index}`}>
								<span/><span/><span/><span/>
							</div>
						<ParamDescription type={el.name.toLowerCase()} el={{...el, text: el.param}} />
						</div>
					)
				});

				return (
					<React.Fragment>
						<div className="progress -lg -multiply">
							{lines}
						</div>
						{!moreOptions &&
						<div className="progress-bar_description">
							{blocks}
						</div>
						}
					</React.Fragment>
				)
			}
		}
	}

	render() {
		const {
			moreOptions,
			descriptions,
		} = this.props;

		return !moreOptions ?
			<section className="product-param">
				<header className="product-param_header">
					{this.renderHeader()}
				</header>
				{this.renderContent()}
			</section>
			:
			descriptions.map(i => (
				<section className="product-param" key={i.class}>
					<header className="product-param_header">
						{this.renderHeader(i)}
					</header>
					{this.renderContent(i)}
				</section>
			))

	}
}
