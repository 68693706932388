import React, {Component} from 'react';
import {CustomerService} from "./services/customer.service";
import {Link, Redirect} from 'react-router-dom';
import Loader from "./loader.component";
import config from "./configurations";
import Modal from "./modal/modal.component";
import {AddToCartUtil} from "./utils/addtocart.util"
import Collapse from "components/collapse/collapse.component";

class Account extends Component {
    constructor(props){
        super();
        this.state= {
            id: '',
            redirect: false,
            name: '',
            email: '',
            company: '',
            zip: '',
            address: '',
            state: '',
            country: '',
            phone: '',
            recepient: '',
            companyBilling: '',
            zipBilling: '',
            addressBilling: '',
            stateBilling: '',
            countryBilling: '',
            phoneBilling: '',
            recepientBilling: '',
            recentOrders: [],
            historyOrders: [],
            hasAddress: false,
            addressId: -1
        };
        let date = new Date();
        let day = date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate();
        let month = date.getMonth() === 0 ? 12 : date.getMonth();
        month = month.toString().length === 1 ? '0' + month : month;
        let year = date.getMonth() === 0 ?  (+date.getFullYear())-1 :  date.getFullYear();
        this.monthAgoDate = year+'-'+month+'-'+day;
        this.customerSvc = new CustomerService();
        this.cartUtil = new AddToCartUtil();
    }

    componentDidMount() {
        if (!window.localStorage.getItem(config.headerstorage)) this.setState({redirect: true});
        this.customerSvc.getCustomer()
        .then( res => {
            if (!res.id) {
                this.setState({redirect: true});
            } else {
                this.setState( { id: res.id, name: res.firstname + ' '+ res.lastname, email: res.email});
                let shipping = this.customerSvc.getCustomerAccountAddress();
                let billing = this.customerSvc.getCustomerBillingAddress();
                let orders = this.customerSvc.getCustomerOrders('');
                Promise.all([shipping,billing,orders])
                .then( res => {
                    let shipResults = res[0].items[0] || [] ;
                    let billResults = res[1];
                    let orderResults = res[2] || [];
                    if (!!shipResults) {
                        this.setState( {
                            company: '',
                            zip: shipResults.postcode || '',
                            address: (shipResults.street && shipResults.street[0]) || '',
                            state: (shipResults.region && shipResults.region.region_code) || '',
                            country: shipResults.country_id || '',
                            phone: shipResults.telephone || '',
                            hasAddress: !!res[0].items[0],
                            addressId: shipResults.id || '',                            
                            recepient: !!shipResults.firstname && !! shipResults.lastname ? shipResults.firstname +' '+ shipResults.lastname : ''
                        })
                    }
                    if (!!billResults) {

                        this.setState( {
                            billingAddressId: billResults.id,
                            hasBillingAddress: !!billResults.id,
                            companyBilling: billResults.company || '',
                            zipBilling: billResults.postcode || '',
                            addressBilling: billResults.street || '',
                            stateBilling: (billResults.region && billResults.region.region_code) || '',
                            countryBilling: billResults.country_id || '',
                            phoneBilling: billResults.telephone || '',
                            recepientBilling: !! billResults.firstname && !! billResults.lastname ? billResults.firstname + ' '+ billResults.lastname : ''
                        })
                    }

                    this.setState({
                        recentOrders: orderResults.items.filter(el => el.created_at.split(' ')[0]>this.monthAgoDate) || [], 
                        historyOrders: orderResults.items || []
                    });

                })


            }
        })
    }

    renderRedirect = (e) => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/register",
                state: {
                    url: "/account"
                }
        }} />
          }
    }

    render() {

        return (
            <section className="account">
                {this.renderRedirect()}
                {this.state.id && (<div>
                    <div className="account__section">
					<h2 className="account__h2">
						My Account Dashboard
					</h2>
					<div className="account__orders">
						<div className="account__orders-list">
							{[
								{title: "Recent Orders", items: this.state.recentOrders || []},
								{title: "Order History", items: this.state.historyOrders || []},
							].map((list, i) => <React.Fragment>
								<h3 className="account__h3">
									{list.title}
								</h3>
								{i === 0 &&
									<div className="account__thead">
										{["order number", "date", "shipped to", "order total", "status"].map(i => (
											<div className="account__th">
												{i}
											</div>
										))}
									</div>
								}

								{list.items.map((item, i) => (
									<Collapse
										key={i}
										underLg
										className={`account__collapse ${i === 0 ? "_first" : ""}`}
										title={<header className="account__collapse-header">
											<button className="account__collapse-btn">
												Order #{item.increment_id}
											</button>
										</header>}
										visibleContent={<div className="account__collapse-visible">
											<div className="account__param">
												<div className="account__param-key">
													Date
												</div>
												<div className="account__param-value">
												{(item.created_at && item.created_at.split(' ')[0])||''}
												</div>
											</div>
										</div>}
									>
										<React.Fragment>
											<div className="account__param">
												<div className="account__param-key">
													Shipped to
												</div>
												<div className="account__param-value">
												{item.billing_address.firstname} {item.billing_address.lastname}
												</div>
											</div>
											<div className="account__param">
												<div className="account__param-key">
													Order Total
												</div>
												<div className="account__param-value">
												{item.base_currency_code} {this.cartUtil.priceFormatter(item.base_grand_total)}
												</div>
											</div>
											<div className="account__param">
												<div className="account__param-key">
													Status
												</div>
												<div className="account__param-value">
													<div className="account__status _pending">
													{item.status}
													</div>
												</div>
											</div>
										</React.Fragment>
									</Collapse>
								))}
							</React.Fragment>)}
						</div>
					</div>
				</div>
				<div className="account__section _gray">
					<h2 className="account__h2">
						Account Info
					</h2>
					<div className="account__info">
						{[
							{title: "Contact Info", list: [
								this.state.name,
								this.state.email,
								<Link to="/password-change">
                                    <button>Change Password</button>
                                </Link>,
							]},
							{title: "Default Billing address", list: [
								this.state.recepientBilling,
                                this.state.companyBilling,
                                this.state.addressBilling,
                                this.state.stateBilling + ', ' + this.state.zipBilling,
                                this.state.countryBilling,
                                "Phone: " + this.state.phoneBilling,
								<Link to={{
                                    pathname:"/billing",
                                    state:{
                                        url:"/account",
                                        hasAddress: this.state.hasBillingAddress,
                                        addressId: this.state.billingAddressId
                                    }}
                                }>
                                    <button>Edit Address</button>
                                </Link>,
							]},
							{title: "Default Shipping address", list: [
								this.state.recepient,
                                this.state.company,
                                this.state.address,
                                this.state.state + ', ' + this.state.zip,
                                this.state.country,
                                "Phone: " + this.state.phone,
                                <Link to={{
                                    pathname:"/shipping",
                                    state:{
                                        url:"/account",
                                        hasAddress: this.state.hasAddress,
                                        addressId: this.state.addressId
                                    }}
                                }>
                                    <button>Edit Address</button>
                                </Link>,
							]},
						].map((info, i) => (
							<div className="account__info-item">
								<h3 className="account__h3">
									{info.title}
								</h3>
								<p>
									{info.list.map((item, i) => (
										<span className="_d-b">
                                            {item}
                                        </span>
									))}
								</p>
							</div>
						))}
					</div>
				</div>
                </div>)}
                {!this.state.id && !this.state.redirect && (<Modal><Loader/></Modal>)}
            </section>
        );
    }
}

export default Account;
