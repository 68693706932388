import React from "react";
import TextInput from "./text.input.component";

export default function NumberInput({ onChange, ...props }) {
  const handleChange = (e) => {
    const { value } = e.target;
    if (/^\d+$/.test(value) || value === '') {
      onChange(e);
    }
  };

  return <TextInput onChange={handleChange} {...props} />;
}
