import { useRef, useEffect } from "react";
import { config } from "components/configurations";

export default function useAffirmMutationObserver(price) {
  const ref = useRef();

  useEffect(() => {
    const affirmObserver = new MutationObserver((mutationList) => {
      const [
        {
          addedNodes: [node],
        },
      ] = mutationList;

      if (node?.className === "affirm-modal-trigger") {
        const elem = ref.current?.children?.[0];

        if (elem) {
          const affirmMessageHTML = elem.innerHTML?.replace(
            "/mo",
            '<span class="per-month">/mo</span>'
          );
          elem.innerHTML = affirmMessageHTML;
        }
      }
    });

    if (ref.current && price <= config.affirmLimit) {
      affirmObserver.observe(ref.current, { childList: true });
    }
    window.affirm?.ui?.refresh?.();

    return () => affirmObserver.disconnect();
  }, [price]);

  return ref;
}
