import { config } from "../../components/configurations";
import { ErrorHandlingService } from "../services/errorHandling.service";
import { AuthorizedCallService } from "../services/authorizedcall.service";

export class CartService {
    constructor() {
        this.guestCartPrefix = '/V1/guest-carts/';
        this.cartsPrefix = '/V1/carts/';
        this.diamondPrefix = '/V1/diamondsearch';
        this.customersPrefix = '/V1/customers/';
        this.error = new ErrorHandlingService();
        this.customerCall = new AuthorizedCallService();
    }

    createGuestCart = () => {
        return fetch(config.magentoURL + this.guestCartPrefix, {
            method: 'post'
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    createCustomerCart = () => {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + "mine", {}, 'post')
            .then(res => res.json())
    }

    getGuestCart = (id) => {
        return fetch(config.magentoURL + this.guestCartPrefix + id)
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    getGuestCartTotals = (id) => {
        return fetch(config.magentoURL + this.guestCartPrefix + id + '/totals')
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    getCustomerCart = () => {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + "mine", {}, 'get')
            .then(res => res.json())
    }

    getCustomerCartTotals = () => {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + "mine/totals", {}, 'get')
            .then(res => res.json())
    }

    getItemsInCart = (cartId) => {
        return fetch(config.magentoURL + this.cartsPrefix + cartId + '/items')
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    addItemToCustomerCart = (payload) => {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + "mine/items", payload, 'post')
            .then(res => res.json())
    }

    addItemToGuestCart = (cartId, payload) => {
        return fetch(config.magentoURL + this.guestCartPrefix + cartId + '/items', {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    addDiamondToCart = (cartId, diamondId, type) => {
        let headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        };
        if (type === 'customer') {
            headers["Authorization"] = "Bearer " + window.localStorage.getItem(config.headerstorage);
        }
        return fetch(config.magentoURL + this.diamondPrefix + '/cart', {
            method: 'post',
            headers: headers,
            body: JSON.stringify({ cartId: cartId, diamondId: diamondId })
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }


    removeFromCart = (cartType, cartId, diamondId) => {
        let cart = cartType === 'customer' ? this.cartsPrefix : this.guestCartPrefix;

        if (cartType === 'customer') {
            return this.customerCall.executeAuthorizedCall(config.magentoURL + cart + 'mine/items/' + diamondId, {}, 'delete');

        } else {
            return fetch(config.magentoURL + cart + cartId + '/items/' + diamondId, {
                method: 'delete'
            })
                .then(res => res.json())
                .catch(err => this.error.handleError(err));
        }

    }

    guestCheckout(guestId, payload) {
        return fetch(config.magentoURL + this.guestCartPrefix + guestId + '/payment-information', {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    customerCheckout(payload) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + 'mine/payment-information', payload, 'post')
            .then(res => res.json());
    }

    mergeGuestAndCustomerCart(guestID) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/falcon/carts/mine', {
            "masked_quote_id": guestID
        }, 'post')
            .then(res => res.json());
    }

    collectTotalsCustomer() {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + 'mine/collect-totals', {
            "paymentMethod": {
                "method": "mpbraintree"
            },
            "shippingCarrierCode": "freeshipping",
            "shippingMethodCode": "freeshipping"
        }, 'put')
            .then(res => res.json())
    }

    applyCouponCustomerCart(coupon) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + 'mine/coupons/' + coupon, {}, 'put')
            .then(res => res.json())
    }

    applyCouponGuestCart(coupon, guestCartID) {
        return fetch(config.magentoURL + this.guestCartPrefix + guestCartID + '/coupons/' + coupon, {
            method: 'put',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({})
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }

    getCustomerCartCoupons() {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + 'mine/coupons', {}, 'get')
            .then(res => res.json())
    }

    getGuestCartCoupons(guestCartID) {
        return fetch(config.magentoURL + this.guestCartPrefix + guestCartID + '/coupons')
            .then(res => res.json())
    }

    deleteCouponsCustomerCart() {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.cartsPrefix + 'mine/coupons', {}, 'delete')
            .then(res => res.json())
    }

    deleteCouponsGuestCart(guestCartID) {
        return fetch(config.magentoURL + this.guestCartPrefix + guestCartID + '/coupons', {
            method: 'delete',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err));
    }
    getProduct(productId) {
        return fetch(config.magentoURL + '/products/' + productId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
            .then(res => res.json())
            .catch(error => this.handleError(error))
    }
}

export default CartService;
