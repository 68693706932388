import React from "react";
import config from "../configurations";

const Clarity = (props) => {
	const {
		className,
		yourDiamond,
		levelClassName,
	} = props;

	return (
		<div className={`clarity ${className}`}>
		{config.grades.map((item, i) => (
			<div className={`clarity__item ${item.toLowerCase()}`} key={item}>
				<div className="clarity__image">
					<img
						src={require(`../../img/education/diamond-clarity-${i+1}.png`).default}
						srcSet={`${require(`../../img/education/diamond-clarity-${i+1}@2x.png`).default} 2x, ${require(`../../img/education/diamond-clarity-${i+1}@3x.png`).default} 3x`} alt={`Diamond Clarity ${item}`}
					/>
				</div>
				<div className={`education-level ${levelClassName} clarity__level _${i+1} ${i === 4 ? `_4` : ''} `}>
					<span/><span/><span/><span/>
				</div>
				<div className={`clarity__title ${yourDiamond === i ? "_accent" : ""}`}>
					<div>{item}</div>
					{yourDiamond === i && <b>Your diamond</b>}
				</div>
			</div>
		))}
		</div>
	)
};

export default Clarity;
