import { request, gql } from "graphql-request";
import { useQuery } from "react-query";
import { config } from "components/configurations";
const { magentoGraphqlURL } = config;

const CUSTOM_ATTRIBUTES_QUERY = gql`
  {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "ring_options", entity_type: "catalog_product" }
        { attribute_code: "nwg_diamond_shape", entity_type: "catalog_product" }
        { attribute_code: "nwg_diamond_color", entity_type: "catalog_product" }
        { attribute_code: "nwg_diamond_cut", entity_type: "catalog_product" }
        {
          attribute_code: "nwg_diamond_clarity"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_options {
          value
          label
        }
      }
    }
  }
`;

export function getCustomAttributes() {
  return request(magentoGraphqlURL, CUSTOM_ATTRIBUTES_QUERY).then((data) => {
    const [
      { attribute_options: ringOptions },
      { attribute_options: diamondShapes },
      { attribute_options: diamondColors },
      { attribute_options: diamondCuts },
      { attribute_options: diamondClarities },
    ] = data.customAttributeMetadata.items;
    return {
      ringOptions,
      diamondShapes,
      diamondColors,
      diamondCuts,
      diamondClarities,
    };
  });
}

export function useCustomAttributes() {
  return useQuery("ring-options", getCustomAttributes);
}
