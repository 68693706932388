import React, { Component, Fragment } from 'react';

class RingColorsAvailable extends Component {
    render() {
        return (
            <Fragment>
                <span className='dot rose'></span>
                <span className='dot gold'></span>
                <span className='dot white-grey'></span>
            </Fragment>
        )
    }
}

export default RingColorsAvailable;