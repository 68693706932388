import React from "react";

const EducationContainer = (props) => {
	const {
		className,
		footer,
		toggleEducation,
		handleClose,
		setStep,
	} = props;

	const handleCloseClick = handleClose ? handleClose : () => toggleEducation(false);

	return (
		<div className={`education ${className || ""}`}>
			<div className="education__container">
				<button className="ctrl education__close" onClick={handleCloseClick}>
					<span className="ico ico-Cross"></span>
				</button>
				<div className="education__main">
					{props.children}
				</div>
				{footer &&
					<div className={`education__footer ${footer.className || ""}`}>
						<button className="btn education__btn" onClick={footer.button.onClick}>
							{footer.button.title}
						</button>

						{footer.gia &&
						<button className="btn -width-sm -secondary education__btn" onClick={footer.gia.toggleGIA}>
							{footer.gia.title}
						</button>
						}
					</div>
				}
				{footer && footer.step !== undefined &&
					<div className="education__steps">
						{new Array(...new Array(5)).map((item, i) => (
							<button
								className={footer.step === i ? "_active" : ""}
								key={i}
								onClick={() => setStep(i)}
							></button>
						))}
					</div>
				}
			</div>
		</div>
	)
};

export default EducationContainer;