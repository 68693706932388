import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { config } from "components/configurations";
const { magentoGraphqlURL } = config;

const DIAMOND_BY_SKU_QUERY = gql`
  query($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        id
        name
        sku
        nwg_diamond_shape
        nwg_diamond_cut
        nwg_diamond_color
        nwg_diamond_clarity
        nwg_diamond_carats
        attribute_set_id
        url_key
        stock_status
        thumbnail {
          label
          url
        }
        price_range {
          maximum_price {
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            regular_price {
              currency
              value
            }
          }
        }
      }
    }
  }
`;

export function useDiamond(sku) {
  return useQuery(["diamond", sku], ({ queryKey }) => {
    const [, sku] = queryKey;
    const variables = {
      filter: { sku: { eq: sku } },
    };
    return sku ? request(magentoGraphqlURL, DIAMOND_BY_SKU_QUERY, variables).then(
      (data) => data?.products.items?.[0]
    ) : undefined;
  });
}
