import React, { useEffect, useState } from "react";
import Cut from "components/education.component/cut.component";
import Color from "components/education.component/color.component";
import Carat from "components/education.component/carat.component";
import Clarity from "components/education.component/clarity.component";
import EducationInstructions from "components/education.component/education-instructions.component";
import { diamondEducation } from "components/consts";
import { ReactComponent as EducationIcon } from "img/education-icon.svg";
import { ReactComponent as DiamondIcon } from "./diamond.svg";

const TAB_CHANGE_INTERVAL = 1000 * 7; // seconds
const TABS = Object.keys(diamondEducation);
const EDUCATION_COMPONENTS = {
  cut: Cut,
  color: Color,
  carat: Carat,
  clarity: Clarity,
};

export default function DiamondEducationTabs({ descriptions }) {
  const [cut, color, clarity] = descriptions;
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const EducationComponent = EDUCATION_COMPONENTS[selectedTab];
  const selectedTabInfo = diamondEducation[selectedTab];

  let yourDiamond;

  switch (selectedTab) {
    case "color":
      yourDiamond = color?.param ?? "";
      break;
    case "cut":
      yourDiamond = cut?.selected ?? "";
      break;
    case "clarity":
      yourDiamond = clarity?.selected ?? "";
      break;
    default:
      yourDiamond = "";
  }

  useEffect(() => {
    const selectedTabIndex = Object.values(diamondEducation).findIndex(
      (tab) => tab.title.toLowerCase() === selectedTab
    );
    const nextTabIndex =
      selectedTabIndex === TABS.length - 1 ? 0 : selectedTabIndex + 1;

    const timer = setInterval(
      () => setSelectedTab(TABS[nextTabIndex]),
      TAB_CHANGE_INTERVAL
    );

    return () => clearInterval(timer);
  }, [selectedTab]);

  return (
    <div className="diamond-education-tabs-container">
      <div className="navigation-container">
        {TABS.map((tab) => {
          const { title, subtitle } = diamondEducation[tab];

          return (
            <div
              key={title}
              className={`navigation-tab ${
                tab === selectedTab ? "active" : ""
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              <div className={`icon-container ${tab}`}>
                <svg className="icon-circle" width="40" height="40">
                  <circle
                    cx="20"
                    cy="20"
                    r="18"
                    fill="none"
                    stroke="current"
                    strokeWidth="2"
                  ></circle>
                </svg>
                <DiamondIcon className="diamond-icon" />
              </div>
              <div className="tab-name">
                <div className="tab-name--title">{title}</div>
                <div className="tab-name--subtitle">{subtitle}</div>
              </div>
              <span>&rarr;</span>
            </div>
          );
        })}
      </div>
      <div className="details-container">
        <div className="education__title">
          <div
            className={`education-icon education__icon _${selectedTabInfo.iconColor}`}
          >
            <EducationIcon />
            <span>?</span>
          </div>
          <h2 className="title">{selectedTabInfo.title}</h2>
        </div>
        <div className="subtitle">{selectedTabInfo.subtitle}</div>
        {selectedTab !== "carat" && <EducationInstructions />}
        <div className="tab-component-container">
          <EducationComponent yourDiamond={yourDiamond} />
        </div>
        <div className="text">{selectedTabInfo.text}</div>
      </div>
    </div>
  );
}
