import React from "react";

const caratList = [
	{
		carat: "0.25 carat",
		mm: "4.1",
	},
	{
		carat: "1 carat",
		mm: "6.4",
	},
	{
		carat: "2 carat",
		mm: "8.1",
	},
	{
		carat: "1 dime",
		mm: "18",
	},
];

const Carat = (props) => {
	const {
		className,
	} = props;

	return (
		<div className={`carat ${className}`}>
			{caratList.map((item, i) => (
				<div className="carat__item" key={i}>
					<div className="carat__image">
						<img
							src={require(`../../img/education/diamond-carat-${i+1}.jpg`).default}
							srcSet={`${require(`../../img/education/diamond-carat-${i+1}@2x.jpg`).default} 2x, ${require(`../../img/education/diamond-carat-${i+1}@3x.jpg`).default} 3x`}
							alt=""
						/>
					</div>
					<div className="carat__value">
						{item.carat}
					</div>
					<div className="carat__width">
						{item.mm} mm
					</div>
				</div>
			))}
		</div>
	)
};

export default Carat;
