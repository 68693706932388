import React, { Component } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { CustomerService } from "./services/customer.service";
import { GuestService } from "./services/guest.service";
import Checkbox from "./checkbox/checkbox.component";
import Select from "./select/select.component";
import { config } from "./configurations";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setGrandTotalChanged,
  setTaxChanged,
  setDiscount,
} from "../redux/diamond/actions";
import { setError } from "../redux/user/actions";
import Loader from "./loader.component";
import Modal from "./modal/modal.component";

const countryOptions = config.countryOptions;
const stateOptions = config.stateOptions;
//const stateMap = config.stateMap;
const stateCodeMap = config.stateCodeMap;
const shippingMethod = config.defaultShippingMethod;

class Shipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sameAsBillingChecked: false,
      fullName: "",
      companyName: "",
      address: "",
      apt: "",
      country: 0,
      state: 0,
      phone: "",
      zip: "",
      type: "",
      email: "",
      city: "",
      customerId: "",
      showModal: false,
      shouldBlockNavigation: true,
    };
  }

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.url === "/account"
    )
      this.setState({ shouldBlockNavigation: false });
    let customer = new CustomerService();
    customer.getCustomer().then((res) => {
      if (!!res.id) {
        this.setState({
          type: "customer",
          customerId: res.id,
          email: res.email,
        });
        customer.getCustomerAccountAddress().then((res) => {
          if (res.items && res.items.length > 0)
            this.setState({
              sameAsBillingChecked: res.items[0].defaultBilling,
              fullName: res.items[0].firstname + " " + res.items[0].lastname,
              companyName: res.items[0].company,
              address: res.items[0].street[0],
              apt: "",
              country: countryOptions.findIndex(
                (option) => option.value === res.items[0].country_id
              ),
              state: !!res.items[0].region.region_code
                ? stateOptions.findIndex(
                    (option) => option.value === res.items[0].region.region_code
                  )
                : 0,
              phone: res.items[0].telephone,
              zip: res.items[0].postcode,
              city: res.items[0].city,
            });
        });
      } else {
        if (!!localStorage.getItem("guestCartId")) {
          this.setState({ type: "guest" });
          let guest = new GuestService(localStorage.getItem("guestCartId"));
          guest.getGuestAddress("shipping").then((res) => {
            if (res.street)
              this.setState({
                sameAsBillingChecked: res.defaultBilling,
                fullName: res.firstname + " " + res.lastname,
                companyName: res.company,
                address: res.street,
                apt: "",
                country: res.countryId,
                state: res.regionId,
                phone: res.telephone,
                zip: res.postcode,
                email: res.email,
              });
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.setError("");
  }

  handleSubmitLogic = () => {
    let allowed = true;
    let emptyFields = "";
    Object.keys(this.state).forEach((key) => {
      if (
        key !== "companyName" &&
        key !== "apt" &&
        this.state[key] === "" &&
        key !== "customerId"
      ) {
        allowed = false;
        emptyFields += key + ", ";
      }
    });
    if (!allowed) {
      this.props.setError(
        "The following mandatory fields are empty: " + emptyFields
      );
      return;
    }
    this.setState({ showModal: true });
    sessionStorage.shippingAddress = JSON.stringify(this.state);
    if (this.state.type === "guest") {
      new GuestService(localStorage.getItem("guestCartId"))
        .setGuestAddress("shipping", {
          addressInformation: {
            shipping_address: {
              region_code: stateOptions[this.state.state].value,
              country_id: countryOptions[this.state.country].value,
              street: [this.state.address + " " + this.state.apt],
              telephone: this.state.phone,
              postcode: this.state.zip,
              firstname: this.state.fullName.split(" ")[0],
              lastname: this.state.fullName.split(" ")[1] || "",
              email: this.state.email,
              city: this.state.city,
              same_as_billing: 1,
            },
            shipping_method_code: shippingMethod,
            shipping_carrier_code: shippingMethod,
          },
        })
        .then((res) => {
          if (res.totals) {
            this.props.setGrandTotalChanged(
              res.totals.base_grand_total.toFixed(2)
            );
            this.props.setTaxChanged(res.totals.tax_amount.toFixed(2));
            this.props.setDiscount(res.totals.discount_amount);
            localStorage.setItem(
              "joolezTaxValue",
              res.totals.tax_amount.toFixed(2)
            );
            localStorage.setItem(
              "joolezGrandTotal",
              res.totals.base_grand_total.toFixed(2)
            );
            localStorage.setItem("joolezDiscount", res.totals.discount_amount);
          }
          this.props.history.push("/billing");
        });
    } else {
      let customer = new CustomerService();
      if (
        this.props.location.state &&
        this.props.location.state.url === "/account"
      ) {
        let method = this.props.location.state.hasAddress ? "put" : "post";
        let address = {
          address: {
            region: {
              region_code: stateOptions[this.state.state].value,
              region: stateOptions[this.state.state].title,
              region_id: stateCodeMap[stateOptions[this.state.state].value],
            },
            countryId: countryOptions[this.state.country].value,
            street: [this.state.address + " " + this.state.apt],
            telephone: this.state.phone,
            postcode: this.state.zip,
            firstname: this.state.fullName.split(" ")[0],
            lastname: this.state.fullName.split(" ")[1] || "",
            city: this.state.city,

            default_shipping: true,
            default_billing: false,
          },
        };
        if (method === "put")
          address.address.id = this.props.location.state.addressId;
        customer.updateCustomerAddress(address, method).then((res) => {
          if (res.totals) {
            this.props.setGrandTotalChanged(
              res.totals.base_grand_total.toFixed(2)
            );
            this.props.setTaxChanged(res.totals.tax_amount.toFixed(2));
            this.props.setDiscount(res.totals.discount_amount);
            localStorage.setItem(
              "joolezTaxValue",
              res.totals.tax_amount.toFixed(2)
            );
            localStorage.setItem(
              "joolezGrandTotal",
              res.totals.base_grand_total.toFixed(2)
            );
            localStorage.setItem("joolezDiscount", res.totals.discount_amount);
          }
          let url =
            this.props.location.state && this.props.location.state.url
              ? this.props.location.state.url
              : "/billing";
          this.props.history.push(url);
        });
      } else {
        customer
          .setCustomerAddress("shipping", {
            addressInformation: {
              shipping_address: {
                region_code: stateOptions[this.state.state].value,
                country_id: countryOptions[this.state.country].value,
                street: [this.state.address + " " + this.state.apt],
                telephone: this.state.phone,
                postcode: this.state.zip,
                firstname: this.state.fullName.split(" ")[0],
                lastname: this.state.fullName.split(" ")[1] || "",
                email: this.state.email,
                city: this.state.city,
                same_as_billing: 1,
              },
              shipping_method_code: shippingMethod,
              shipping_carrier_code: shippingMethod,
            },
          })
          .then((res) => {
            if (res.totals) {
              this.props.setGrandTotalChanged(
                res.totals.base_grand_total.toFixed(2)
              );
              this.props.setTaxChanged(res.totals.tax_amount.toFixed(2));
              this.props.setDiscount(res.totals.discount_amount);
              localStorage.setItem(
                "joolezTaxValue",
                res.totals.tax_amount.toFixed(2)
              );
              localStorage.setItem(
                "joolezGrandTotal",
                res.totals.base_grand_total.toFixed(2)
              );
              localStorage.setItem(
                "joolezDiscount",
                res.totals.discount_amount
              );
            }
            let url =
              this.props.location.state && this.props.location.state.url
                ? this.props.location.state.url
                : "/billing";
            this.props.history.push(url);
          });
      }
    }
  };

  submitFunction = (e) => {
    e.preventDefault();
    this.setState({ shouldBlockNavigation: false }, () =>
      this.handleSubmitLogic()
    );
  };

  render() {
    const {
      sameAsBillingChecked,
      fullName,
      companyName,
      address,
      apt,
      country,
      state,
      phone,
      zip,
      email,
      city,
    } = this.state;

    return (
      <form className="form" onSubmit={this.submitFunction}>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message={
            "Are you sure you want to leave?\nYou might lose your selected diamond"
          }
        />
        {this.state.showModal && (
          <Modal>
            <Loader></Loader>
          </Modal>
        )}
        <div className="form__item">
          <h4 className="title-1 form__title">
            Shipping
            <Checkbox
              className="form__title-checkbox"
              name="same-as-billing"
              checked={sameAsBillingChecked}
              onChange={() =>
                this.setState({
                  sameAsBillingChecked: !this.state.sameAsBillingChecked,
                })
              }
            >
              Same as billing
            </Checkbox>
          </h4>
          <div className="form-control form__row">
            <label className="form-control__label" htmlFor="full-name">
              Full name
            </label>
            <input
              type="text"
              className="input-outline form-control__input"
              id="full-name"
              name="full-name"
              value={fullName}
              required
              onChange={(e) =>
                this.setState({
                  fullName: e.target.value,
                })
              }
            />
          </div>
          <div className="form-control form__row">
            <label className="form-control__label" htmlFor="company-name">
              Company name
            </label>
            <input
              type="text"
              className="input-outline form-control__input"
              id="company-name"
              name="company-name"
              value={companyName}
              onChange={(e) =>
                this.setState({
                  companyName: e.target.value,
                })
              }
            />
          </div>
          <div className="form-control form__row">
            <label className="form-control__label" htmlFor="email">
              Email
            </label>
            <input
              type="text"
              className="input-outline form-control__input"
              id="email"
              name="email"
              value={email}
              required
              onChange={(e) =>
                this.setState({
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form__row">
            <div className="form-control form__item _address">
              <label className="form-control__label" htmlFor="address">
                Address
              </label>
              <input
                type="text"
                className="input-outline form-control__input"
                id="address"
                name="address"
                value={address}
                required
                onChange={(e) =>
                  this.setState({
                    address: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-control form__item _apt">
              <label className="form-control__label" htmlFor="apt">
                Apt
              </label>
              <input
                type="text"
                className="input-outline form-control__input"
                id="apt"
                name="apt"
                value={apt}
                onChange={(e) =>
                  this.setState({
                    apt: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-control form__row">
            <label className="form-control__label" htmlFor="apt">
              City
            </label>
            <input
              type="text"
              className="input-outline form-control__input"
              id="city"
              name="city"
              value={city}
              required
              onChange={(e) =>
                this.setState({
                  city: e.target.value,
                })
              }
            />
          </div>
          <div className="form__row">
            <div className="form-control form__item _country">
              <label className="form-control__label">Country</label>
              <Select
                className="form-control__input"
                name="country"
                options={countryOptions}
                activeIndex={country}
                required
                onChange={(e) =>
                  this.setState({
                    country: countryOptions.findIndex(
                      (option) => option.value === e.target.value
                    ),
                  })
                }
              />
            </div>
            <div className="form-control form__item _state">
              <label className="form-control__label">State</label>
              <Select
                className="form-control__input"
                name="state"
                options={stateOptions}
                activeIndex={state}
                required
                onChange={(e) =>
                  this.setState({
                    state: stateOptions.findIndex(
                      (option) => option.value === e.target.value
                    ),
                  })
                }
              />
            </div>
          </div>
          <div className="form__row">
            <div className="form-control form__item _phone">
              <label className="form-control__label" htmlFor="phone">
                Phone number
              </label>
              <input
                type="number"
                className="input-outline form-control__input"
                id="phone"
                name="phone"
                value={phone}
                required
                onChange={(e) =>
                  this.setState({
                    phone: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-control form__item _zip">
              <label className="form-control__label" htmlFor="zip">
                Zip code
              </label>
              <input
                type="number"
                className="input-outline form-control__input"
                id="zip"
                name="zip"
                value={zip}
                required
                onChange={(e) =>
                  this.setState({
                    zip: e.target.value,
                  })
                }
              />
            </div>
          </div>
          {this.state.type !== "customer" && (
            <div className="form__row">
              <p className="text-1 form__p">
                Want to save your info? <Link to="/register">Sign In</Link> or{" "}
                <Link to="/new-client">Create an account</Link>.
              </p>
            </div>
          )}
        </div>
        <div className="form__buttons">
          <button type="submit" className="btn form__btn">
            Continue
          </button>
        </div>
        {}
      </form>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setGrandTotalChanged,
  setTaxChanged,
  setError,
  setDiscount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shipping));
