import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import styles from "./cart.module.scss";

import { SBIMGSlider } from "components/shop.by.component/productDetails/imgSlider.component";
import Radios from "components/radios/radios.component";
import Select from "components/select/select.component";
import { CartService } from "../services/cartService";
import { RingService } from '../services/ring.service';
import { DiamondSearch } from "../services/diamondsearch";
import { CustomerService } from "../services/customer.service";
import { setDiamondId, setCart, setDiamondPurchased } from "../../redux/diamond/actions";
import { setCurrentRingDetails } from '../../redux/ring/actions'
import { setUserType, setError } from "../../redux/user/actions";
import { AddToCartUtil } from "../utils/addtocart.util";
import { config } from "../configurations";
import Loader from "../loader.component";
import Modal from "../modal/modal.component";
import { ReactComponent as LockIcon } from "img/cart/icon-lock.svg";
import Hashids from 'hashids'
import checkIcon from "img/congratulations.svg";
import { formatDiamondSize } from "components/utils/formatDiamondSize";

const Ring = ({ image, image2x, image3x, title }) => (
	<div className={styles.ring}>
		<div className={styles.ringImage}>
			<img src={image} srcSet={`${image2x} 2x, ${image3x} 3x`} alt="" />
		</div>
		<div className={styles.ringTitle}>
			{title}
		</div>
	</div>
);

const ringsRadios = [
	{
		id: "white",
		content: <Ring
			image={require("img/cart/ring-0.jpg").default}
			image2x={require("img/cart/ring-0@2x.jpg").default}
			image3x={require("img/cart/ring-0@3x.jpg").default}
			title="14K White Gold"
		/>
	},
	{
		id: "yellow",
		content: <Ring
			image={require("img/cart/ring-1.jpg").default}
			image2x={require("img/cart/ring-1@2x.jpg").default}
			image3x={require("img/cart/ring-1@3x.jpg").default}
			title="14K Yellow Gold"
		/>
	},
	{
		id: "rose",
		content: <Ring
			image={require("img/cart/ring-2.jpg").default}
			image2x={require("img/cart/ring-2@2x.jpg").default}
			image3x={require("img/cart/ring-2@3x.jpg").default}
			title="14K Rose Gold"
		/>
	},
];



class Cart extends Component {
	constructor() {
		super();
		this.state = {
			ring: "",
			ringSizeIndex: 0,
			cartItems: [],
			cartType: '',
			cartId: 0,
			diamond: {},
			overallQuality: 1,
			itemRemoved: false,
			numericId: 0,
			removeButtonClickedID: '',
			grandTotal: 0,
			removedId: '',
			apiDiamonds: [],
			ringAdded: false,
			rings: [],
			isLoaded: false,
			showModal: false,
			couponText: 'Apply',
			couponValue: '',
			couponInfoMessage: '',
			messageType: 'info',
			shouldBlockNavigation: true,
			ringSelected: false,
			itemsOnCart: [],
			ringsOptions: [],
		};
		this.cartSvc = new CartService();
		this.diamondSvc = new DiamondSearch();
		this.ringSvc = new RingService();
		this.util = new AddToCartUtil();
		this.ringSizes = this.util.getRingSizes();
		this.affirmRef = React.createRef()
		this.congratulationsAnimationTimer = null

		this.affirmObserver = new MutationObserver(mutationList => {
			const [{ addedNodes: [ node ] }] = mutationList
			if (node && node.className === 'affirm-modal-trigger') {
				this.wrapAffirmMessageText()
			}
		})
	}

	componentDidMount() {
		let storedId = this.props.diamond_id;
		if (storedId) {
			this.diamondSvc.getDiamondById(storedId)
				.then(res => {
					if (!res.diamond) {
						this.props.setError('Diamond was not found');
						return;
					}
					let diamond = res.diamond;
					diamond.qualities = res.calculated_quality;
					diamond.images = [res.diamond.image_file_url || require('../pics/diamond-1.png')];
					diamond.size = formatDiamondSize(res.diamond.size)
					this.setState({
						diamond: diamond,
						overallQuality: res.calculated_quality.overall,
					});
					this.animateCongratulations();
				})

		}
		let customer = new CustomerService();
		customer.getCustomer()
			.then(res => {
				let guestId = localStorage.getItem('guestCartId');
				let call = !!res.id ? this.cartSvc.getCustomerCart() : this.cartSvc.getGuestCart(guestId);
				let customerType = !!res.id ? 'customer' : 'guest';
				call.then(async cart => {
					if (cart.message && cart.hasOwnProperty('parameters')) {
						this.setState({ showModal: false, isLoaded: true })
						return;
					}
					if (cart.items.length === 0) {
						this.setState({ showModal: false, isLoaded: true });
						return
					}
					if (cart.id) {
						let couponsCall = customerType === 'customer' ? this.cartSvc.getCustomerCartCoupons() : this.cartSvc.getGuestCartCoupons(guestId);
						couponsCall
							.then(res => {
								if (typeof res === 'string') {
									this.setState({
										couponValue: res,
										couponText: 'Cancel'
									})
								}
							})
						let rings = cart.items.filter(el => el.sku.indexOf('ring') > -1);
						let total = 0;
						let diamondsCalls = [];
						let newFormettedDiamondArray = [];
						let newFormettedRingArray = [];
						let callsToBackend = [];
						let items = [];
						let cartItems = cart.items.map(itemCart => {
							total += itemCart.price;
							callsToBackend.push(this.ringSvc.getSpecificRing(itemCart.sku));
							return itemCart;
						})
						this.setState({ itemsOnCart: cartItems });
						const responses = await Promise.all(callsToBackend);
						if (responses.length) {
							const filteredRings = this.getOnlyRings(responses);
							if (filteredRings.length) {
								this.setState({ ringSelected: true });
								newFormettedRingArray = filteredRings.map(items => this.apiFormRingElement(items))
							}
							const filterDiamonds = this.getOnlyDiamonds(responses);
							diamondsCalls = filterDiamonds.map(diamond => this.diamondSvc.getDiamondById(diamond.sku))
							const resolveDiamondPromises = await Promise.all(diamondsCalls);
							if (resolveDiamondPromises.length) {
								newFormettedDiamondArray = resolveDiamondPromises.map(item => this.apiFormDiamondElement(item.diamond))
							}

							items = [...newFormettedDiamondArray, ...newFormettedRingArray];

							this.setState({
								cartType: customerType,
								cartId: customerType === 'customer' ? cart.id : guestId,
								cartItems: items,
								numericId: cart.id,
								grandTotal: total,
								ringAdded: this.state.ringSelected,
								rings: rings,
								isLoaded: true
							});
							this.props.setUserType(customerType);

							this.getGrandTotal();
						}

					} else {
						this.setState({
							cartType: 'guest',
							cartId: '',
							cartItems: [],
							numericId: '',
							grandTotal: 0,
							ringAdded: this.state.ringSelected,
							rings: [],
							isLoaded: true
						});
					}
				})
			})
		}
		
	componentDidUpdate(prevProps, prevState) {
		if(this.affirmRef.current && this.state.grandTotal <= config.affirmLimit) {
			this.affirmObserver.observe(this.affirmRef.current, { childList: true });
		}
		window.affirm.ui.refresh()
	}

	componentWillUnmount() {
		this.affirmObserver.disconnect();
		this.props.setDiamondPurchased(false);
		this.props.setError('');
		clearTimeout(this.congratulationsAnimationTimer)
	}

	wrapAffirmMessageText() {
		const [elem] = (this.affirmRef.current && this.affirmRef.current.children) || []

		if (elem) {
			const affirmMessageHTML = elem.innerHTML
			const splitContents = affirmMessageHTML.split(' ')
			const perMonth = splitContents.pop()

			if (perMonth === '/mo') {
				splitContents.push(`<span class="per-month">${perMonth}</span>`)
				elem.innerHTML = splitContents.join(' ')
			}
		}
	}

	getGrandTotal() {
		this.setState({ isLoaded: false });
		let totalCall = this.state.cartType === 'customer' ? this.cartSvc.getCustomerCartTotals() : this.cartSvc.getGuestCartTotals(this.state.cartId);
		totalCall.then(res => {
			this.setState({ isLoaded: true });
			let element = document.getElementById('grandTotal');
			if(element) {
				element.innerHTML = '$'+this.util.priceFormatter(res.grand_total);
			}
		});
	}

	getOnlyDiamonds(cartItems) {
		const diamonds = cartItems.map((value) => {
			const { custom_attributes } = value;
			const temp = custom_attributes.filter(({ attribute_code }) => {
				return attribute_code === 'ring_size' || attribute_code === 'ring_color' || attribute_code === 'ring_metal'
			});
			if (temp.length === 0) {
				return value;
			} else {
				return undefined
			}
		});

		return diamonds.filter((el) => el != null);
	}

	getOnlyRings(cartItem) {
		const rings = cartItem.map((value) => {
			const { custom_attributes } = value;
			const temp = custom_attributes.filter(({ attribute_code }) => {
				return attribute_code === 'ring_size' || attribute_code === 'ring_color' || attribute_code === 'ring_metal'
			});
			if (temp.length) {
				return value;
			} else {
				return undefined
			}
		});

		return rings.filter((el) => el != null);
	}

	getDiamondImageUrl(shape) {
		let newShape = shape;

		if (!newShape) {
			newShape = this.state.shape.split(' ')[0];
		}

		newShape = newShape.charAt(0).toLowerCase() + newShape.slice(1);

		return config.diamondImages[newShape];
	}

	apiFormRingElement = (element) => {
		let formatedRingNewObject = {};
		if (Object.keys(element).length) {
			let ring = element;
			if(ring.sku.includes('-er-')) {
				const ringDetails = ring.sku.split('-er-');
				var ringAttrs = ringDetails[1].split('-');
			} else {
				const ringDetails = ring.sku.split('-');
				var ringAttrs = ringDetails.slice(2);
			}
			const ringName = ring.name.split('-');
			formatedRingNewObject = {
				url: element.extension_attributes.media_gallery_sizes[0].full,
				labels: [{ title: "Your pick", className: "-green" }, { title: "Great choice!", className: "-rose" }],
				price: element.price,
				sku: element.sku,
				name: element.name,
				table: [{
					data: [
						["Name", ringName[0]],
						["Color", ringAttrs[0]],
						["Metal", ringAttrs[1]],
						["Size", ringAttrs[2]],
					]
				}],
			}
			formatedRingNewObject.item_id = this.getItemIdFromRing(element);
			return formatedRingNewObject;

		} else {
			formatedRingNewObject.message = "This ring is no longer available"
			return formatedRingNewObject;
		}
	} // This function recives a ring element and transform this element in a new but with properties like a diamond.

	apiFormDiamondElement = (localEl) => {
		let diamondNewFormat = {};
		if (Object.keys(localEl).length) {
			diamondNewFormat.table = [{
				data: [
					["Size", formatDiamondSize(localEl.size)],
					["Cut", localEl.cut || "N/A"],
					["Color", localEl.color,],
					["Clarity", localEl.clarity,],
				]
			}];
			diamondNewFormat.price = localEl.total_sales_price;
			diamondNewFormat.item_id = this.getItemIdFromDiamond(localEl);
			diamondNewFormat.sku = localEl.diamond_id;
			diamondNewFormat.url = localEl.image_file_url || this.getDiamondImageUrl(localEl.shape)
		}
		else {
			diamondNewFormat.message = "This diamond is no longer available";
			diamondNewFormat.url = require("pics/Shiny-Diamond.png");
		}
		diamondNewFormat.labels = [];
		diamondNewFormat.labels.push({ title: "Your pick", className: "-green", });
		diamondNewFormat.labels.push({ title: "Great choice!", className: "-rose", });
		diamondNewFormat.labels.push({ title: localEl.shape, });
		return diamondNewFormat;
	}

	getItemIdFromDiamond(diamond) {
		const { itemsOnCart } = this.state;
		const { item_id } = itemsOnCart.find((cartItem) => Number(cartItem.sku) === diamond.diamond_id);
		return item_id;
	}

	getItemIdFromRing(ring) {
		const { itemsOnCart } = this.state;
		const { item_id } = itemsOnCart.find((cartItem) => cartItem.sku === ring.sku);
		return item_id;
	}

	verifyRingsInCart = async (cartItem = []) => {
		if (cartItem.length) {
			let ringsAvailables = false;
			let calls = cartItem.map((item) => {
				return this.ringSvc.getSpecificRing(item.sku);
			});
			const allResults = await Promise.all(calls);
			if (allResults.length) {
				const onlyRings = this.getOnlyRings(allResults);
				if (onlyRings.length) {
					ringsAvailables = true;
				} else {
					ringsAvailables = false;
				}
			}
			return ringsAvailables
		}
	}

	removeItem = (sku, id, url) => {
		if (this.state.diamond.diamond_id + '' === sku + '') {
			this.setState({ removedId: true });
		}

		this.setState({ removeButtonClickedID: sku });
		this.setState({ showModal: true });
		this.cartSvc.removeFromCart(this.state.cartType, this.state.cartId, id)
			.then(async res => {
				this.setState({ showModal: true });
				let cart = this.state.cartItems;
				let i = this.state.cartItems.findIndex(el => el.sku + '' === sku + '');
				cart.splice(i, 1);
				let total = 0;
				cart.forEach(el => total += el.price)
				if (!await this.verifyRingsInCart(cart)) this.setState({ ringAdded: false })
				this.setState({
					itemRemoved: true,
					cartItems: cart,
					grandTotal: total,
					removeButtonClickedID: ''
				});
				if (cart.length === 0) {
					window.localStorage.removeItem('diamondId')
				}
				let storeCart = this.props.cart;
				let j = storeCart.findIndex(el => el.sku + '' === sku + '' || el.id + '' === sku + '');
				storeCart.splice(j, 1);
				this.props.setCart(storeCart);
				if (cart.length === 0 && this.state.rings.length > 0) {
					let calls = [];
					this.state.rings.forEach(el => {
						calls.push(this.cartSvc.removeFromCart(this.state.cartType, this.state.cartId, el.item_id));
					});
					Promise.all(calls)
						.then(res => {
							this.setState({ showModal: false })
							this.setState({
								rings: [],
								ringAdded: false
							});
							if (!!url) this.props.history.push(url);
						})

				} else {
					if (!!url) this.props.history.push(url);
					this.setState({ showModal: false })
				}
				this.getGrandTotal();
			});
	}

	handleCoupon = (e) => {
		e.preventDefault();
		let couponCall;

		this.setState({ isLoaded: false });
		if (this.state.couponText === 'Apply') {

			if (this.state.couponValue === '') return;

			couponCall = this.state.cartType === 'customer' ? this.cartSvc.applyCouponCustomerCart(this.state.couponValue) :
				this.cartSvc.applyCouponGuestCart(this.state.couponValue, this.state.cartId);
			couponCall
				.then(res => {
					if (res.toString() === 'true') {
						this.setState({
							couponText: 'Cancel',
							couponInfoMessage: 'The coupon was applied',
							messageType: 'info',
							isLoaded: true
						});


						setTimeout(() => {
							this.setState({ couponInfoMessage: ''});
						}, 3000);
						this.getGrandTotal();
					} else {
						this.setState({
							couponInfoMessage: 'The coupon code is invalid',
							messageType: 'error',
							isLoaded: true
						});
						setTimeout(() => {
							this.setState({ couponInfoMessage: ''});
						}, 3000);
					}

				})
		}

		if (this.state.couponText === 'Cancel') {
			couponCall = this.state.cartType === 'customer' ? this.cartSvc.deleteCouponsCustomerCart() : this.cartSvc.deleteCouponsGuestCart(this.state.cartId);
			couponCall
				.then(res => {
					this.setState({
						couponText: 'Apply',
						couponValue: '',
						isLoaded: true
					})
					this.getGrandTotal();
				})
		}
	}

	cartRedirect = () => {
		this.setState({ shouldBlockNavigation: false }, () => this.cartRingLogic());

	}

	cartRingLogic = () => {
		if (!this.state.ring) {
			this.props.history.push('/register');
			return;
		}

		let payload = {
			cartItem: {
				sku: 'solitaire-ring-' + this.state.ring + '-gold-' + this.ringSizes[this.state.ringSizeIndex].value,
				qty: 1,
				quote_id: this.state.cartId
			}
		}

		if (this.state.cartType === 'customer') {
			this.cartSvc.addItemToCustomerCart(payload)
				.then(res => {
					if (res.item_id) this.props.history.push('/register');
					return;
				})
		} else {
			this.cartSvc.addItemToGuestCart(this.state.cartId, payload)
				.then(res => {
					if (res.item_id) this.props.history.push('/register');
					return;
				})
		}
	}

	// fade from congratulations text to information about diamond
	animateCongratulations = () => {
		this.congratulationsAnimationTimer = setTimeout(() => {
			if (!this.props.diamond_purchased) return;
			const height1 = this.congratulations?.offsetHeight ?? 0;
			const height2 = this.info?.offsetHeight ?? 0;

			// set current height to have start for transition
			this.congratulations.style.height = height1 + "px";

			// transit to second text (without small timeout style applies immediately without transition)
			setTimeout(() => {
				this.congratulations.style.height = height2 + "px";
				this.congratulations.style.opacity = 0;
				this.info.classList.add("_show");

				// mae faded in text position static and hide congratulations block
				this.info.addEventListener("transitionend", () => {
					this.info.classList.remove("_show");
					this.info.classList.add("_static");
					this.congratulations.classList.add("_hidden");
				}, { once: true });
			}, 50);
		}, 5000);
	}

	productRedirect = async (id, notfound) => {
		this.setState({ showModal: true });
		if (!!notfound) return;
		if (!!id) {
			const ring_diamond = await this.ringSvc.getSpecificRing(id)
			const isRing = this.hasRingsProperties(ring_diamond);
			if (isRing) {
				await this.redirectToRingBuilder(ring_diamond);
			} else {
				const hashids = new Hashids();
            	let encoded = hashids.encode(id);
				this.setState({ shouldBlockNavigation: false }, () => {
					this.setState({ showModal: false });
					this.props.setDiamondId(id);
					this.props.history.push('/product-details/'+encoded);

				})
			}
		}

	}

	async redirectToRingBuilder(item) {
		const { itemsOnCart } = this.state;
		const skuOfSelectedItem = item.sku;
		const foundItem = itemsOnCart.find((ring) => ring.sku === skuOfSelectedItem);
		if (foundItem.hasOwnProperty('extension_attributes') && !!foundItem.extension_attributes.parent_sku) {
			const dataParentRing = await this.ringSvc.getSpecificRing(foundItem.extension_attributes.parent_sku);
			this.props.setCurrentRingDetails(dataParentRing);
			this.setState({ showModal: false });
			const urlKey = dataParentRing.custom_attributes.find((element) => element.attribute_code === 'url_key');
			this.props.history.push('/ring-builder/' + urlKey.value);
		} else {
			this.setState({ showModal: false });
			return;
		}
	}

	goToUpgradeRings = () => {
		this.setState({ shouldBlockNavigation: false }, () => {
			this.props.history.push('/ring-detail');
		})
		return <Redirect to='/ring-detail' />
	}

	hasRingsProperties = (item) => {
		const { custom_attributes } = item
		if (Object.keys(custom_attributes).length) {
			const attrs = custom_attributes.filter((element) => element.attribute_code === 'ring_color' || element.attribute_code === 'ring_size' || element.attribute_code === 'ring_metal')
			if (attrs.length) {
				return true;
			} else {
				return false
			}
		}
	}


	render() {
		const {
			ring,
			ringSizeIndex,
			grandTotal
		} = this.state;
		return (
			<div className={styles.cart}>
				{/* <Prompt
					when={this.state.shouldBlockNavigation && this.state.cartItems.length > 0}
					message={'Are you sure you want to leave?\nYou might lose your selected diamond'}
				/> */}
				{this.state.showModal && <Modal><Loader></Loader></Modal>}
				{this.state.cartItems.length > 0 &&
					<React.Fragment>
						<div className={`${styles.orderDetails}`}>
							<h4 className={`text-3 ${styles.orderDetailsTitle}`}>
								Order Details
							</h4>
							<span>
								Items will remain in your bag for <b>24 hours</b>.
							</span>
						</div>
						{this.state.cartItems.map(el => (
							<div className={styles.up} key={el.sku}>
								<SBIMGSlider
									key={el.sku}
									className={styles.slider}
									imgs={[el.url] || []}
									labels={el.labels}
									description={
										<div className="good-description">
											<div className="good-description_line">
												<div className="good-feature">Best value</div>
												{/*<div className={`${iconMapping[this.state.overallQuality]} good-feature_level`}>
													<span /><span /><span /><span />
									</div>*/}
												<div className="good-price"> {el.price ? '$ ' + this.util.priceFormatter(el.price) : 'Free'}</div>
												{/*<div className="good-quality">
													Overall quality
								</div>*/}
											</div>
										</div>
									}
									noSlider={true}
									clicked={() => this.productRedirect(el.sku, el.message)}
								/>
								<div className={styles.upRight}>
									{el.sku + '' === this.props.diamond_id + '' && this.props.diamond_purchased &&
										<div className={styles.congratulations} ref={el1 => this.congratulations = el1}>
											<i className={styles.icon}>
												<img src={checkIcon} alt="check icon" />
											</i>
											<div className={styles.upRightText}>
												<h2 className={`text-1 ${styles.title}`}>
													Congratulations!
												</h2>
												<p className={`text-1 fw-300 ${styles.subtitle}`}>
													Your diamond has been added to your bag.
												</p>
												<p className={`text-1 fw-300 ${styles.subtitle} _hide-over-lg`}>
													Items will remain in your bag for <b>15 minutes</b>.
												</p>
											</div>
										</div>
									}
									<div
										className={`${styles.info} ${el.sku + '' === this.props.diamond_id + '' && this.props.diamond_purchased ? "" : "_static"}`}
										ref={el1 => { el.sku + '' === this.props.diamond_id + '' && (this.info = el1) }}
									>
										{el.table && el.table.map((table, i) => (
											<div
												className={`table-wrapper ${styles.params}`}
												key={`tableWrapper-${i}`}
											>
												{table.title &&
													<h4 className={`table-title ${styles.paramsTitle} ${table.titleClassName || ""}`}>
														{table.title}
													</h4>
												}
												<table className={`table ${styles.paramsTable}`} key={`table-${i}`}>
													<tbody>
														{table.data.map((row, k) => (
															<tr key={`table-${i} row-${k}`}>
																{row.map((td, l) => (
																	<td
																		className={`${l === 0 ? "_c-gray" : ""} ${(l + 1) === row.length ? "_ta-r" : ""}`}
																		key={`table-${i} row-${k} td-${l}`}
																	>
																		{td}
																	</td>
																))}
															</tr>
														))}
													</tbody>
												</table>
											</div>
										))}
										{
											el.message && <div className={styles.up + ' ' + styles.__red} key={el.sku}>
												<div className={styles.btn__cart_container}>
													<span className='logo-text'>{el.message}</span>
												</div>
												<div className={styles.btn__cart_container}>
													<button className="btn" onClick={() => this.removeItem(el.sku, el.item_id, '/')}>Start new search</button>
												</div>
											</div>
										}
									</div>
									{this.state.removeButtonClickedID + '' !== el.sku + '' &&
										<button
											className={`btn -secondary ${styles.removeBtn}`}
											onClick={() => this.removeItem(el.sku, el.item_id)}
										>
											Remove from bag
										</button>
									}
								</div>
							</div>
						))}
						{!this.state.ringAdded && <div className={styles.rings}>
							<h4 className={`text-1 fw-600 ${styles.title2}`}>
								Every diamond comes with a 14k solitaire gold ring for <span className="_carat">FREE! $300 value!</span> <span className="_secondary">(Optional)</span>
							</h4>

							<div className={styles.ringsSelect}>
								<div className={styles.ringsRadios}>
									<span className={styles.ringsSubtitle}>
										Choose a color
									</span>
									<Radios
										className={styles.checkboxes}
										checkboxClassName={styles.checkbox}
										list={ringsRadios}
										name="ring"
										checked={ring}
										onChange={({ target }) => {
											if (this.state.ring === target.id) this.setState({
												ring: ""
											}); else this.setState({
												ring: target.id
											})
										}}
									/>
								</div>

								<div className={`${styles.selectWrapper}`}>
									<span className={styles.ringsSubtitle}>
										Choose a ring size
									</span>
									<Select
										className={`_rounded ${styles.select}`}
										name="ringSizes"
										options={this.ringSizes}
										activeIndex={ringSizeIndex}
										onChange={(e) => {
											this.setState({
												ringSizeIndex: this.ringSizes.findIndex(option => option.value === e.target.value)
											})
										}}
										placeholder="Choose a ring size"
										placeholderClassName={styles.selectPlaceholder}
									/>
								</div>
							</div>
						</div>}
						{
							!this.state.ringAdded && <div className={`${!this.state.ringAdded ? styles.upgrade : styles.upgrade__removeTopDashedLine}`}>
								<div className={styles.upgradeLeft}>
									<h4 className={`${styles.upgradeTitle}`}>
										Upgrade your ring
									</h4>
									<p className={`${styles.upgradeP}`}>
										Browse thousands of ring styles from trusted Jewelry designers, you are bound to find the perfect ring for your fiancé-to-be.
									</p>
								</div>
								<button onClick={this.goToUpgradeRings} className={`btn -secondary ${styles.upgradeBtn}`}>
									Upgrade ring
								</button>
							</div>
						}


						<div className={styles.discount}>
							<input
								type="text"
								className={`input ${styles.discountInput}`}
								placeholder="Enter discount code"
								value={this.state.couponValue}
								onChange={(v) => this.setState({ couponValue: v.target.value })}
							/>
							<button
								type="submit"
								className={`label -green _cu-p ${styles.discountSubmit}`}
								onClick={this.handleCoupon}
							>{this.state.couponText}</button>
							{!!this.state.couponInfoMessage &&
								<p className={`text-1 ${styles.discountMessage} ${this.state.messageType === "info" ? styles._success : styles._error}`}>
									<i className={styles.discountMessageIcon}></i>
									{this.state.couponInfoMessage}
								</p>
							}
						</div>

						<div className={styles.total}>
							<table className={`table-cart ${styles.table}`}>
								<tbody>
									<tr>
										<td>
											Grand Total
										</td>
										<td id="grandTotal">
											${this.util.priceFormatter(grandTotal)}
										</td>
									</tr>
									{grandTotal < config.affirmLimit &&
										<tr>
											<td/>
											<td className="cart-affirm">
													
														<div className="price-separator">or</div>
														<p
															ref={this.affirmRef}
															className="affirm-as-low-as"
															data-page-type="banner"
															data-amount={`${grandTotal * 100}`}
															data-learnmore-show="false"
															data-affirm-type="symbol"
														/>
													
											</td>
										</tr>
									}
								</tbody>
							</table>
						</div>

						<footer className={`${styles.footer}`}>
							<button className={`btn ${styles.checkout}`} onClick={this.cartRedirect}>
								<LockIcon /> Check out
							</button>
							<div className={styles.payments}>
								<img
									src={require("img/cart/payment-methods.jpg").default}
									srcSet={`${require("img/cart/payment-methods@2x.jpg").default} 2x, ${require("img/cart/payment-methods@3x.jpg").default} 3x`}
									alt="payment methods"
								/>
							</div>
						</footer>
					</React.Fragment>
				}
				{this.state.cartItems.length === 0 && this.state.isLoaded && (
					<div>
						<h3 className="text-4 fw-600 tt-u">
							Shopping bag
						</h3>
						<p className="text-4">
							You have no items in your bag.<br />
							Click <Link to="/shop-by">here</Link> to continue shopping.
						</p>
					</div>
				)}
				{
					!this.state.isLoaded && <Modal><Loader></Loader></Modal>
				}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	diamond_id: state.diamond.diamond_id,
	cart: state.diamond.cart,
	diamond_purchased: state.diamond.diamond_purchased,
});

const mapDispatchToProps = {
	setDiamondId,
	setUserType,
	setError,
	setCart,
	setDiamondPurchased,
	setCurrentRingDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
