import React, {Component} from "react";

import EducationContainer from "./education.container.component";
import EducationInstructions from "./education-instructions.component";
import Clarity from "components/education.component/clarity.component";
import { ReactComponent as EducationIcon } from "../../img/education-icon.svg";

class EducationStep3 extends Component {
	constructor(props) {
		super();
		this.state = {
			showGIA: false,
		}
	}

	toggleGIA = (show) => {
		this.setState({showGIA: show});
	}

	GIA = () => (
		<div className="education__gia">
			<div className="education__gia-title">
				GIA plotting symbols key
			</div>
			<p className="education__gia-p">
				Below you can see the different imperfections that can be found in diamonds.
			</p>
			<div className="education__gia-image">
				<img className="under-md" src={require(`../../img/education/gia-table.png`).default} srcSet={`${require(`../../img/education/gia-table@2x.png`).default} 2x, ${require(`../../img/education/gia-table@3x.png`).default} 3x`} alt=""/>
				<img className="over-md" src={require(`../../img/education/gia-table_over-md.png`).default} alt=""/>
			</div>
		</div>
	)

	render() {
		const {
			showGIA,
		} = this.state;

		const GIA = this.GIA;

		return <React.Fragment>
			<EducationContainer
				footer={{
					className: "_2-btns",
					button: {
						title: "Continue to carat",
						onClick: () => this.props.setStep(4),
					},
					gia: {
						title: "GIA Key of Symbols",
						toggleGIA: () => this.toggleGIA(true),
					},
					step: 3,
				}}
				toggleEducation={this.props.toggleEducation}
				setStep={this.props.setStep}
			>
				<div className="education__title">
					<div className="education-icon _yellow education__icon">
						<EducationIcon/>
						<span>?</span>
					</div>
					<h2 className="title-2">
						Clarity
					</h2>
				</div>
				<p className="education__p _subtitle">
					How flawless is the diamond
				</p>
				<EducationInstructions />
				<div className="education__wrapper">
					<Clarity className="education__type"/>
					<p className="education__p _clarity">
						While no diamond is perfectly pure, the closer it comes, the higher its value. The most popular range is the VS1-VS2 diamond.
					</p>
				</div>
			</EducationContainer>

			{showGIA &&
			<EducationContainer
				handleClose={() => this.toggleGIA(false)}
			>
				<GIA/>
			</EducationContainer>
			}
		</React.Fragment>;
	}
}

export default EducationStep3;
