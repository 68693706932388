import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app.component";
import { Router } from "react-router-dom";
import "./index.scss";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers";
import { ErrorHandlingService } from "./components/services/errorHandling.service";
import { createBrowserHistory } from "history";
import TagManager from "react-gtm-module";
import { gtmId } from "./components/consts";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  },
});

const tagManagerArgs = {
  gtmId: gtmId,
};

TagManager.initialize(tagManagerArgs);

const history = createBrowserHistory();
history.listen((location) => {});
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const errorSvc = new ErrorHandlingService(store);

const rootElement = document.getElementById("root");

const AppProvider = (
  <Provider store={store}>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <App errorSvc={errorSvc} />
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </Router>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(AppProvider, rootElement);
} else {
  ReactDOM.render(AppProvider, rootElement);
}