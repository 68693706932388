import React from "react";
import PropTypes from "prop-types";
import SBHeader from "../shopbyheader.component";
import SBPriceCategory from './shopbypricecategory.component';
import {connect} from 'react-redux';
import {setShopByQuery} from "../../../redux/diamond/actions";
import TagManager from "react-gtm-module";
import {gtmId} from "../../consts";
import DocumentMeta from 'react-document-meta';

export class SBPrice1 extends React.Component {

    componentDidMount() {
        const tagManagerArgs = {
            gtmId: gtmId,
            dataLayer: {
                event: 'Shop By Price'

            },
        }
        TagManager.dataLayer(tagManagerArgs);
    }

    addMetaData() {
        const meta = {
            title: 'Find the perfect Engagement Ring on Joolez. ',
            description: 'Engagement rings worthy of your grand gesture moment. The search for your perfect diamond ring is over. ',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'engagement rings, diamonds, diamond ring, diamond engagement ring, best engagement ring, joolez'
                }
            }
        }

        return <DocumentMeta {...meta} />;
    }


    render() {
        document.title = 'Shop By Price - Joolez';

        let links = MOCK_LINKS.map(item => (
            <SBPriceCategory url={item.url}
            range={item.range}
            description={item.description}
            key={item.key}
            query={item.query}
            sendQuery={(q) => {this.props.setShopByQuery(q)}}/>
        ));

        return (
            <section className="page">
                {this.addMetaData()}
                <SBHeader title="Shop by price" description="Pick the option that fits your budget needs. You can adjust later."/>
                <div className="by-price">
                    {links}
                </div>
            </section>
        )
    }
}
const MOCK_LINKS = [
    {
        key: 1,
        url: '/shop-by-price/step2',
        query: {price_total_from: 1000, price_total_to: 5000, shapes: ['Round']},
        description: ' Small budget? No problem we got you covered.',
        range: '$1,000 – $5,000'

    },
    {
        key: 2,
        url: '/shop-by-price/step2',
        query: {price_total_from: 5000, price_total_to: 10000, shapes: ['Round']},
        description: 'The sweet spot. Quality & style at fair prices.',
        range: '$5,000 – $10,000'
    },
    {
        key: 3,
        url: '/shop-by-price/step2',
        query: {price_total_from: 10000, price_total_to: 25000, shapes: ['Round']},
        description: 'Spare no expense. Get the best of the best.',
        range: '$10,000 – $25,000+'
    }
]
SBPrice1.propTypes = {
    clickHandler: PropTypes.func,
  };

const mapStateToProps = state => ({
   query: state.diamond.query
});

const mapDispatchToProps ={
    setShopByQuery
};

export default connect(mapStateToProps, mapDispatchToProps)(SBPrice1);
