export const diamondEducation = {
	cut: {
		title: "Cut",
		subtitle: "How much the diamond sparkles",
		text: "The better the cut the greater the stone’s brilliance. The better the cut, the better the diamond sparkles.",
	},
	color: {
		title: "Color",
		subtitle: "How clear is the diamond",
		text: "Color measures the “the absence of color” in a diamond. A perfect diamond has no color hue, (D, E, F) however many color distinctions are so subtle that they are invisible to the untrained eye.(G,H,I)",
		iconColor: 'cerulean'
	},
	clarity: {
		title: "Clarity",
		subtitle: "How flawless is the diamond",
		text: "While no diamond is perfectly pure, the closer it comes, the higher its value. The most popular range is the VS1-VS2 diamond.",
		iconColor: 'yellow'
	},
	carat: {
		title: "Carat",
		subtitle: "The size of the diamond",
		text: "Carat weight is the total weight of a diamond regardless of how it's distributed. From the top, a poorly cut diamond may appear smaller than a diamond of equal weight.",
		iconColor: 'rose'
	},
};

export const diamondShapesDescription = {
	round: "Designed for maximum sparkle, Round Brilliant is by far the most popular diamond cut. The ultimate classic.",
	princess: "Princess is a contemporary cut with square lines, sharp corners, and high degree of brilliance.",
	radiant: "Radiant combines the brilliance and fire of the Round Brilliant with an elegant look of the Emerald.",
	oval: "A fiery cut combining the sparkle of the Round Brilliant with an elongated, elliptical outline.",
	marquise: "A majestic, elongated cut with tapering points at both ends. Very regal and quite striking.",
	heart: "As a symbol of love, Heart is the most explicitly romantic of all diamond shapes. Gentle and affectionate.",
	emerald: "One of the classics of diamond shapes, Emeralds are known for their elegance and exquisite beauty.",
	cushion: "A round-cornered, squarish-rectangular, pillow resembling shape with strong romantic appeal.",
	asscher: "A squarish Asscher cut is a variation of the Emerald cut with almost octagonal shape. Uniquely regal.",
	pear: "Resembling a teardrop, Pear cut is a unique, feminine shape with one end tapering to a point."
};

//Local up local GTM id or export production GTM id.
export const gtmId = process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : "GTM-P74C7PT";