import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {CustomerService} from "./services/customer.service";
import {connect} from 'react-redux';
import {setError} from "../redux/user/actions"

class PasswordChange extends Component {
    constructor(props){
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            valid: false,
            redirect: false

        }
    }

    componentWillUnmount() {
        this.props.setError('');
    }

    submitForm = (e) => {
        e.preventDefault();

        let customer = new CustomerService();
        customer.forgotPassword(this.state.email)
        .then( res => {
            if (!!res) {
                this.setState({redirect:true});
            }

        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/account' />
        }
      }

    render() {
        const {
            email
        } = this.state;

        return(
        <div className="register-container forgot-container">
        <h2>Forgot Password</h2>
        <form onSubmit={this.submitForm} className="register__form">
            {this.renderRedirect()}
             <input
            type="email"
            className="input register__input"
            onChange={(e) => this.setState({
                email: e.target.value
            })}
            placeholder="Enter your email"
            value={email}
            required
             />
            <button type="submit" className="btn register__submit">
                Submit
            </button>
        </form>
        </div>
        )
    }

}

const mapStateToProps = state => ({
    error: state.user.error
});

const mapDispatchToProps ={
    setError
};


export default connect(mapStateToProps,mapDispatchToProps)(PasswordChange);
