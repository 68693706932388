import React from "react";
import { Link, Redirect } from 'react-router-dom';
import Slider from "react-slick";
import { connect } from 'react-redux';
import { setCompareDiamonds, setDiamondId, setShopByState, setShopByQuery, setShopByAction } from "../../../redux/diamond/actions";
import { setError } from "../../../redux/user/actions";
import { SBPriceFilter } from "./shopbypricefilter.component";
import SBPriceFilterClosed from "./shopbypriceclosedFilter.component";
import SBGood from './shopbypricegood.component';
import { Recommendations } from "../../services/recommendations.service";
import SliderArrow from "components/slider/slider-arrow.component";
import { AddToCartUtil } from "../../utils/addtocart.util";
import { config } from "../../configurations";
import { DiamondSearch } from "../../services/diamondsearch";
import Loader from 'components/loader.component'

import { ReactComponent as CompareDiamondsIcon } from "img/comparison/compare-diamonds.svg";
import { gtmId } from "../../consts";
import TagManager from "react-gtm-module";
import Hashids from 'hashids'
import DocumentMeta from 'react-document-meta';

export class SBPrice2 extends React.Component {
    constructor(props) {
        super(props);
        this.Md = 992;
        this.state = {
            recommendedItems: [],
            filter: !!localStorage.getItem('joolezLocalDiamondFilter') ? localStorage.getItem('joolezLocalDiamondFilter') : '',
            payload: {},
            features: {},
            redirect: false,
            selectedDiamond: '',
            windowWidthUnderMd: window.innerWidth < this.Md,
            isCompareCheckboxesVisible: false,
            total: 100,
            allowCompare: false
        };

        this.util = new AddToCartUtil();
        this.diamondSvc = new DiamondSearch();
    }

    componentWillMount() {
        this.initSlider();
        window.addEventListener("resize", this.initSlider);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.initSlider);
        this.props.setError('');
    }

    componentDidMount() {
        let payload = {};
        let urlParams = this.props.sb_query;

        Object.keys(urlParams).forEach(p => {
            payload[p] = urlParams[p];
            if (p === 'shapes') {
                this.setState({ filter: urlParams[p][0] });
            }
        });
        this.setState({ payload: payload });
        let trialPayload = {};
        Object.keys(urlParams).forEach(p => {
            if (p === 'shapes') {
                trialPayload["shape"] = urlParams[p][0];
                localStorage.setItem('joolezLocalDiamondFilter', urlParams[p][0]);
            } else {
                trialPayload[p] = urlParams[p];
            }
        });

        trialPayload.attr = this.props.sb_action;
        this.setState({ payload: trialPayload });
        this.props.setShopByQuery(trialPayload);
        new Recommendations().getRuleRecommendations(trialPayload)
            .then(res => {
                if (!!res && res.state) this.props.setShopByState(res.state);
                if (!res || res.length === 0 || res.message) {
                    this.props.setError('There was an error loading the results');
                    return;
                }
                this.setRecommendedItems(res);
            });

        const tagManagerArgs = {
            gtmId: gtmId,
            dataLayer: {
                event: 'Shop By Price - Selected',
                eventLabel: 'Price Range Selected:' + urlParams.price_total_from + ' - ' + urlParams.price_total_to
            },
        }
        TagManager.dataLayer(tagManagerArgs);

    }

    addMetaData() {
        const meta = {
            title: 'Find the perfect Engagement Ring on Joolez. ',
            description: 'Engagement rings worthy of your grand gesture moment. The search for your perfect diamond ring is over. ',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'engagement rings, diamonds, diamond ring, diamond engagement ring, best engagement ring, joolez'
                }
            }
        }

        return <DocumentMeta {...meta} />;
    }

    initSlider = () => {
        const windowWidth = window.innerWidth;
        if (this.state.windowWidthUnderMd === true) {
            if (windowWidth < this.Md) {
                return;
            } else {
                this.setState({
                    windowWidthUnderMd: false,
                });
            }
        } else {
            if (windowWidth < this.Md) {
                this.setState({
                    windowWidthUnderMd: true,
                });
            } else {
                return;
            }
        }
    }

    handleHEaderFilterClick = (click) => {
        this.props.showOverlay(!this.props.overlayVisible);
    }

    handleFilterSelection = (data) => {
        this.setState({ filter: data, recommendedItems: [] }, () => {
            localStorage.setItem('joolezLocalDiamondFilter', data);
            this.props.showOverlay(!this.props.overlayVisible);
            let payload = this.state.payload;
            let trialPayload = this.state.payload;
            payload.shapes = [data];
            trialPayload.shape = data;
            trialPayload.attr = this.props.sb_action;
            this.props.setShopByQuery(trialPayload);
            //this.props.setShopByAction('shape');
            this.setState({ filter: data, payload: payload }, () => {
                new Recommendations().getRuleRecommendations(trialPayload)
                    .then(res => this.setRecommendedItems(res));
            });
        })

    }

    setRecommendedItems = (res) => {
        let items = [];
        let features = {};
        if (!res) return;
        Object.keys(res).forEach(key => {
            if (key === 'total_available_diamonds') {
                this.setState({ total: res[key] });
            } else {
                if (key === 'state') {
                    this.props.setShopByState(res[key]);
                } else {
                    if (res[key].diamond && res[key].diamond.diamond_id) {
                        res[key].diamond.feature = key.charAt(0).toUpperCase() + key.replace('_', ' ').substr(1).replace('recommended', 'finest');
                        res[key].diamond.calculated_quality = {};
                        res[key].diamond.image_file_url = this.getDiamondImageUrl(res[key].diamond.shape);
                        res[key].diamond.shape = res[key].diamond.shape.split(' ')[0];
                        items.push(res[key].diamond);
                        features[res[key].diamond.diamond_id] = res[key].diamond.feature;
                        this.setState({ recommendedItems: items, features });
                    }

                }

            }

        });

    }

    diamondChecked = (id) => {
        let arr = this.props.selectedDiamonds;
        if (arr.indexOf(id) > -1) arr.splice(arr.indexOf(id), 1); else arr.push(id);
        this.props.setCompareDiamonds(arr);
    }

    filterClosed = (e) => {
        this.props.showOverlay(false);
    }
    diamondSelected = (id) => {
        this.props.setDiamondId(id);
        this.setState({ redirect: true });
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            const hashids = new Hashids();
            let encoded = hashids.encode(this.props.diamond_id);
            return <Redirect to={{
                pathname: '/product-details/'+encoded,
                state: {
                    accent: this.state.features[this.props.diamond_id]
                }
            }} />
        }
    }
    setAllowCompare = () => {
        this.setState({ allowCompare: this.props.selectedDiamonds.length === 2 })
    }

    cancel = () => {
        this.setState({
            isCompareCheckboxesVisible: false,
            allowCompare: false
        });
        this.props.setCompareDiamonds([]);
    }

    sliderSettings = {
        slidesToShow: 3,
        infinite: false,
        prevArrow: <SliderArrow to="prev" />,
        nextArrow: <SliderArrow to="next" />,
    }

    showCompareCheckboxes = () => {
        this.setState({
            isCompareCheckboxesVisible: !this.state.isCompareCheckboxesVisible,
            selectedDiamonds: [],
        });
    }

    getDiamondImageUrl = (shape) => {
        let newShape = shape;
        newShape = newShape.split(' ')[0];
        newShape = newShape.charAt(0).toLowerCase() + newShape.slice(1);
        return config.diamondImages[newShape];
    }

    render() {
        document.title = 'Recommendations - Joolez';
        const { isCompareCheckboxesVisible, recommendedItems } = this.state;

        let items = this.state.recommendedItems.map((item, i) => (((this.state.filter === item.shape
            || this.state.filter.indexOf(item.shape) > -1
            || !this.state.filter
            || item.shape) && item.shape.indexOf(this.state.filter) > -1) &&
            <SBGood
                img={item.image_file_url}
                label={item.shape}
                feature={item.feature}
                price={item.currency_symbol + ' ' + this.util.priceFormatter(item.total_sales_price_in_currency)}
                quality="See details"
                note="This is not real footage of the diamond"
                key={item.diamond_id + '_' + i}
                diamond_id={item.diamond_id}
                diamondSelected={this.diamondSelected}
                diamondQuality={item.calculated_quality}
                isCompareCheckboxesVisible={isCompareCheckboxesVisible}
                check={this.setAllowCompare}
            />
        ));

        items = this.state.windowWidthUnderMd ? items : (
            <Slider {...this.sliderSettings}>
                {items}
            </Slider>
        );

        let showFilter = this.props.overlayVisible ? { display: 'none' } : {};
        let showFilterSelection = !this.props.overlayVisible ? { display: 'none' } : { "zIndex": 3000 };

        let diamondCompareIconClass = isCompareCheckboxesVisible ? "-active " : "";
        diamondCompareIconClass += this.props.selectedDiamonds.length === 1 ? "-one" : this.props.selectedDiamonds.length === 2 ? "-two" : "";

        return (
            <React.Fragment>
                {this.renderRedirect()}
                <SBPriceFilterClosed
                    showFilter={showFilter}
                    showExpandedFilter={this.handleHEaderFilterClick}
                    shape={this.state.filter}
                    total={this.state.total}
                />
                <SBPriceFilter
                    filterSelection={showFilterSelection}
                    setFilter={this.handleFilterSelection}
                    closeFilter={this.filterClosed}
                    shape={this.state.filter}
                    total={this.state.total}
                />
                <div className="product-list">
                    {this.addMetaData()}
                    <section className={`goods ${this.props.selectedDiamonds.length === 2 ? "_comparison" : ""}`}>
                        <header className="goods-header">
                            <h1 className="good-list_title">
                                Top 4 {/* / {this.state.total} */}
                            </h1>
                            <div className="goods-description">
                                Choose what matters most.<br />
                                You can adjust later.
                            </div>
                            <button
                                className={`ctrl -compare goods-header_btn product-info_compare -lg  ${diamondCompareIconClass}`}
                                onClick={this.showCompareCheckboxes}
                            >
                                <CompareDiamondsIcon />
                            </button>
                        </header>
                        {recommendedItems.length === 0 && <div className="loader-container"><Loader /></div>}
                        <div className="goods-list">{items}</div>
                    </section>

                    {this.props.selectedDiamonds.length > 2 && (
                        <div className="goods__compare-message">
                            You can only compare two items at a time.
                        </div>
                    )}

                    {this.state.allowCompare && (
                        <div className="buttons _comparison _fixed product-list__buttons">
                            <Link
                                className="btn -shadow product-list__compare"
                                to={{
                                    pathname: '/compare-diamonds',
                                    state: {
                                        ids: this.props.selectedDiamonds,
                                        features: this.state.features
                                    }
                                }}
                            >
                                Compare
                            </Link>
                            <button
                                className="btn -shadow -white product-list__cancel"
                                onClick={this.cancel}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    selectedDiamonds: state.diamond.selected_diamonds,
    diamond_id: state.diamond.diamond_id,
    sb_state: state.diamond.sb_state,
    sb_query: state.diamond.query,
    sb_action: state.diamond.sb_action
});

const mapDispatchToProps = {
    setCompareDiamonds,
    setDiamondId,
    setError,
    setShopByState,
    setShopByQuery,
    setShopByAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SBPrice2);
