import React, { useState, useRef } from "react";

function RingAccordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active__accordion" : "");
    setHeightState(
      setActive === "active__accordion"
        ? "0px"
        : `${content.current.scrollHeight}px`
    );
  }

  return (
    <div className={`accordion__section ${props.className ?? ''}`}>
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p
          className={`accordion__title ${
            setActive ? "accordion__title__underline" : ""
          }`}
        >
          {props.title}
          <img
            className="accordion__title__icon"
            src={props.icon}
            alt="icon"
            width="23"
            height="23"
          />
        </p>
        <img
          className="accordion__icon"
          src={
            setActive
              ? require("img/less.png").default
              : require("img/plus.png").default
          }
          alt="accordionState"
          height="20"
          width="20"
        />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content__container"
      >
        <div
          className="accordion__subcontainer__info"
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>
      </div>
    </div>
  );
}

export default RingAccordion;
