import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {CustomerService} from "./services/customer.service";
import {AuthorizedCallService} from "./services/authorizedcall.service";
import {CartService} from "./services/cartService";
import Modal from "./modal/modal.component";
import Loader from "./loader.component";
import {connect} from 'react-redux';
import {setError} from "../redux/user/actions"

class NewUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            first_name: '',
            last_name: '',
            redirect: false,
            submitted: false
        }

    }

    submitForm = (e) => {
        e.preventDefault();
        this.setState({submitted: true});
        let customer = new CustomerService();
        customer.newCustomer(this.state.password, {
            "email": this.state.email,
            "firstname": this.state.first_name,
            "lastname": this.state.last_name
        })
        .then( res => {
            if (!!res.id) {
                customer.customerLogin(this.state.email,this.state.password)
                .then( res => {
                    if (!!res) {
                        let authCall = new AuthorizedCallService();
                        authCall.setAuthorizationHeader(res);
                        let cartService = new CartService();
                        cartService.mergeGuestAndCustomerCart(localStorage.getItem('guestCartId'))
                        .then( res => {
                            this.setState({redirect:true})
                        })
                        //this.setState({redirect:true})
                    }
                })
            } else {
                this.setState({submitted:false});
                this.props.setError(res.message);
            }
        })
    }

    componentWillUnmount() {
        this.props.setError('');
    }

    renderRedirect = () => {
        let url = this.props.location.state && this.props.location.state.url ? this.props.location.state.url : '/shipping-billing';
        if (this.state.redirect) {
          return <Redirect to={url} />
        }
      }

    render() {
        const {
            email,
            password,
            last_name,
            first_name
        } = this.state;

        return(<form onSubmit={this.submitForm} className="register__form">
            {this.state.submitted && (<Modal><Loader/></Modal>)}
            {this.renderRedirect()}
             <input
            type="text"
            className="input register__input"
            onChange={(e) => this.setState({
                first_name: e.target.value
            })}
            placeholder="Enter your First Name"
            value={first_name}
            required
        />
         <input
            type="text"
            className="input register__input"
            onChange={(e) => this.setState({
                last_name: e.target.value
            })}
            placeholder="Enter your Last Name"
            value={last_name}
            required
        />
        <input
            type="text"
            className="input register__input"
            onChange={(e) => this.setState({
                email: e.target.value
            })}
            placeholder="Enter Email address"
            value={email}
            required
        />
        <input
            type="password"
            className="input register__input"
            onChange={(e) => this.setState({
                password: e.target.value
            })}
            placeholder="Password"
            value={password}
            required
        />
        <button type="submit" className="btn register__submit">
            Submit
        </button>
    </form>)
    }

}
const mapStateToProps = state => ({
    error: state.user.error
});

const mapDispatchToProps ={
    setError
};


export default connect(mapStateToProps,mapDispatchToProps)(NewUser);
