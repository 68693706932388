import React from "react";

import EducationContainer from "./education.container.component";

import Carat from "components/education.component/carat.component";
import { ReactComponent as EducationIcon } from "../../img/education-icon.svg";

const EducationStep4 = (props) => (
	<EducationContainer
		footer={{
			button: {
				title: "Got it",
				onClick: () => props.toggleEducation(false),
			},
			step: 4,
		}}
		toggleEducation={props.toggleEducation}
		setStep={props.setStep}
	>
		<div className="education__title">
			<div className="education-icon _rose education__icon">
				<EducationIcon/>
				<span>?</span>
			</div>
			<h2 className="title-2">
				Carat
			</h2>
		</div>
		<p className="education__p _subtitle">
			The size of the diamond
		</p>
		<div className="education__wrapper">
			<Carat className="education__type"/>
			<p className="education__p _carat">
				Carat weight is the total weight of a diamond regardless of how it's distributed. From the top, a poorly cut diamond may appear smaller than a diamond of equal weight.
			</p>
		</div>
	</EducationContainer>
);

export default EducationStep4;
