import React from "react";
import config from "./configurations";

function ContactUs() {
    return(
        <form className="form" action={config.mailChimpURL} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">	

<div class="form-control form__row">
	<label for="mce-EMAIL" className="form-control__label">Email Address  <span class="asterisk">*</span></label>
	<input type="email" required name="EMAIL" className="input-outline form-control__input required" id="mce-EMAIL"/>
</div>
<div class="form-control form__row">
	<label for="mce-FNAME" className="form-control__label">First Name <span class="asterisk">*</span></label>
	<input type="text" required name="FNAME" className="input-outline form-control__input required" id="mce-FNAME"/>
</div>
<div class="form-control form__row">
	<label for="mce-LNAME" className="form-control__label">Last Name <span class="asterisk">*</span></label>
	<input type="text"  required name="LNAME" className="input-outline form-control__input required" id="mce-LNAME"/>
</div>
<div class="form-control form__row">
	<label for="mce-MMERGE3" className="form-control__label">Phone Number </label>
	<input type="text" name="MMERGE3" className="input-outline form-control__input"  id="mce-MMERGE3"/>
</div>
<div class="form-control form__row">
	<label for="mce-MMERGE4" className="form-control__label">What's on your mind? <span class="asterisk">*</span></label>
	<input type="text" required name="MMERGE4" className="input-outline form-control__input required" id="mce-MMERGE4"/>
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style={{"display":"none"}}></div>
		<div class="response" id="mce-success-response" style={{"display":"none"}}></div>
	</div>    
    <div style={{"position": "absolute", "left": "-5000px"}} aria-hidden="true"><input type="text" name="b_4b5a681132f1e58c6e16dfc88_21592129b7" tabindex="-1" value=""/></div>
    <div class="clear">
        <input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" className="btn form__btn"/>
        </div>
    </div>
</form>
    )
}

export default ContactUs;
//  <form className="form" method="post" encType="text/plain">
//             <div className="form-control form__row">
//                         <label className="form-control__label" htmlFor="full-name">
//                             Name
//                         </label>
//                         <input
//                             type="text"
//                             className="input-outline form-control__input"
//                             id="name"
//                             name="name"   
//                             required                         
//                         />
//                     </div>
//                     <div className="form-control form__row">
//                         <label className="form-control__label" htmlFor="full-name">
//                             Email
//                         </label>
//                         <input
//                             type="text"
//                             className="input-outline form-control__input"
//                             id="email"
//                             name="email"   
//                             required                         
//                         />
//                     </div>
//                     <div className="form-control form__row">
//                         <label className="form-control__label" htmlFor="full-name">
//                             Phone number
//                         </label>
//                         <input
//                             type="text"
//                             className="input-outline form-control__input"
//                             id="phone"
//                             name="phone"                                                     
//                         />
//                     </div>
//                     <div className="form-control form__row">
//                         <label className="form-control__label" htmlFor="full-name">
//                             What's on your mind?
//                         </label>
//                         <textarea
                            
//                             className="input-outline form-control__input"
//                             id="comment"
//                             name="comment"    
//                             required                                                 
//                         />
//                     </div>

//                     <div className="form__buttons">
//                     <button type="submit" className="btn form__btn">
//                         Submit
//                     </button>
//                 </div>

//         </form>


// <Mailchimp
//         action={config.mailChimpURL}
//         fields={[
//             {
//                 name: 'Name',
//                 placeholder: 'Name',
//                 type: 'text',
//                 required: true,
//                 label: 'Name'
//             },
//             {
//                 name: 'Email',
//                 placeholder: 'Email',
//                 type: 'email',
//                 required: true
//             },
//             {
//                 name: 'Phone',
//                 placeholder: 'Phone',
//                 type: 'text',
//                 required: false
//             },
//             {
//                 name: "Comments",
//                 placeholder:"What's on your mind?",
//                 type: 'text',
//                 required: true
//             }
//         ]}
//         className = "form"
//         messages = {
//             {
//                 sending: "Sending...",
//                 success: "Thank you for submission!",
//                 error: "An unexpected internal error has occurred.",
//                 empty: "Name, Email and Your opinion are mandatory",
//                 duplicate: "Too many subscribe attempts for this email address",
//                 button: "Submit"
//             }
//         }
//         />
