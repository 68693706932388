import React from "react";
import PropTypes from "prop-types";
import SBHeader from "../shopbyheader.component";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {setShopByQuery} from "../../../redux/diamond/actions";
import {Recommendations} from "../../services/recommendations.service";
import {AddToCartUtil} from "../../utils/addtocart.util";
import TagManager from "react-gtm-module";
import {gtmId} from "../../consts";
import DocumentMeta from 'react-document-meta';

export class ShopBySize1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0.75,
      query: {
        size_from: 0.75,
        price_total_from: 668,
        price_total_to: 9309
      }
    };

    this.recommendSvc = new Recommendations();
    this.util = new AddToCartUtil();
  }

  componentDidMount() {

      const tagManagerArgsMount = {
        gtmId: gtmId,
        dataLayer: {
          event: 'Shop By',
          shop_by: 'Size'

        },
      }
      TagManager.dataLayer(tagManagerArgsMount);


    if (this.props.query) {
      let localQuery = this.state.query;
      this.recommendSvc.getPriceRange({
        "size": this.props.query.size_from || localQuery.size_from
      })
      .then(res => {
        localQuery.price_total_from = res.price_total_from;
        localQuery.price_total_to = res.price_total_to;
        Object.keys(this.props.query).forEach(key => {
          if (key !== 'price_total_from' && key !== 'price_total_to') localQuery[key] = this.props.query[key];
        });
        this.setState({query: localQuery});
        this.props.setShopByQuery(localQuery);
      })
    }
  }

  addMetaData() {
      const meta = {
          title: 'Find the perfect Engagement Ring on Joolez. ',
          description: 'Engagement rings worthy of your grand gesture moment. The search for your perfect diamond ring is over. ',
          meta: {
              charset: 'utf-8',
              name: {
                  keywords: 'engagement rings, diamonds, diamond ring, diamond engagement ring, best engagement ring, joolez'
              }
          }
      }

      return <DocumentMeta {...meta} />;
  }

  changeSizeMinus = (click) => {

    const tagManagerArgsMinus = {
      gtmId: gtmId,
      dataLayer: {
        event: 'Size Change',
        change: 'Decrease'

      },
    }
    TagManager.dataLayer(tagManagerArgsMinus);

    click.preventDefault();
    if (this.state.query.size_from>0.25) {
      let localQuery = this.state.query;
      localQuery.size_from -=0.25;
      this.recommendSvc.getPriceRange({
        "size": localQuery.size_from
      })
      .then( res => {
        if (res.price_total_from && res.price_total_to) {
          localQuery.price_total_from = res.price_total_from;
          localQuery.price_total_to = res.price_total_to;
        }
        this.setState({query: localQuery});
        this.props.setShopByQuery(localQuery);
      })
    }
  }
  changeSizePlus = (click) => {
    const tagManagerArgsMinus = {
      gtmId: gtmId,
      dataLayer: {
        event: 'Size Change',
        change: 'Increase'

      },
    }
    TagManager.dataLayer(tagManagerArgsMinus);

    click.preventDefault();
    let localQuery = this.state.query;
    localQuery.size_from +=0.25;
    this.recommendSvc.getPriceRange({
      "size": localQuery.size_from
    })
    .then( res => {
      if (res.price_total_from && res.price_total_to) {
        localQuery.price_total_from = res.price_total_from;
        localQuery.price_total_to = res.price_total_to;
      }
      this.setState({query: localQuery});
      if (this.props.sb_action === 'size') this.props.setShopByQuery(localQuery);
    })
  }

  render() {
    document.title = 'Shop By Size - Joolez';

    let diamondWidth = 4.6 + 4.8 * ((this.state.query.size_from - .75) / (4 - .75));
    diamondWidth = diamondWidth <= 9.4 ? diamondWidth : 9.4;

	  return (
        <section className="page">
          {this.addMetaData()}
          <SBHeader title='What size are you looking for?' description='Average diamond size is 1 carat.'></SBHeader>

          <form className="by-size" action='#'>

            <div className="by-size_ct">
              <span className="num">{this.state.query.size_from}</span> <span className="unit">ct</span>
            </div>

            <div className="by-size_pic">
              <img
                style={{width: `${diamondWidth}rem`}}
                src={require('../../../pics/carat@3x.jpg').default}
                alt="Shop by size"
              />
            </div>

            <div className="by-size_estimation">
              <div className="by-size_estimation-title">Estimated price range</div>
              <div className="by-size_estimation-price">${this.util.priceFormatter(this.state.query.price_total_from)} &ndash; ${this.util.priceFormatter(this.state.query.price_total_to)}</div>
            </div>

            <div className="by-size_ctrls">
              <div className="by-size_ctrl">
                <button className="ctrl -bordered" onClick={this.changeSizeMinus}>
                  <span className="ico ico-Minus"></span>
                </button>
              </div>
              <div className="by-size_ctrl">
                <button className="ctrl -bordered" onClick={this.changeSizePlus}>
                  <span className="ico ico-Plus"></span>
                </button>
              </div>
            </div>

            <div className="by-size_ctrls-descr">
              Use the minus or plus button to adjust the carat size up or down.
            </div>

            <footer className="by-size_footer">
              <Link to="/shop-by-price/step2" className="btn" type="submit" onClick={this.setQuery}>Continue</Link>
            </footer>

          </form>
        </section>
	  )
  }
}
ShopBySize1.propTypes = {
  clickHandler: PropTypes.func,
};

const mapStateToProps = state => ({
  query: state.diamond.query,
  sb_action: state.diamond.sb_action
});

const mapDispatchToProps ={
   setShopByQuery
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopBySize1);
