import {
   USER_TYPE_CHANGED,
   ERROR_CHANGED,
   CUSTOMER_ID_CHANGED
} from "./actions";

const defaultState = {
    user_type: '',
    error: '',
    customer_id: ''
}

export const UserReducer = (state = defaultState, action) => {
    switch (action.type) {

        case USER_TYPE_CHANGED:
            return {
                ...state,
                user_type: action.payload
            };
        case ERROR_CHANGED:
                return {
                    ...state,
                    error: action.payload
                };
                case CUSTOMER_ID_CHANGED:
                    return {
                        ...state,
                        customer_id: action.payload
                    };
        default: // do nothing          
    }
    return state;
}
