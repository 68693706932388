import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MenuComponent from "./menu.component";
import MainComponent from "./main.component";
import HeaderComponent from "./header.component";
import Footer from "./footer.component";
import FooterPartners from "./footer-partners.component";
import Overlay from "./overlay.component";
import Education from "./education.component/education.component";
import { connect } from "react-redux";
import { setError, setUserType, setCustomerId } from "../redux/user/actions";
import { setCart } from "../redux/diamond/actions";
import { CustomerService } from "./services/customer.service";
import { CartService } from "./services/cartService";
import CustomerChat from "./facebookchat.component";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import Prefetch from "components/ring.detail.component/Prefetch";

class App extends Component {
  constructor() {
    super();
    let shape = window.location.search.split("shape=")[1] || "Round";
    this.state = {
      buttonClicked: false,
      showOverlay: false,
      headerFilter: shape,
      showEducation: false,
      urlCart: false,
      showMenu: false,
      menuOpened: false,
    };

    this.mobile = window.innerHeight / window.innerWidth > 1.5 ? true : false;
    this.headerHandler = this.headerHandler.bind(this);
    this.hidden = this.hidden.bind(this);
  }

  componentDidMount() {
    let guestID = localStorage.getItem("guestCartId");
    let customer = new CustomerService();
    customer.getCustomer().then((res) => {
      let cart = new CartService();
      cart = !!res.id ? cart.getCustomerCart() : cart.getGuestCart(guestID);
      let userType = !!res.id ? "customer" : "guest";
      this.props.setUserType(userType);
      if (userType === "customer") this.props.setCustomerId(res.id);
      cart.then((res) => {
        this.setState({
          count: (res.items && res.items.length) || 0,
        });
        res.items &&
          res.items.forEach((el) => {
            el.id = el.sku;
          });
        res.items =
          res.items && res.items.filter((el) => el.sku.indexOf("ring") < 0);
        this.props.setCart(res.items || []);
      });
    });
  }

  componentDidUpdate() {
    // scroll to top if new route loaded
    if (!this.pathname) {
      this.pathname = window.location.pathname;
    } else if (window.location.pathname !== this.pathname) {
      this.pathname = window.location.pathname;
      document.querySelector("html").scrollTop = 0;
    }
  }

  headerHandler = (data) => {
    //In case any floating is needed to be added to the FB chat, please use moveFacebook functin in setState's callback
    this.setState({
      buttonClicked: !this.state.buttonClicked,
      showMenu: !this.state.showMenu,
    });

    if (!this.state.menuOpened) this.setState({ menuOpened: true });
  };
  hidden = (data) => {
    //In case any floating is needed to be added to the FB chat, please use moveFacebook functin in setState's callback
    this.setState({
      buttonClicked: false,
      showMenu: false,
    });
  };
  filterHeaderChanged = (e) => {
    this.setState({ headerFilter: e });
  };

  setOverlay = (data) => {
    this.setState({ showOverlay: data });
  };

  toggleEducation = (show) => {
    this.setState({ showEducation: show });
  };

  signOffRedirect = () => {
    this.props.history.push("/");
    window.location.reload(false);
  };

  moveFacebook = (opened) => {
    let facebookWrappers = document.getElementsByClassName("fb_dialog");
    for (let i = 0; i < facebookWrappers.length; i++) {
      facebookWrappers[i].style["margin-right"] = opened ? "43%" : "0";
    }
  };

  render() {
    hotjar.initialize(2076364, 6);

    ReactPixel.init("3425087674253228");
    ReactPixel.pageView(); // For tracking page view

    const path = window.location.pathname;
    const mainClassName =
      ["/", "/how-it-works"].indexOf(path) !== -1 ? "-index " : "";

    const urlParams = {};

    let noHeader = ["/how-it-works"].indexOf(path) !== -1;
    let noFooter =
      ["/how-it-works", "/", "/account-markup", "/account"].indexOf(path) !==
      -1;
    let transparentOverlay = ["/shop-by-price/step2"].indexOf(path) !== -1;
    const body = document.querySelector("body");

    if (this.state.showEducation) {
      body.classList.add("modal__open");
    } else {
      body.classList.remove("modal__open");
    }

    switch (path) {
      case "/":
        urlParams.withPartnersFooter = true;
        break;
      default:
        break;
    }

    return (
      <main
        className={`main-container ${mainClassName} ${
          noFooter ? "_no-footer" : ""
        }`}
      >
        <div className={`container-inner ${path === "/" ? "_landing" : ""}`}>
          {!noHeader && <HeaderComponent hideMenu={this.headerHandler} />}

          <MainComponent
            showOverlay={this.setOverlay}
            overlayVisible={this.state.showOverlay}
            errorSvc={this.props.errorSvc}
          />

          <MenuComponent
            signOff={this.signOffRedirect}
            shown={this.state.showMenu}
            crossClicked={this.hidden}
            toggleEducation={this.toggleEducation}
          />
          <CustomerChat />
        </div>
        {!noFooter && (
          <Footer pathname={path} toggleEducation={this.toggleEducation} />
        )}
        {urlParams.withPartnersFooter && <FooterPartners />}
        <Overlay
          className={transparentOverlay ? "_transparent" : ""}
          overlayShown={this.state.showOverlay}
          hideOverlay={this.setOverlay}
        />
        {this.state.showEducation && (
          <Education toggleEducation={this.toggleEducation} />
        )}

        {!!this.props.error && (
          <div
            className={this.mobile ? "common_error__mobile" : "common_error"}
            onClick={() => this.props.setError("")}
          >
            {this.props.error}
          </div>
        )}
        <Prefetch />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.user.error,
  cart: state.diamond.cart,
});

const mapDispatchToProps = {
  setError,
  setCart,
  setUserType,
  setCustomerId,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
