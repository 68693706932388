import React from "react";

import { ReactComponent as ArrowIcon } from "img/common/arrow-right-1.svg";

const SliderArrow = ({className, to, onClick}) => (
	<button
		type="button"
		onClick={onClick}
		className={className}
		aria-label={to}
	>
		<ArrowIcon/>
	</button>
);

export default SliderArrow;