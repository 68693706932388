import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { config } from "components/configurations";
const { magentoGraphqlURL, ringsPageSize } = config;

const RINGS_QUERY = gql`
  query(
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
    $sort: SortEnum
  ) {
    products(
      search: "Ring"
      filter: $filter
      pageSize: $pageSize
      currentPage: $currentPage
      sort: { name: $sort }
    ) {
      items {
        id
        name
        sku
        url_key
        ring_options
        brand
        meta_title
        meta_keyword
        meta_description
        thumbnail {
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            attribute_code
            values {
              value_index
              label
            }
          }
        }
      }
      page_info {
        total_pages
      }
    }
  }
`;

export function getRings({ queryKey }) {
  const [, { filter, page }] = queryKey;

  const defaultVariables = {
    pageSize: ringsPageSize,
    sort: "ASC",
  };

  const filters =
    filter === "all"
      ? { filter: { category_id: { eq: 99 } } }
      : {
          filter: { category_id: { eq: 99 }, ring_options: { eq: filter } },
        };

  const variables = {
    ...defaultVariables,
    ...filters,
    currentPage: page ?? 1,
  };

  return request(magentoGraphqlURL, RINGS_QUERY, variables).then((data) => {
    return {
      rings: data.products.items,
      totalPages: data.products.page_info.total_pages,
    };
  });
}

export function useRings(params) {
  return useQuery(["rings", params], getRings);
}
