import React from "react";
import Modal from "components/modal/modal.component";
import EducationModal from "components/education.component/education-modal.component";
import Cut from "components/education.component/cut.component";
import Color from "components/education.component/color.component";
import Carat from "components/education.component/carat.component";
import Clarity from "components/education.component/clarity.component";

function ProductEducationModal({ educationModal, handleModalClick, details }) {
  switch (educationModal) {
    case "cut":
      return (
        <Modal canClose={true} modalClicked={handleModalClick}>
          <EducationModal
            title="Cut"
            text="The better the cut the better light reflects through the diamond."
            close={handleModalClick}
          >
            <Cut className="_modal" yourDiamond={details[0].selected} />
          </EducationModal>
        </Modal>
      );
    case "color":
      return (
        <Modal canClose={true} modalClicked={handleModalClick}>
          <EducationModal
            eduIconColor="_cerulean"
            title="Color"
            text="Color measures the “the absence of color” in a diamond. A perfect diamond has no color hue, (D, E, F) however many color distinctions are so subtle that they are invisible to the untrained eye.(G,H,I)."
            close={handleModalClick}
          >
            <Color className="_modal" yourDiamond={details[1].text} />
          </EducationModal>
        </Modal>
      );
    case "clarity":
      return (
        <Modal canClose={true} modalClicked={handleModalClick}>
          <EducationModal
            eduIconColor="_yellow"
            title="Clarity"
            text="While no diamond is perfectly pure, the closer it comes, the higher its value. The most popular range is the VS1-VS2 diamond."
            close={handleModalClick}
          >
            <Clarity className="_modal" yourDiamond={details[2].selected} />
          </EducationModal>
        </Modal>
      );
    case "carat":
      return (
        <Modal canClose={true} modalClicked={handleModalClick}>
          <EducationModal
            eduIconColor="_rose"
            title="Carat"
            text="Carat is the size of the diamond."
            close={handleModalClick}
          >
            <Carat className="_modal" />
          </EducationModal>
        </Modal>
      );
    default:
      return null;
  }
}

export default ProductEducationModal;
