import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useHydrate } from "react-query/hydration";
import { getRings } from "queries/graphql";

export default function Prefetch() {
  const queryClient = useQueryClient();
  const dehydratedCache = JSON.parse(localStorage.getItem("cache") ?? "{}");
  useHydrate(dehydratedCache);
  const { search } = useLocation();

  if (!search) {
    queryClient.prefetchQuery(["rings", { filter: "all", page: 1 }], getRings);
  }

  return null;
}
