import React, { useState, useEffect } from "react";

const Collapse = ({
	underLg,
	className,
	title,
	children,
	visibleContent,
	isOpened
}) => {
	const [open, setOpen] = useState(isOpened)
	const toggleCollapse = () => setOpen(!open)
	const	openedClassName = open ? "_opened" : "";

	useEffect(() => {
		setOpen(isOpened)
	}, [isOpened])

	return (
		<div className={`collapse ${openedClassName} ${underLg ? "_under-lg" : ""} ${className || ""}`}>
			<div className="collapse__btn" onClick={toggleCollapse}>
				{title}
			</div>
			{visibleContent}
			<div className="collapse__content">
				{children}
			</div>
		</div>
	)
}

export default Collapse;
