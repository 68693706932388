import React from 'react';
import ParamDescription from "./paramDescription/paramDescription.component";
import ProductEducationModal from "./productEducationModal.component";

const FOUR_C = ["cut", "color", "clarity", "carat"];

export class SBProdDetailItem extends React.Component {
	state = {
		educationModal: undefined
	}

	showEducationModal = (i) => {
		if(window.innerWidth < 1200) {
			this.setState({educationModal: FOUR_C[i]});
		}
	}

	handleEducationModal = () => {
		this.setState({educationModal: undefined});
	}

	render() {
		const {	educationModal } = this.state;
		const {	className, details } = this.props;

		return (
			<div className={`product-details ${className || ""}`}>
				<div className="product-details_overall">
					<h4>
						Follow the green bars {/*Overall Quality */}
					</h4>
					<div className={"product-details_overall-quality _"+this.props.qualityClass}>
						{[...new Array(4)].map((i, k) => <span key={k}></span>)}
					</div>
				</div>
				<div className="product-details_overall __4c">
					<h4>
						4Cs
					</h4>
				</div>
				{this.props.details.map((el, i) => (
					<div className="product-details_item" key={'detailItem' + i}>
						<div className="product-details_icons" onClick={() => this.showEducationModal(i)}>
							<span className={`${el.chargeIcon} product-details_level animate${i+1}`}>
								<span/><span/><span/><span/>
							</span>
							<span className={el.iconClass1}></span>
						</div>
						<div className="product-details_descr">
							<span className="product-details_descr-title">{el.title}</span>
							<span className="product-details_descr-text">{el.text}</span>
						</div>
						<ParamDescription
							type={FOUR_C[i]}
							el={el}
						/>
					</div>
				))}
				<ProductEducationModal
					educationModal={educationModal}
					handleModalClick={this.handleEducationModal}
					details={details}
				/>
			</div>
		)
	}
}

export default SBProdDetailItem;
