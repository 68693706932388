import React, {Component} from "react";

class Modal extends Component {
	modalClicked = (e) => {
		if (this.props.canClose) {
			this.props.modalClicked();
		}
	}

	render() {
		const {
			children,
			className = ''
		} = this.props;

		return (
			<div className="modal-1-bg" onClick={this.modalClicked}>
				<div className="modal-1-wrapper">
					<div className={`modal-1 ${className}`}>
						{children}
					</div>
				</div>
			</div>
		)
	}
}

export default Modal;
