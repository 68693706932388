import React, { Component } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { CustomerService } from "./services/customer.service";
import { GuestService } from "./services/guest.service";
import { ErrorHandlingService } from "./services/errorHandling.service";
import { TextInput, NumberInput, PhoneNumberInput } from "components/input";
import Checkbox from "./checkbox/checkbox.component";
import Select from "./select/select.component";
import { config } from "./configurations";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setError } from "../redux/user/actions";
import {
  setGrandTotalChanged,
  setTaxChanged,
  setDiscount,
} from "../redux/diamond/actions";
import Loader from "./loader.component";
import Modal from "./modal/modal.component";
import { gtmId } from "./consts";
import TagManager from "react-gtm-module";

const countryOptions = config.countryOptions;
const stateOptions = config.stateOptions;
const stateMap = config.stateMap;
const stateCodeMap = config.stateCodeMap;
const shippingMethod = config.defaultShippingMethod;

const REQUIRED_BILLING_FIELDS = [
  "billingFirstName",
  "billingLastName",
  "billingAddress",
  "billingCity",
  "billingState",
  "billingZip",
  "phone",
  "email",
];

const REQUIRED_SHIPPING_FIELDS = [
  "firstName",
  "lastName",
  "address",
  "city",
  "state",
  "zip",
];

class ShippingBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageStep: "billing",
      sameAsBillingChecked: true,
      firstName: "",
      lastName: "",
      companyName: "",
      address: "",
      apt: "",
      country: 0,
      state: 0,
      phone: "",
      zip: "",
      type: "",
      email: "",
      city: "",
      customerId: "",
      billingFirstName: "",
      billingLastName: "",
      billingAddress: "",
      billingApt: "",
      billingState: 0,
      billingZip: "",
      billingCountry: 0,
      billingCity: "",
      showModal: false,
      shouldBlockNavigation: true,
      fieldErrors: {},
    };

    this.customer = new CustomerService();
    this.error = new ErrorHandlingService();
  }

  componentDidMount() {
    const tagManagerViewArgs = {
      gtmId: gtmId,
      dataLayer: {
        event: "checkout",
        eventLabel: "Checkout View",
        ecommerce: {
          checkout: {
            actionField: { step: 2 },
          },
        },
      },
    };
    TagManager.dataLayer(tagManagerViewArgs);

    this.customer.getCustomer().then((res) => {
      if (!!res.id) {
        this.setState({
          type: "customer",
          customerId: res.id,
          email: res.email,
        });
        this.customer.getCustomerAccountAddress().then((res) => {
          if (res.items && res.items.length > 0) {
            this.setState({
              sameAsBillingChecked: res.items[0].defaultBilling,
              firstName: res.items[0].firstname,
              lastName: res.items[0].lastname,
              companyName: res.items[0].company,
              address: res.items[0].street[0],
              apt: "",
              country: countryOptions.findIndex(
                (option) => option.value === res.items[0].country_id
              ),
              state: !!res.items[0].region.region_code
                ? stateOptions.findIndex(
                    (option) => option.value === res.items[0].region.region_code
                  )
                : 0,
              phone: res.items[0].telephone,
              zip: res.items[0].postcode,
              city: res.items[0].city,
            });
          }
        });
      } else {
        if (!!localStorage.getItem("guestCartId")) {
          this.setState({ type: "guest" });
          let guest = new GuestService(localStorage.getItem("guestCartId"));
          guest.getGuestAddress("shipping").then((res) => {
            if (res.street)
              this.setState({
                sameAsBillingChecked: res.defaultBilling,
                firstName: res.firstname,
                lastName: res.lastname,
                companyName: res.company,
                address: res.street,
                apt: "",
                country: res.countryId,
                state: res.regionId,
                phone: res.telephone,
                zip: res.postcode,
                email: res.email,
              });
          });
        }
      }
    });

    this.customer.getCustomerBillingAddress().then((res) => {
      if (res.firstname) {
        this.setState({
          billingFirstName: res.firstname || "",
          billingLastName: res.lastname || "",
          billingAddress: (res.street && res.street[0]) || "",
          billingCity: res.city,
          billingState:
            res.region && res.region.region_code
              ? stateOptions.findIndex(
                  (option) => option.value === res.region.region_code
                )
              : 0,
          billingZip: res.postcode || "",
          billingCountry: res.country_id
            ? countryOptions.findIndex(
                (option) => option.value === res.country_id
              )
            : 0,
        });
      }
    });
  }

  submitFunction = (e) => {
    e.preventDefault();
    this.setState({ shouldBlockNavigation: false }, () => {
      const valid = this.validateAddressFields();
      if (valid) this.handleSubmitFunction();
    });
  };

  handleSubmitFunction = (e) => {
    this.setState({ showModal: true });
    const { sameAsBillingChecked } = this.state;

    const shippingFields = sameAsBillingChecked
      ? {
          region_code: stateOptions[this.state.billingState].value,
          country_id: countryOptions[this.state.billingCountry].value,
          street: [this.state.billingAddress, this.state.billingApt],
          postcode: this.state.billingZip,
          firstname: this.state.billingFirstName,
          lastname: this.state.billingLastName,
          city: this.state.billingCity,
        }
      : {
          region_code: stateOptions[this.state.state].value,
          country_id: countryOptions[this.state.country].value,
          street: [this.state.address, this.state.apt],
          postcode: this.state.zip,
          firstname: this.state.firstName,
          lastname: this.state.lastName,
          city: this.state.city,
        };

    const billingFields = {
      address: {
        region: stateMap[stateOptions[this.state.billingState].value],
        region_id: stateCodeMap[stateOptions[this.state.billingState].value],
        region_code: stateOptions[this.state.billingState].value,
        country_id: countryOptions[this.state.billingCountry].value,
        street: [this.state.billingAddress, this.state.billingApt],
        postcode: this.state.billingZip,
        firstname: this.state.billingFirstName,
        lastname: this.state.billingLastName,
        city: this.state.billingCity,
        telephone: this.state.phone,
        email: this.state.email,
      },
      useForShipping: false,
    };

    sessionStorage.shippingAddress = JSON.stringify(this.state);

    if (this.state.type === "guest" || !this.state.type) {
      new GuestService(localStorage.getItem("guestCartId"))
        .setGuestAddress("shipping", {
          addressInformation: {
            shipping_address: {
              ...shippingFields,
              email: this.state.email,
              telephone: this.state.phone,
              same_as_billing: 1,
            },
            shipping_method_code: shippingMethod,
            shipping_carrier_code: shippingMethod,
          },
        })
        .then((res) => {
          if (res.totals) {
            this.props.setGrandTotalChanged(
              res.totals.base_grand_total.toFixed(2)
            );
            this.props.setTaxChanged(res.totals.tax_amount.toFixed(2));
            this.props.setDiscount(res.totals.discount_amount);
            localStorage.setItem(
              "joolezTaxValue",
              res.totals.tax_amount.toFixed(2)
            );
            localStorage.setItem(
              "joolezGrandTotal",
              res.totals.base_grand_total.toFixed(2)
            );
            localStorage.setItem("joolezDiscount", res.totals.discount_amount);
          }

          new GuestService(localStorage.getItem("guestCartId"))
            .setGuestAddress("billing", billingFields)
            .then((res) => this.props.history.push("/cardholder-review"));
        });
    } else {
      if (
        this.props.location.state &&
        this.props.location.state.url === "/account"
      ) {
        let method = this.props.location.state.hasAddress ? "put" : "post";
        let address = {
          address: {
            region: {
              region_code: stateOptions[this.state.state].value,
              region: stateOptions[this.state.state].title,
              region_id: stateCodeMap[stateOptions[this.state.state].value],
            },
            countryId: countryOptions[this.state.country].value,
            street: [this.state.address + " " + this.state.apt],
            telephone: this.state.phone,
            postcode: this.state.zip,
            firstname: this.state.firstName,
            lastname: this.state.lastName,
            city: this.state.city,
            default_shipping: true,
            default_billing: true,
          },
        };
        if (method === "put")
          address.address.id = this.props.location.state.addressId;
        this.customer.updateCustomerAddress(address, method).then((res) => {
          let url =
            this.props.location.state && this.props.location.state.url
              ? this.props.location.state.url
              : "/billing";
          this.props.history.push(url);
        });
      } else {
        this.customer
          .setCustomerAddress("shipping", {
            addressInformation: {
              shipping_address: {
                ...shippingFields,
                email: this.state.email,
                telephone: this.state.phone,
                same_as_billing: 1,
              },
              shipping_method_code: shippingMethod,
              shipping_carrier_code: shippingMethod,
            },
          })
          .then((res) => {
            if (res.totals) {
              this.props.setGrandTotalChanged(
                res.totals.base_grand_total.toFixed(2)
              );
              this.props.setTaxChanged(res.totals.tax_amount.toFixed(2));
              this.props.setDiscount(res.totals.discount_amount);
              localStorage.setItem(
                "joolezTaxValue",
                res.totals.tax_amount.toFixed(2)
              );
              localStorage.setItem(
                "joolezGrandTotal",
                res.totals.base_grand_total.toFixed(2)
              );
              localStorage.setItem(
                "joolezDiscount",
                res.totals.discount_amount
              );
            }
            this.customer
              .setCustomerAddress("billing", billingFields)
              .then((res) => {
                let url =
                  this.props.location.state && this.props.location.state.url
                    ? this.props.location.state.url
                    : "/cardholder-review";
                this.props.history.push(url);
              });
          });
      }
    }
  };

  changeBilling = () => {
    if (this.state.sameAsBillingChecked) {
      const clearedShipFieldErrors = REQUIRED_SHIPPING_FIELDS.reduce(
        (errors, field) => {
          errors[field] = null;
          return errors;
        },
        {}
      );

      this.setState({
        firstName: this.state.billingFirstName,
        lastName: this.state.billingLastName,
        address: this.state.billingAddress,
        apt: this.state.billingApt,
        state: this.state.billingState,
        city: this.state.billingCity,
        zip: this.state.billingZip,
        fieldErrors: {
          ...this.state.fieldErrors,
          ...clearedShipFieldErrors,
        },
      });
    } else {
      this.setState({
        firstName: "",
        lastName: "",
        address: "",
        apt: "",
        state: 0,
        city: "",
        zip: "",
      });
    }
  };

  componentWillUnmount() {
    this.props.setError("");
  }

  updateTextInputField = (fieldName) => (e) => {
    const { fieldErrors } = this.state;
    this.setState({
      [fieldName]: e.target.value,
      fieldErrors: {
        ...fieldErrors,
        [fieldName]: null,
      },
    });
  };

  validateField = (fieldName) => () => {
    const { [fieldName]: fieldValue, fieldErrors } = this.state;

    if (fieldName === "phone") {
      const value = fieldValue.replace(/[()_\-\s]/g, "");
      const errorMessage =
        value.length === 10
          ? null
          : value.length === 0
          ? "Required"
          : "Incomplete phone number";
      this.setState({
        fieldErrors: {
          ...fieldErrors,
          phone: errorMessage,
        },
      });
    } else {
      if (!fieldValue.trim()) {
        this.setState({
          fieldErrors: {
            ...fieldErrors,
            [fieldName]: "Required",
          },
        });
      }
    }
  };

  validateAddressFields = () => {
    const requiredFields = this.state.sameAsBillingChecked
      ? REQUIRED_BILLING_FIELDS
      : [...REQUIRED_BILLING_FIELDS, ...REQUIRED_SHIPPING_FIELDS];

    const fieldErrors = requiredFields.reduce((errors, field) => {
      if (!this.state[field]) {
        errors[field] = "Required";
      }
      return errors;
    }, {});

    if (Object.keys(fieldErrors).length !== 0) {
      this.setState({
        fieldErrors: {
          ...this.state.fieldErrors,
          ...fieldErrors,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      pageStep,
      sameAsBillingChecked,
      firstName,
      lastName,
      companyName,
      address,
      apt,
      state,
      phone,
      zip,
      email,
      city,
      billingFirstName,
      billingLastName,
      billingAddress,
      billingApt,
      billingState,
      billingCity,
      billingZip,
      fieldErrors,
    } = this.state;

    return (
      <div className={`shipping-billing _${pageStep}`}>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message={
            "Are you sure you want to leave?\nYou might lose your selected diamond"
          }
        />

        <div className="shipping-billing__item">
          <form
            className="form shipping-billing__form"
            onSubmit={this.submitFunction}
          >
            <div className="form__item">
              <h4 className="title-1 form__title shipping-billing__title">
                Billing
              </h4>

              <div className="form__row">
                <TextInput
                  className="form-control form__item _fname"
                  name="billingFirstName"
                  value={billingFirstName}
                  label="First Name"
                  onChange={this.updateTextInputField("billingFirstName")}
                  onBlur={this.validateField("billingFirstName")}
                  error={fieldErrors.billingFirstName}
                />
                <TextInput
                  className="form-control form__item _lname"
                  name="billingLastName"
                  value={billingLastName}
                  label="Last Name"
                  onChange={this.updateTextInputField("billingLastName")}
                  onBlur={this.validateField("billingLastName")}
                  error={fieldErrors.billingLastName}
                />
              </div>

              <TextInput
                className="form-control form__row"
                name="company-name"
                value={companyName}
                label="Company Name"
                onChange={this.updateTextInputField("companyName")}
              />
              <TextInput
                className="form-control form__row"
                name="email"
                value={email}
                label="Email"
                onChange={this.updateTextInputField("email")}
                onBlur={this.validateField("email")}
                error={fieldErrors.email}
              />
              <PhoneNumberInput
                className="form-control form__row"
                name="phone"
                value={phone}
                label="Phone Number"
                onChange={this.updateTextInputField("phone")}
                onBlur={this.validateField("phone")}
                error={fieldErrors.phone}
              />

              <div className="form__row">
                <TextInput
                  className="form-control form__item _address"
                  name="billingAddress"
                  value={billingAddress}
                  label="Address"
                  onChange={this.updateTextInputField("billingAddress")}
                  onBlur={this.validateField("billingAddress")}
                  error={fieldErrors.billingAddress}
                />

                <TextInput
                  className="form-control form__item _apt"
                  name="billingApt"
                  value={billingApt}
                  label="Apt"
                  onChange={this.updateTextInputField("billingApt")}
                />
              </div>

              <div className="form__row">
                <TextInput
                  className="form-control form__item _city"
                  name="billingCity"
                  value={billingCity}
                  label="City"
                  onChange={this.updateTextInputField("billingCity")}
                  onBlur={this.validateField("billingCity")}
                  error={fieldErrors.billingCity}
                />

                <div
                  className={`form-control form__item _state ${
                    fieldErrors.billingState ? "error" : ""
                  }`}
                >
                  <div className="label-container">
                    <label className="form-control__label">State</label>
                    {fieldErrors.billingState && <span> - Required</span>}
                  </div>
                  <Select
                    className="form-control__input"
                    name="state"
                    options={stateOptions}
                    activeIndex={billingState}
                    onChange={(e) => {
                      const billingState = stateOptions.findIndex(
                        (option) => option.value === e.target.value
                      );
                      const { fieldErrors } = this.state;
                      this.setState({
                        billingState,
                        fieldErrors: {
                          ...fieldErrors,
                          billingState: billingState === 0,
                        },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="form__row">
                <div className="form-control form__item _country">
                  <label className="form-control__label">Country</label>
                  <input
                    className="input-outline"
                    value="United States"
                    disabled
                  />
                </div>
                <NumberInput
                  className="form-control form__item _zip"
                  name="billingZip"
                  value={billingZip}
                  label="Zip code"
                  maxLength={5}
                  onChange={this.updateTextInputField("billingZip")}
                  onBlur={this.validateField("billingZip")}
                  error={fieldErrors.billingZip}
                />
              </div>

              {this.state.type !== "customer" && (
                <div className="form__row under-md">
                  <p className="text-1 form__p">
                    Want to save your info? <Link to="/register">Sign In</Link>{" "}
                    or <Link to="/new-client">Create an account</Link>.
                  </p>
                </div>
              )}
            </div>
            <div className="form__buttons under-md">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  const valid = this.validateAddressFields();
                  if (valid) this.setState({ pageStep: "shipping" });
                }}
                className="btn form__btn"
              >
                Continue
              </button>
            </div>
          </form>
        </div>

        <div className="shipping-billing__item">
          <form
            className="form shipping-billing__form"
            onSubmit={this.submitFunction}
          >
            <div className="form__item">
              <h4 className="title-1 form__title shipping-billing__title">
                Shipping
                <Checkbox
                  className="form__title-checkbox"
                  name="same-as-billing"
                  checked={sameAsBillingChecked ?? false}
                  onChange={() =>
                    this.setState(
                      {
                        sameAsBillingChecked: !this.state.sameAsBillingChecked,
                      },
                      this.changeBilling
                    )
                  }
                >
                  Same as billing
                </Checkbox>
              </h4>

              {!sameAsBillingChecked && (
                <>
                  <div className="form__row">
                    <TextInput
                      className="form-control form__item _fname"
                      name="firstName"
                      value={firstName}
                      label="First Name"
                      onChange={this.updateTextInputField("firstName")}
                      onBlur={this.validateField("firstName")}
                      error={fieldErrors.firstName}
                    />
                    <TextInput
                      className="form-control form__item _lname"
                      name="lastName"
                      value={lastName}
                      label="Last Name"
                      onChange={this.updateTextInputField("lastName")}
                      onBlur={this.validateField("lastName")}
                      error={fieldErrors.lastName}
                    />
                  </div>

                  <div className="form__row">
                    <TextInput
                      className="form-control form__item _address"
                      name="address"
                      value={address}
                      label="Address"
                      onChange={this.updateTextInputField("address")}
                      onBlur={this.validateField("address")}
                      error={fieldErrors.address}
                    />

                    <TextInput
                      className="form-control form__item _apt"
                      name="apt"
                      value={apt}
                      label="Apt"
                      onChange={this.updateTextInputField("apt")}
                    />
                  </div>

                  <div className="form__row">
                    <TextInput
                      className="form-control form__item _city"
                      name="city"
                      value={city}
                      label="City"
                      onChange={this.updateTextInputField("city")}
                      onBlur={this.validateField("city")}
                      error={fieldErrors.city}
                    />

                    <div
                      className={`form-control form__item _state ${
                        fieldErrors.state ? "error" : ""
                      }`}
                    >
                      <div className="label-container">
                        <label className="form-control__label">State</label>
                        {fieldErrors.state && <span> - Required</span>}
                      </div>
                      <Select
                        className="form-control__input"
                        name="state"
                        options={stateOptions}
                        activeIndex={state}
                        required
                        onChange={(e) => {
                          const state = stateOptions.findIndex(
                            (option) => option.value === e.target.value
                          );
                          const { fieldErrors } = this.state;
                          this.setState({
                            state,
                            fieldErrors: {
                              ...fieldErrors,
                              state: state === 0,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__item form-control _country">
                      <label className="form-control__label">Country</label>
                      <input
                        className="input-outline"
                        value="United States"
                        disabled
                      />
                    </div>

                    <NumberInput
                      className="form-control form__item _zip"
                      name="zip"
                      value={zip}
                      label="Zip code"
                      maxLength={5}
                      onChange={this.updateTextInputField("zip")}
                      onBlur={this.validateField("zip")}
                      error={fieldErrors.zip}
                    />
                  </div>

                  {this.state.type !== "customer" && (
                    <div className="form__row over-md">
                      <p className="text-1 form__p">
                        Want to save your info?{" "}
                        <Link to="/register">Sign In</Link> or{" "}
                        <Link to="/new-client">Create an account</Link>.
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="form__buttons shipping-billing__submit">
              <button type="submit" className="btn form__btn">
                Continue
              </button>
            </div>
          </form>
        </div>

        {this.state.showModal && (
          <Modal>
            <Loader></Loader>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setError,
  setGrandTotalChanged,
  setTaxChanged,
  setDiscount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShippingBilling));
