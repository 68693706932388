import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {CustomerService} from "./services/customer.service";
import {connect} from 'react-redux';
import {setError} from "../redux/user/actions"

class PasswordChange extends Component {
    constructor(props){
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            valid: false,
            redirect: false

        }
    }

    validatePassword = (password) => {
        password = password.target.value;
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(password)) {
            this.setState({valid: false, newPassword: ''});
            this.props.setError("Password should contain at least one upper case, one lower case, one numeric character and be at least 8 characters long");

        }

    }

    componentWillUnmount() {
        this.props.setError('');
    }

    submitForm = (e) => {
        e.preventDefault();

        let customer = new CustomerService();
        customer.changePassword(this.state.oldPassword,this.state.newPassword)
        .then( res => {
            if (!!res) {
                this.setState({redirect:true});
            }

        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/account' />
        }
      }

    render() {
        const {
            oldPassword,
            newPassword
        } = this.state;

        return(<form onSubmit={this.submitForm} className="register__form">
            {this.renderRedirect()}
             <input
            type="password"
            className="input register__input"
            onChange={(e) => this.setState({
                oldPassword: e.target.value
            })}
            placeholder="Enter your Current Password"
            value={oldPassword}
            required
        />
        <input
            type="password"
            className="input register__input"
            onChange={(e) => this.setState({
                newPassword: e.target.value
            })
            }
            onBlur={this.validatePassword}
            placeholder="Enter New Password"
            value={newPassword}
            required
        />
        <button type="submit" className="btn register__submit">
            Submit
        </button>
    </form>)
    }

}

const mapStateToProps = state => ({
    error: state.user.error
});

const mapDispatchToProps ={
    setError
};


export default connect(mapStateToProps,mapDispatchToProps)(PasswordChange);
