import { config } from "components/configurations";

export function setGuestEmailOnCart({ cartId, email }) {
  const SET_GUEST_EMAIL_MUTATION = `
    mutation {
      setGuestEmailOnCart(input: {
        cart_id: "${cartId}"
        email: "${email}"
      }) {
        cart {
          email
        }
      }
    }
  `;

  return graphqlFetch({ query: SET_GUEST_EMAIL_MUTATION });
}

export function getCustomerCartId() {
  const GET_CUSTOMER_CART = `
    query {
      customerCart{
        id
      }
    }
  `;

  return graphqlFetch({ query: GET_CUSTOMER_CART, authorizedCall: true })
    .then(({ data }) => data.customerCart.id);
}

export function setPaymentMethodOnCart({ cartId, affirmToken, checkoutType }) {
  const SET_PAYMENT_METHOD = `
    mutation {
      setPaymentMethodOnCart(input: {
          cart_id: "${cartId}"
          payment_method: {
          code: "affirm_gateway"
            affirm :{
              checkout_token : "${affirmToken}"
            }
          }
      }) {
        cart {
          selected_payment_method {
            code
            title
            purchase_order_number
          }
        }
      }
    }
  `;
  const authorizedCall = checkoutType === 'customer';
  return graphqlFetch({ query: SET_PAYMENT_METHOD, authorizedCall });
}

export function placeOrder({ cartId, checkoutType }) {
  const PLACE_ORDER = `
    mutation {
      placeOrder(input: {cart_id: "${cartId}"}) {
        order {
          order_number
        }
      }
    }
  `;
  const authorizedCall = checkoutType === 'customer';
  return graphqlFetch({ query: PLACE_ORDER, authorizedCall }).then((response) => {
    const { data, errors } = response;
    const error = errors ? errors[0].message : null
    return error ? { error } : { orderNumber: data.placeOrder.order.order_number };
  });
}

function graphqlFetch({ query, authorizedCall = false }) {
  const authorizationHeader = authorizedCall ?
    { Authorization: `Bearer ${window.localStorage.getItem(config.headerstorage)}` }
    : {};

  return fetch(config.magentoGraphqlURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader
    },
    body: JSON.stringify({ query })
  })
  .then((response) => response.json());
}
