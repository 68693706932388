import { useLocation, useHistory } from "react-router-dom";

export default function useQuerySearchParam(param) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramValue = searchParams.get(param);

  const setParamAndUpdateURL = (newValue) => {
    if (newValue === null) {
      searchParams.delete(param);
    } else if (param === "option") {
      searchParams.delete("page");
      searchParams.set(param, newValue);
    } else {
      searchParams.set(param, newValue);
    }
    history.push(`${pathname}?${searchParams.toString()}`);
  };

  return [paramValue, setParamAndUpdateURL];
}
