import React from "react";

import EducationContainer from "./education.container.component";
import EducationInstructions from "./education-instructions.component";
import Color from "components/education.component/color.component";
import { ReactComponent as EducationIcon } from "../../img/education-icon.svg";

const EducationStep2 = (props) => (
	<EducationContainer
		footer={{
			button: {
				title: "Continue to clarity",
				onClick: () => props.setStep(3),
			},
			step: 2,
		}}
		toggleEducation={props.toggleEducation}
		setStep={props.setStep}
	>
		<div className="education__title">
			<div className="education-icon _cerulean education__icon">
				<EducationIcon/>
				<span>?</span>
			</div>
			<h2 className="title-2">
				Color
			</h2>
		</div>
		<p className="education__p _subtitle">
			How clear is the diamond
		</p>
		<EducationInstructions />
		<div className="education__wrapper">
			<Color className="education__type"/>
			<p className="education__p _color">
				Color measures the “the absence of color” in a diamond. A perfect diamond has no color hue, (D, E, F) however many color distinctions are so subtle that they are invisible to the untrained eye.(G,H,I)
			</p>
		</div>
	</EducationContainer>
);

export default EducationStep2;
