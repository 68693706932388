import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  useConfiguredRing,
  useCustomAttributes,
  useRing,
} from "queries/graphql";
import { useParams } from "react-router-dom";
import useQuerySearchParam from "hooks/useQuerySearchParam";
import { useAffirmMutationObserver, useCacheDehydration } from "hooks";
import { AddToCartUtil } from "components/utils/addtocart.util";
import RingDiamondSelected from "components/ring.detail.component/RingDiamondSelected";
import RingImageSlider from "./RingImageSlider";
import RingInfo from "./RingInfo";
import RingFooter from "./RingFooter";
import RingAndDiamondDetails from "./RingAndDiamondDetails";

const cartUtil = new AddToCartUtil();

export default function RingBuilder() {
  useCacheDehydration();
  const [selectRingError, setSelectRingError] = useState(false);
  const [selectedColor, updateURLColor] = useQuerySearchParam("color");
  const [selectedMetal, updateURLMetal] = useQuerySearchParam("metal");
  const [selectedSize, updateURLSize] = useQuerySearchParam("size");

  const { ringUrlKey } = useParams();
  const [ringName] = ringUrlKey.split("-");

  const sku = `fw-${ringName}-er`;
  const variantSKU = `${sku}-${selectedColor?.replace(
    "-",
    " "
  )}-${selectedMetal}-${selectedSize ?? 0}`;

  const {
    data: configuredRing,
    error: configuredRingError,
    isLoading: configuredRingLoading,
  } = useConfiguredRing(variantSKU);

  const { data: ring, error: ringError } = useRing(ringUrlKey);
  const { data: customAttributesData } = useCustomAttributes();

  const error = configuredRingError || ringError || selectRingError;

  const ringOptions = customAttributesData?.ringOptions;
  const diamondShapesOptions = customAttributesData?.diamondShapes;

  const description = ring?.short_description?.html;
  const name = ring?.name;
  const brand = ring?.brand;
  const configurableOptions = ring?.configurable_options;
  const ringOptionValue = ring?.ring_options;
  const setting = ring?.setting;
  const sizeLimit = ring?.size_limit;
  const accentStones = ring?.accent_stones;
  const diamondShapes = ring?.diamond_shapes;
  const mediaGallery = (configuredRing ?? ring)?.media_gallery;
  const price =
    (configuredRing ?? ring)?.price_range.minimum_price.regular_price.value ??
    "0";
  const thumbnail = ring?.thumbnail?.url;
  const metaTitle = ring?.meta_title;
  const metaKeyword = ring?.meta_keyword;
  const metaDescription = ring?.meta_description;
  const ringOptionLabel = ringOptions?.find(
    (option) => +option.value === +ringOptionValue
  )?.label;

  const metalType =
    configurableOptions?.find(
      (option) => option.attribute_code === "ring_metal"
    ) ?? {};
  const metals =
    metalType?.values?.map((value) => value.label).join(", ") ?? "";

  const sizes =
    configurableOptions
      ?.find((option) => option.attribute_code === "ring_size")
      .values.filter((size) => size.label !== "0") ?? [];

  const colors = configurableOptions?.find(
    (option) => option.attribute_code === "ring_color"
  );

  const selectedColorLabel = colors?.values.find(
    (color) => color.label.toLowerCase().replace(" ", "-") === selectedColor
  )?.label;

  const handleColorChange = (value) => () => {
    updateURLColor(value.toLowerCase().replace(" ", "-"));
  };
  const handleMetalChange = (value) => () => {
    updateURLMetal(value.toLowerCase());
  };

  const affirmRef = useAffirmMutationObserver(price);

  return (
    <div className="product-list">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeyword} />
        <meta property="og:id" content={variantSKU} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={thumbnail} />
        <meta property="og:url" content={window.location.href} />
        <meta property="product:brand" content={brand} />
        <meta property="product:item_group_id" content={variantSKU} />
        <meta property="product:price:amount" content={price} />
        <meta property="product:availability" content="in stock" />
        <meta property="product:condition" content="new" />
        <meta property="product:price:currency" content="USD" />
      </Helmet>
      {error && selectRingError && (
        <div className="ring_error" onClick={() => setSelectRingError(false)}>
          {error}
        </div>
      )}
      <div className="diamond-detail-container-builder">
        <RingDiamondSelected diamondShapes={diamondShapesOptions} />
      </div>
      <div className="product__container">
        <RingImageSlider
          images={mediaGallery}
          loading={configuredRingLoading}
        />

        <div className="options__container">
          <section className="goods topNameBrandSection">
            <div className="header-builder-container">
              <p className="ring-name-builder">{name}</p>
            </div>
            <div className="header-builder-container">
              <p className="ring-brand-builder">{brand}</p>
            </div>
          </section>
          <div className="infoPriceSKUItem">
            <div className="ring-price-item-code-container">
              <div id="mobile-content-name-sku">
                <div className="ring-builder-item-code-text">
                  <p className="ring-name-builder">{name}</p>
                  Item #{sku}
                </div>
                <div className="ring-builder-price-text">
                  <span className="option-price">
                    ${cartUtil.priceFormatter(price)}
                  </span>
                </div>
              </div>

              <div id="non-mobile-content-name-sku">
                <div className="ring-builder-price-text">
                  Price{" "}
                  <span className="option-price">
                    ${cartUtil.priceFormatter(price)}
                  </span>
                </div>
                <div className="ring-builder-item-code-text">Item #{sku}</div>
              </div>
            </div>
            <div className="ring-builder-price-text">
              <p
                ref={affirmRef}
                className="affirm-as-low-as product-detail-mobile-affirm"
                data-page-type="category"
                data-amount={price * 100}
                data-learnmore-show="false"
              />
            </div>
            <hr />
            <div className="ring-builder-trust-list-container">
              <ul className="ring-builder-trust-list">
                <li className="ring-build-trust-list-item">
                  <img
                    src={require("img/check-verify.png").default}
                    alt="check-verify"
                    className="ring-img-green-check"
                  />
                  <span>Free Shipping.</span>
                </li>
                <li className="ring-build-trust-list-item">
                  <img
                    src={require("img/check-verify.png").default}
                    alt="check-verify"
                    className="ring-img-green-check"
                  />
                  <span>Free Returns.</span>
                </li>
                <li className="ring-build-trust-list-item">
                  <img
                    src={require("img/shield-checked.png").default}
                    alt="shield-checked"
                    className="ring-img-green-check"
                  />
                  <span>100% Risk Free.</span>
                </li>
              </ul>
            </div>

            <div className="ring-builder-categories-buttons">
              <div className="ring-builder-ring-metal-buttons">
                <p>{`Select ${metalType.label ?? "Metal Type"}`}</p>
                {metalType.values?.map((metal) => (
                  <div
                    autoFocus
                    key={metal.value_index}
                    className={`ring-builder-metal-item-button ${
                      metal.label.toLowerCase() === selectedMetal
                        ? "active-button"
                        : ""
                    }`}
                    onClick={handleMetalChange(metal.label)}
                  >
                    <img
                      src={require("img/rings-type-metal.png").default}
                      className="ring-builder-ring-metal-img"
                      alt={metal.label}
                    />
                    {metal.label}
                  </div>
                ))}
              </div>
              <div className="ring-builder-ring-colors-buttons">
                <p>{`Select ${colors?.label ?? "Ring Color"}`}</p>
                {colors?.values?.map((color) => {
                  return (
                    <div
                      key={color.value_index}
                      className={`ring-builder-color-item-button ${
                        color.label.toLowerCase().replace(" ", "-") ===
                        selectedColor
                          ? "active-button"
                          : ""
                      }`}
                      onClick={handleColorChange(color.label)}
                    >
                      <span
                        className={color.label
                          .toLowerCase()
                          .replace("gold", "")}
                      ></span>
                      {color.label}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <RingAndDiamondDetails
        description={description}
        options={ringOptionLabel}
        metals={metals}
        sizes={sizes}
        setting={setting}
        sizeLimit={sizeLimit}
        accentStones={accentStones}
        diamondShapes={diamondShapes}
        sku={sku}
        selectedColor={selectedColorLabel}
      />

      <RingInfo />
      <RingFooter
        color={selectedColor?.replace("-", " ")}
        metal={selectedMetal}
        price={price}
        sizes={sizes}
        selectedSize={selectedSize}
        updateURLSize={updateURLSize}
        sku={variantSKU}
        setSelectRingError={setSelectRingError}
      />
    </div>
  );
}
