import React, { Fragment } from "react";
import { SBJoolezPrDescription } from "./joolezProductDescription/joolezProductDescription.component";
import { SBMediaSlider } from "./mediaSlider.component";
import { SBProdDetailItem } from "./productDetailItems/productDetailItems.component";
import { DiamondSearch } from "../../services/diamondsearch";
import { CartService } from "../../services/cartService";
import { CustomerService } from "../../services/customer.service";
import { GuestService } from "../../services/guest.service";
import { Redirect, Link } from "react-router-dom";
import Modal from "../../modal/modal.component";
import Loader from "../../loader.component";
import { config } from "../../configurations";
import { ReactComponent as CompareDiamondsIcon } from "img/comparison/compare-diamonds.svg";
import { connect } from "react-redux";
import {
  setCompareDiamonds,
  setCart,
  setShopByState,
  setDiamondId,
  setShopByQuery,
  setDiamondPurchased,
} from "../../../redux/diamond/actions";
import { setError } from "../../../redux/user/actions";
import { Prompt } from "react-router";
import ProductDescriptionIncluded from "components/shop.by.component/productDetails/joolezProductDescription/joolezProductDescriptionIncluded.component";
import Options from "components/shop.by.component/productDetails/options/options";
import DiamondEducationTabs from "./DiamondEducationTabs/DiamondEducationTabs";
import { AddToCartUtil } from "../../utils/addtocart.util";
import Tour from "reactour";
import TagManager from "react-gtm-module";
import { gtmId } from "../../consts";
import Hashids from "hashids";
import DocumentMeta from "react-document-meta";
import GiaContactForm from "./GiaContactForm/GiaContactForm";
import { formatDiamondSize } from "components/utils/formatDiamondSize";

import hrdLogo from "./GiaContactForm/hrd-emblem.png";
import eglLogo from "./GiaContactForm/EGL.png";
import giaLogo from "./GiaContactForm/gia-emblem.png";
import igiLogo from "./GiaContactForm/igi.png";
import joolezLogo from "./GiaContactForm/joolez-icon.png";

const LOGOS = [
  { title: "IGI", image: igiLogo },
  { title: "GIA", image: giaLogo },
  { title: "Joolez", image: joolezLogo },
  { title: "HRD", image: hrdLogo },
  { title: "EGL", image: eglLogo },
];

let diamondAssistantText = "";
export class SBProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.affirmFooterRef = React.createRef();
    this.affirmMobileRef = React.createRef();
    let accent = this.props.location.state && this.props.location.state.accent;
    this.Lg = 1200;
    this.state = {
      redirect: false,
      price: 0,
      size: 0,
      cut: 0,
      color: 0,
      clarity: 0,
      accent: accent,
      diamond: { details: [], sections: [], image_file_url: "" },
      diamondDetails: [],
      diamondId: "",
      showModal: false,
      showGiaCertModal: false,
      qualitysum: 0,
      calculated_quality: {},
      hasCert: false,
      compare: false,
      showShapes: false,
      shape: "",
      shapes: config.shapes,
      shownShapes: [],
      buttonsClicked: false,
      apiDiamond: {},
      infoTab: "diamond assistant",
      showLoader: false,
      list: [],
      diamondQueryUpdateCount: 0,
      addCompareActive: false,
      overallQuality: 1,
      steps: [
        {
          selector: ".product-param:nth-of-type(1) .product-param_controls",
          content: "Adjust Price using these buttons",
        },
        {
          selector: ".product-param:nth-of-type(2) .product-param_controls",
          content: "Adjust Size using these buttons",
        },
        {
          selector: ".product-param:nth-of-type(3) .product-param_controls",
          content: "Adjust Quality using these buttons",
        },
        {
          selector: ".product-param:nth-of-type(3) .progress-bar_description",
          content: "Bite-size 4C's diamond education - Follow the green bars",
        },
        {
          selector: ".diamond-history_btns",
          content: "Recently viewed diamonds are stored here, use < >",
        },
      ],
      isTourOpen: true,
      showMore: false,
      betterDeal: null,
    };
    this.iconMapping = config.goodsQualityIcons;
    this.customerSvc = new CustomerService();
    this.diamondSvc = new DiamondSearch();
    this.util = new AddToCartUtil();

    this.affirmFooterObserver = new MutationObserver((mutationList) => {
      const [
        {
          addedNodes: [node],
        },
      ] = mutationList;
      if (node && node.className === "affirm-modal-trigger") {
        this.wrapAffirmFooterMessageText();
      }
    });
    this.affirmMobileObserver = new MutationObserver((mutationList) => {
      const [
        {
          addedNodes: [node],
        },
      ] = mutationList;
      if (node && node.className === "affirm-modal-trigger") {
        this.wrapAffirmMobileMessageText();
      }
    });
  }

  componentWillMount() {
    let tourShown = localStorage.getItem("jTourShow");
    // do not show tour if user is not a first time visitor
    if (tourShown === "true") {
      this.setState({ isTourOpen: false });
    }
  }

  componentDidMount() {
    this.affirmFooterObserver.observe(this.affirmFooterRef.current, {
      childList: true,
    });
    this.affirmMobileObserver.observe(this.affirmMobileRef.current, {
      childList: true,
    });

    const hashids = new Hashids();
    let id = this.props.match.params.did;
    if (id == null) {
      id =
        this.props.selectedDiamonds[1] ||
        this.props.diamond_id ||
        localStorage.getItem("diamondId") ||
        sessionStorage.getItem("diamondId");
    } else {
      id = hashids.decode(id)[0];
    }

    if (!!id) {
      let diamond = this.diamondSvc.getDiamondById(id);
      diamond.then((res) => {
        if (!res.diamond) {
          this.props.setError("Diamond was not found");
          return;
        }

        this.setDiamondDetails(
          { ...res.diamond, size: formatDiamondSize(res.diamond.size) },
          res.calculated_quality,
          diamondAssistantText
        );
      });
    }

    /* Update history if user is coming from compare page*/
    if (
      this.props.selectedDiamonds.length > 0 ||
      (!!this.props.sb_state.history && !!this.props.sb_state.future)
    ) {
      this.setState({
        historyLength: this.props.sb_state.history.length,
        futureLength: this.props.sb_state.future.length,
      });
    }
    document.addEventListener("scroll", this.handleMobileBtnDisplay);
  }

  addMetaData(diamond) {
    const meta = {
      title: "Find the perfect Engagement Ring on Joolez. ",
      description:
        "Engagement rings worthy of your grand gesture moment. The search for your perfect diamond ring is over. ",
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "engagement rings, diamonds, diamond ring, diamond engagement ring, best engagement ring, joolez",
        },
      },
    };

    return <DocumentMeta {...meta} />;
  }

  routerWillLeave(nextLocation) {
    if (
      this.props.selectedDiamonds.indexOf(this.state.diamondId) > -1 &&
      !this.state.buttonsClicked
    ) {
      let away = window.confirm(
        "Are you sure you want to leave your comparison? Your choices will be lost."
      );
      if (!away) return false;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleMobileBtnDisplay);
    this.props.setError("");
    this.affirmFooterObserver.disconnect();
    this.affirmMobileObserver.disconnect();
  }

  wrapAffirmFooterMessageText() {
    const [elem] = this.affirmFooterRef.current.children || [];

    if (elem) {
      const affirmMessageHTML = elem.innerHTML;
      const splitContents = affirmMessageHTML.split(" ");
      const perMonth = splitContents.pop();

      if (perMonth === "/mo") {
        splitContents.push(`<span class="per-month">${perMonth}</span>`);
        elem.innerHTML = splitContents.join(" ");
      }
    }
  }

  wrapAffirmMobileMessageText() {
    const [elem] = this.affirmMobileRef.current.children || [];

    if (elem) {
      const affirmMessageHTML = elem.innerHTML;
      const splitContents = affirmMessageHTML.split(" ");
      const wrappedContent = splitContents.map((content) =>
        content.includes("/mo") && !content.includes('class="per-month"')
          ? content.replace("/mo", '<span class="per-month">/mo</span>')
          : content
      );
      elem.innerHTML = wrappedContent.join(" ");
    }
  }

  getDiamondStockImageUrl(shape) {
    const newShape = shape || this.state.shape.split(" ")[0];
    return config.diamondImages[newShape.toLowerCase()];
  }

  getYoutubeUrl(url) {
    const videoID = url.split("/").pop();
    return `https://youtube.com/embed/${videoID}?playlist=${videoID}&autoplay=1&mute=1&loop=1&controls=0&disablekb=1&fs=0&modestbranding=1`;
  }

  getDiamondSliderMedia(diamond) {
    const { video_url: videoUrl } = diamond;
    const stockImage = this.getDiamondStockImageUrl();
    const diamondImage = diamond.has_image_file && diamond.image_file_url;
    const video =
      diamond.has_video_file && videoUrl.includes("youtu.be")
        ? this.getYoutubeUrl(videoUrl)
        : videoUrl;

    return [
      { type: "video", src: video },
      { type: "image", src: stockImage },
      { type: "image", src: diamondImage },
    ].filter(({ src }) => Boolean(src));
  }

  selectForCompare = () => {
    let id = this.state.diamondId;

    let arr = this.props.selectedDiamonds;
    if (arr.indexOf(id) > -1) arr.splice(arr.indexOf(id), 1);
    else arr.push(id);
    if (arr.length > 2) arr.splice(0, 1);
    this.props.setCompareDiamonds(arr);
    this.setState({
      compare: this.props.selectedDiamonds.length === 2,
      addCompareActive: !this.state.addCompareActive,
    });
  };

  setDiamondDetails = (diamond, qualities, text) => {
    const tagManagerViewArgs = {
      gtmId: gtmId,
      dataLayer: {
        event: "productClick",
        choice: this.state.accent,
        eventLabel: "View Product",
        ecommerce: {
          click: {
            products: [
              {
                name: `${diamond.shape} ${diamond.size}ct ${diamond.color} ${diamond.clarity}`,
                id: diamond.diamond_id,
              },
            ],
          },
        },
      },
    };
    TagManager.dataLayer(tagManagerViewArgs);

    diamond.shape = diamond.shape.split(" ")[0];
    this.setState({
      overallQuality: qualities.overall,
      apiDiamond: diamond,
      price: diamond.total_sales_price,
      size: diamond.size,
      shape: diamond.shape,
      color: diamond.color,
      clarity: diamond.clarity,
      cut: diamond.cut,
      calculated_quality: qualities,
      diamondId: diamond.diamond_id,
      hasCert: diamond.has_cert_file,
      shownShapes: this.state.shapes.filter((el) => el !== diamond.shape),
      list: [
        {
          title: "price",
          color: "green",
          min: 0,
          max: 1000000,
          step: 2000,
          values: [
            this.props.sb_query.price_total_from || 1000,
            this.props.sb_query.price_total_to || 1000000,
          ],
          inputs: {
            label: "$",
          },
          query_name: "price_total",
        },
        {
          title: "carat",
          color: "carat",
          min: 0.25,
          max: 100,
          step: 0.2,
          values: [
            +diamond.size.toFixed(2) || 0.25,
            +diamond.size.toFixed(2) || 100,
          ],
          inputs: true,
          query_name: "size",
        },

        {
          title: "cut",
          color: "cut",
          values: [
            this.calculateIndexForList(
              ["Excellent", "Very Good", "Good", "Fair"],
              diamond.cut,
              true
            ),
            this.calculateIndexForList(
              ["Excellent", "Very Good", "Good", "Fair"],
              diamond.cut,
              false
            ),
          ],
          scale: ["Excellent", "Very Good", "Good", "Fair"],
        },
        {
          title: "color",
          color: "color",
          values: [
            this.calculateIndexForList(
              ["D", "E", "F", "G", "H", "I", "J"],
              diamond.color,
              true
            ),
            this.calculateIndexForList(
              ["D", "E", "F", "G", "H", "I", "J"],
              diamond.color,
              false
            ),
          ],
          scale: ["D", "E", "F", "G", "H", "I", "J"],
        },
        {
          title: "clarity",
          color: "clarity",
          values: [
            this.calculateIndexForList(
              ["IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "SI3", "I1"],
              diamond.clarity,
              true
            ),
            this.calculateIndexForList(
              ["IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "SI3", "I1"],
              diamond.clarity,
              false
            ),
          ],
          scale: [
            "IF",
            "VVS1",
            "VVS2",
            "VS1",
            "VS2",
            "SI1",
            "SI2",
            "SI3",
            "I1",
          ],
        },
        {
          title: "polish",
          color: "green",
          values: [
            this.calculateIndexForList(
              ["Excellent", "Very Good", "Good", "Fair"],
              diamond.polish,
              true
            ),
            this.calculateIndexForList(
              ["Excellent", "Very Good", "Good", "Fair"],
              diamond.polish,
              false
            ),
          ],
          scale: ["Excellent", "Very Good", "Good", "Fair"],
        },
        {
          title: "symmetry",
          color: "green",
          values: [
            this.calculateIndexForList(
              ["Excellent", "Very Good", "Good", "Fair"],
              diamond.symmetry,
              true
            ),
            this.calculateIndexForList(
              ["Excellent", "Very Good", "Good", "Fair"],
              diamond.symmetry,
              false
            ),
          ],
          scale: ["Excellent", "Very Good", "Good", "Fair"],
        },
      ],
    });

    let qualitysum = 0;
    Object.keys(qualities).forEach((key) => (qualitysum += qualities[key]));
    qualitysum -= qualities.overall;
    const icons = this.calculateIcons(diamond);
    this.setState({
      qualitysum: qualitysum || 1,
    });

    let diamondDetail = [
      {
        title: "Diamond Details",
        titleClassName: "_under-md",
        data: [
          ["Shape", diamond.shape],
          ["Carat", diamond.size],
          ["Certificate", diamond.cert_num],
        ],
      },
      {
        title: "Diamond Structure",
        data: [
          ["Cut", diamond.cut || "N/A"],
          ["Color", diamond.color],
          ["Clarity", diamond.clarity],
          ["Symmetry", diamond.symmetry_to || "N/A"],
          ["Polish", diamond.polish],
          ["Fluorescence Intensity", diamond.fluorescence_intensity || "N/A"],
          ["Fluorescence Color", diamond.fluorescence_color || "N/A"],
        ],
      },
      {
        title: "Diamond Conformity",
        data: [
          [
            "Measurements",
            diamond.meas_length +
              " x " +
              diamond.meas_width +
              " x " +
              diamond.meas_depth +
              " mm",
          ],
          ["Table (%)", `${diamond.table_percent}%`],
          ["Depth (%)", `${diamond.depth_percent}%`],
          ["Girdle", diamond.girdle_condition],
          ["Culet", diamond.culet_size || "N/A"],
          ["Conflict", "Free"],
        ],
      },
    ];
    this.setState({ diamondDetails: diamondDetail });

    diamond.image_file_url = diamond.image_file_url
      ? diamond.image_file_url
      : this.getDiamondStockImageUrl();
    diamond.sliderMedia = this.getDiamondSliderMedia(diamond);
    diamond.details = [
      {
        title: "Cut:",
        text: diamond.cut || "N/A",
        iconClass1: "ico ico-DiamondSimple -cut",
        chargeIcon: this.iconMapping[icons && icons.cut],
        selected: icons && icons.cut - 1,
      },
      {
        title: "Color:",
        text: diamond.color,
        iconClass1: "ico ico-DiamondSimple -color",
        chargeIcon: this.iconMapping[icons && icons.color],
        selected: icons && icons.color - 1,
      },
      {
        title: "Clarity:",
        text: diamond.clarity,
        iconClass1: "ico ico-DiamondSimple -clarity",
        chargeIcon: this.iconMapping[icons && icons.clarity],
        selected: icons && icons.clarity - 1,
      },
      {
        icons: "product-details_icons-text",
        iconText: diamond.size,
        iconClass1: "ico ico-DiamondSimple -carat",
        text: diamond.size,
        title: "Carat:",
      },
    ];
    diamond.assistantTitle = "Diamond Assistant";
    diamond.assistantText = (text && (text.content || text.message)) || "";
    diamond.sections = [
      {
        title: "Price",
        value: diamond.total_sales_price,
        currency: diamond.currency_symbol,
        percentage: +(diamond.total_sales_price / 1500).toFixed(0),
        type: "simple",
        style: "-price",
        percent: 1,
      },
      {
        title: "Size",
        value: diamond.size,
        currency: "ct",
        percentage: +(diamond.size / 0.045).toFixed(2),
        type: "simple",
        description: "ico ico-DiamondSimple product-param_ico -carat",
        style: "-size",
        percent: 1,
      },
      {
        title: "Quality",
        value: diamond.total_sales_price,
        currency: diamond.currency_symbol,
        percentage: 100,
        type: "multi",
        lines: [
          {
            class: "-cut",
            width: (qualities.cut / (qualitysum - qualities.carat)) * 100,
          },
          {
            class: "-color",
            width: (qualities.color / (qualitysum - qualities.carat)) * 100,
          },
          {
            class: "-clarity",
            width: (qualities.clarity / (qualitysum - qualities.carat)) * 100,
          },
        ],
        descriptions: [
          {
            width:
              ((config.cut.length - config.cut.indexOf(diamond.cut)) /
                config.cut.length) *
              100,
            style: "-cut",
            param: diamond.cut || "N/A",
            name: "Cut",
            chargeIcon: this.iconMapping[icons && icons.cut],
            percent: +(100 / config.cut.length).toFixed(0),
            selected: icons && icons.cut - 1,
          },
          {
            width:
              ((config.color.length - config.color.indexOf(diamond.color)) /
                config.color.length) *
              100,
            style: "-color",
            param: diamond.color,
            name: "Color",
            chargeIcon: this.iconMapping[icons && icons.color],
            percent: +(100 / config.color.length).toFixed(0),
            selected: icons && icons.color - 1,
          },
          {
            width:
              ((config.clarity.length -
                config.clarity.indexOf(diamond.clarity)) /
                config.clarity.length) *
              100,
            style: "-clarity",
            param: diamond.clarity,
            name: "Clarity",
            chargeIcon: this.iconMapping[icons && icons.clarity],
            percent: +(100 / config.clarity.length).toFixed(0),
            selected: icons && icons.clarity - 1,
          },
        ],
      },
    ];
    this.setState({ diamond: diamond });
  };

  calculateIndexForList = (array, item, start) => {
    let i =
      array.indexOf(item) >= 0
        ? array.indexOf(item)
        : start
        ? 0
        : array.length - 1;
    if (!start && i !== array.length - 1) i++;
    if (start && i === array.length - 1) i--;
    return i;
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/cart" />;
    }
  };

  cartRedirect = () => {
    this.props.sendDiamond({
      id: this.props.diamond_id,
      price: this.state.price,
      size: this.state.size,
      shape: this.state.diamond.shape,
    });
    let cart = this.props.cart;
    cart.push({
      id: this.props.diamond_id,
      price: this.state.price,
      size: this.state.size,
      shape: this.state.diamond.shape,
      cut: this.state.cut,
      clarity: this.state.clarity,
      color: this.state.color,
      url: this.state.diamond.image_file_url || "",
    });
    this.props.setCart(cart);
    this.props.setDiamondPurchased(true);
    this.setState({ showModal: false, redirect: true });

    const tagManagerAddCartArgs = {
      gtmId: gtmId,
      dataLayer: {
        event: "addToCart",
        eventLabel: "Add To Cart",
        ecommerce: {
          //hard code the currency code for now since we do not deal with other countries, and our application state will no indicate what our currency is
          currencyCode: "USD",
          add: {
            products: [
              {
                name: `${this.state.shape} ${this.state.size}ct ${this.state.color} ${this.state.clarity}`,
                id: this.props.diamond_id,
                quantity: "1",
                price: this.state.price,
              },
            ],
          },
        },
      },
    };
    TagManager.dataLayer(tagManagerAddCartArgs);
  };

  addToCart = () => {
    this.props.setDiamondId(this.state.diamond.diamond_id);
    this.setState({ showModal: true, buttonsClicked: true });
    //let id = this.props.diamond_id;
    let cart = new CartService();
    this.customerSvc.getCustomer().then((res) => {
      if (!!res.id) {
        cart.getCustomerCart().then((res) => {
          if (res.id) {
            sessionStorage.cartId = res.id;
            cart
              .addDiamondToCart(
                res.id,
                this.state.diamond.diamond_id,
                "customer"
              )
              .then((res) => {
                if (res === "true") {
                  localStorage.diamondId = this.state.diamond.diamond_id;
                  this.customerSvc
                    .setCustomerAddress("billing", {
                      address: {},
                    })
                    .then((res) => this.cartRedirect());
                } else {
                  this.props.setError("Item was not added to cart");
                  this.setState({ showModal: false });
                }
              });
          } else {
            cart.createCustomerCart().then((res) => {
              if (res && typeof res == "number") {
                sessionStorage.cartId = res;
                cart
                  .addDiamondToCart(
                    res,
                    this.state.diamond.diamond_id,
                    "customer"
                  )
                  .then((res) => {
                    if (res === "true") {
                      localStorage.diamondId = this.state.diamond.diamond_id;
                      this.customerSvc
                        .setCustomerAddress("billing", {
                          address: {},
                        })
                        .then((res) => this.cartRedirect());
                    } else {
                      this.props.setError("Item was not added to cart");
                      this.setState({ showModal: false });
                    }
                  });
              }
            });
          }
        });
      } else {
        let guestId = localStorage.getItem("guestCartId");
        if (!!guestId) {
          cart.getGuestCart(guestId).then((res) => {
            if (res.id) {
              cart
                .addDiamondToCart(res.id, this.state.diamond.diamond_id)
                .then((res) => {
                  if (res === "true") {
                    localStorage.diamondId = this.state.diamond.diamond_id;
                    new GuestService(guestId)
                      .setGuestAddress("billing", {
                        address: {},
                      })
                      .then((res) => this.cartRedirect());
                  } else {
                    this.props.setError("Item was not added to cart");
                    this.setState({ showModal: false });
                  }
                });
            } else {
              cart.createGuestCart().then((res) => {
                localStorage.guestCartId = res;
                let id = res;
                cart.getGuestCart(res).then((res) => {
                  cart
                    .addDiamondToCart(res.id, this.state.diamond.diamond_id)
                    .then((res) => {
                      if (res === "true") {
                        localStorage.diamondId = this.state.diamond.diamond_id;
                        new GuestService(id)
                          .setGuestAddress("billing", {
                            address: {},
                          })
                          .then((res) => this.cartRedirect());
                      } else {
                        this.props.setError("Item was not added to cart");
                        this.setState({ showModal: false });
                      }
                    });
                });
              });
            }
          });
        } else {
          cart.createGuestCart().then((res) => {
            localStorage.guestCartId = res;
            let id = res;
            cart.getGuestCart(res).then((res) => {
              cart
                .addDiamondToCart(res.id, this.state.diamond.diamond_id)
                .then((res) => {
                  if (res === "true") {
                    localStorage.diamondId = this.state.diamond.diamond_id;
                    new GuestService(id)
                      .setGuestAddress("billing", {
                        address: {},
                      })
                      .then((res) => this.cartRedirect());
                  } else {
                    this.props.setError("Item was not added to cart");
                    this.setState({ showModal: false });
                  }
                });
            });
          });
        }
      }
    });
  };

  getDiamondDetails = (type, payload) => {
    this.diamondSvc
      .editDiamond(type, payload, this.props.sb_state, this.state.apiDiamond)
      .then((res) => {
        this.setState({
          historyLength: res.state.history.length,
          futureLength: res.state.future.length,
        });

        if (!!res.state) {
          this.props.setShopByState(res.state);
          sessionStorage.setItem("diamondState", JSON.stringify(res.state));
        }
        if (
          res.joolez_recommended &&
          res.joolez_recommended.diamond.diamond_id
        ) {
          diamondAssistantText = res.status;
          this.setState({ betterDeal: res.status.better_deal });
          this.diamondSvc
            .getDiamondById(res.joolez_recommended.diamond.diamond_id)
            .then((res) => {
              const hashids = new Hashids();
              let encoded = hashids.encode(res.diamond.diamond_id);
              this.props.history.push("/product-details/" + encoded);
              this.setDiamondDetails(
                { ...res.diamond, size: formatDiamondSize(res.diamond.size) },
                res.calculated_quality,
                diamondAssistantText
              );
            });
        }
        this.setState({
          showLoader: false,
        });
      })
      .catch((error) => {
        // remove loader if server times out
        this.setState({
          showLoader: false,
        });
      });
  };

  changeShape = (e) => {
    const { diamond } = this.state;

    const newShape = e.target.id;

    this.setState(
      {
        shape: newShape,
        showShapes: false,
        shownShapes: this.state.shapes.filter((el) => el !== e.target.id),
        diamond,
        addCompareActive: false,
        diamondQueryUpdateCount: this.state.diamondQueryUpdateCount + 1,
      },
      () => this.executeNewQuery()
    );

    const tagManagerChangeShapeArgs = {
      gtmId: gtmId,
      dataLayer: {
        event: "productChangeShape",
        shape: newShape,
      },
    };
    TagManager.dataLayer(tagManagerChangeShapeArgs);
  };

  reduce = (data) => {
    let type = "adjust";
    let payload = {
      attr: data,
      dir: "dec",
    };

    this.setState({
      showLoader: true,
      addCompareActive: false,
    });
    this.getDiamondDetails(type, payload);
  };

  increase = (data) => {
    let type = "adjust";
    let payload = {
      attr: data,
      dir: "inc",
    };

    this.setState({
      showLoader: true,
      addCompareActive: false,
    });
    this.getDiamondDetails(type, payload);
  };

  updateQuery = (list, title, values) => {
    this.setState({
      list,
      diamondQueryUpdateCount: this.state.diamondQueryUpdateCount + 1,
    });
    let sb_query = this.props.sb_query;
    sb_query.price_total_from = list[0].values[0];
    sb_query.price_total_to = list[0].values[1];
    this.props.setShopByQuery(sb_query);
    //Passing in title and values to use in gtm event
    const tagManagerChangeMoreArgs = {
      gtmId: gtmId,
      dataLayer: {
        event: "productChangeMore",
        eventLabel: "Change - " + title + " - " + values.toString(),
      },
    };
    TagManager.dataLayer(tagManagerChangeMoreArgs);
  };

  executeNewQuery = () => {
    if (this.state.diamondQueryUpdateCount === 0) return;

    let payload = {};
    let type = "set";
    let newShape = this.state.shape.split(" ")[0];
    this.state.list.forEach((criteria) => {
      if (criteria.values) {
        if (criteria.scale) {
          if (criteria.query_name) {
            payload[criteria.query_name + "_from"] =
              criteria.scale[criteria.values[0]];
            payload[criteria.query_name + "_to"] =
              criteria.scale[criteria.values[1] - 1];
          } else {
            payload[criteria.title + "_from"] =
              criteria.scale[criteria.values[0]];
            payload[criteria.title + "_to"] =
              criteria.scale[criteria.values[1] - 1];
          }
        } else {
          if (criteria.query_name) {
            payload[criteria.query_name + "_from"] = parseFloat(
              Number(criteria.values[0]).toFixed(2)
            );
            payload[criteria.query_name + "_to"] = parseFloat(
              Number(criteria.values[1]).toFixed(2)
            );
          } else {
            payload[criteria.title + "_from"] = criteria.values[0];
            payload[criteria.title + "_to"] = criteria.values[1];
          }
        }
      }
    });
    payload["shape"] = newShape;
    this.setState({
      showLoader: true,
      addCompareActive: false,
    });
    this.getDiamondDetails(type, payload);
  };

  calculateIcons = (diamond) => {
    let icons = {};

    switch (diamond.cut) {
      case "Excellent":
        icons.cut = 5;
        break;
      case "Very Good":
        icons.cut = 4;
        break;
      case "Good":
        icons.cut = 3;
        break;
      case "Fair":
        icons.cut = 2;
        break;
      case "Poor":
        icons.cut = 1;
        break;
      default:
        icons.cut = 0;
    }

    switch (diamond.color) {
      case "D":
      case "E":
      case "F":
        icons.color = 5;
        break;
      case "G":
      case "H":
      case "I":
      case "J":
        icons.color = 4;
        break;
      case "K":
      case "L":
      case "M":
        icons.color = 3;
        break;
      default:
        return null;
    }

    switch (diamond.clarity) {
      case "IF":
        icons.clarity = 5;
        break;
      case "VVS1":
      case "VVS2":
        icons.clarity = 4;
        break;
      case "VS1":
      case "VS2":
        icons.clarity = 3;
        break;
      case "SI1":
      case "SI2":
        icons.clarity = 2;
        break;
      case "I1":
      case "I2":
      case "I3":
        icons.clarity = 1;
        break;
      default:
        return null;
    }
    return icons;
  };

  handleCompareClick = (e) => {
    e.preventDefault();
    let url = e.target.href;
    this.setState(
      {
        buttonsClicked: true,
      },
      () => {
        this.props.history.push({
          pathname: url.substring(url.lastIndexOf("/")),
          state: {
            ids: this.props.selectedDiamonds,
            features: {},
          },
        });
      }
    );
  };

  getDiamondHistory = (payload) => {
    this.setState({ showLoader: true });
    this.getDiamondDetails("command", payload);
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
    localStorage.setItem("jTourShow", true);
    document
      .querySelector(".product-page__fixed-footer .product__select-btn")
      .classList.add("active");
  };

  activateTour = () => {
    this.setState({ isTourOpen: true });
  };

  handleMobileBtnDisplay = () => {
    const windowWidth = window.innerWidth,
      scrollPosition = document.documentElement.scrollTop,
      selectBtn = document.querySelector(
        ".product-page__fixed-footer .product__select-btn"
      );

    if (windowWidth < this.Lg) {
      if (scrollPosition >= 80) {
        selectBtn.classList.add("active");
      }
    } else {
      return;
    }
  };

  showMoreOptions = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  showGiaCertModal = () => this.setState({ showGiaCertModal: true });
  hideGiaCertModal = () => this.setState({ showGiaCertModal: false });

  render() {
    const {
      accent,
      infoTab,
      steps,
      isTourOpen,
      showLoader,
      showGiaCertModal,
      diamondId,
      diamond: { assistantTitle, sections },
    } = this.state;

    let diamondCompareIconClass =
      this.props.selectedDiamonds.length > 0 ? "-active " : "";
    diamondCompareIconClass +=
      this.props.selectedDiamonds.length === 1
        ? "-one"
        : this.props.selectedDiamonds.length === 2
        ? "-two"
        : "";
    document.title = "Product Details - Joolez";
    const root = document.querySelector("#root");

    return (
      <div className="product product-page">
        {this.addMetaData(this.state.diamond)}
        <Tour
          onRequestClose={this.closeTour}
          steps={steps}
          isOpen={isTourOpen}
          rounded={5}
          disableInteraction={true}
          className="custom-tour"
          accentColor="#00ffc6"
          onAfterOpen={(target) => (root.style.overflowY = "hidden")}
          onBeforeClose={(target) => (root.style.overflowY = "")}
          lastStepNextButton={<span className="btn -sm">Got it</span>}
          startAt={0}
          closeWithMask={false}
          inViewThreshold={
            window.innerWidth >= this.Lg
              ? 50
              : window.innerWidth >= 800 || window.innerWidth <= this.lg
              ? 30
              : 19
          }
        />
        <Prompt
          when={
            this.props.selectedDiamonds.indexOf(this.state.diamondId) > -1 &&
            !this.state.buttonsClicked
          }
          message="Are you sure you want to leave your comparison? Your choices will be lost."
        />
        {this.renderRedirect()}
        {this.state.showModal && (
          <Modal>
            <Loader />
          </Modal>
        )}

        <div className="product-page__up">
          <div className="product-page__diamond">
            <SBMediaSlider
              diamondId={diamondId}
              media={this.state.diamond.sliderMedia || []}
              className="product-page__slider"
            />

            <SBProdDetailItem
              className="_product-page product-page__bars"
              details={(this.state.diamond && this.state.diamond.details) || []}
              qualityClass={4}
            />

            <div className="gia_cert" onClick={this.showGiaCertModal}>
              {LOGOS.map((logo) => (
                <div className="logo-container">
                  <img src={logo.image} alt={logo.title} />
                </div>
              ))}
            </div>

            <div className="product-info _product-page">
              <div className="product-info_row">
                <div className="product-info_col product-page__shape-select">
                  <div className="product-info_shape">
                    <button
                      className="label product-info_shape-label -lg"
                      onClick={() =>
                        this.setState({ showShapes: !this.state.showShapes })
                      }
                    >
                      {this.state.shape}
                      <span className="ico ico-ArrowBottom"></span>
                    </button>

                    <div className="diamond-compare-icons">
                      <button
                        className={
                          "ctrl -compare -lg product-info_compare " +
                          diamondCompareIconClass
                        }
                      >
                        <CompareDiamondsIcon />
                      </button>
                      <button
                        className={`btn -xs -secondary product-info_compare_btn ${
                          this.state.addCompareActive ? "-active" : ""
                        }`}
                        onClick={this.selectForCompare}
                      >
                        Add to compare
                      </button>
                    </div>
                  </div>
                  {this.state.showShapes && (
                    <div className="shapes-dropdown">
                      {this.state.shownShapes.map((el) => (
                        <div id={el} key={el} onClick={this.changeShape}>
                          {el}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="product-info_row _under-lg">
                <div className="product-info_col -left">
                  <div className="product-info_accent">{this.state.accent}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            ref={this.affirmMobileRef}
            className="affirm-as-low-as product-detail-mobile-affirm"
            data-page-type="category"
            data-amount={`${this.state.price * 100}`}
            data-learnmore-show="false"
          />

          <article className="product-assistant _product-page product-page__tabs">
            <header className="product-assistant_header">
              <h2
                className={`product-assistant_title diamond-assistant ${
                  assistantTitle && infoTab === assistantTitle.toLowerCase()
                    ? "_active"
                    : ""
                }`}
                onClick={() =>
                  this.setState({ infoTab: assistantTitle.toLowerCase() })
                }
              >
                <div className="title">{assistantTitle}</div>
                <img
                  className="product-assistant_icon"
                  src={
                    require("img/product-page/diamond-assistance.gif").default
                  }
                  alt="Diamond assistant"
                />
                <div className="product-assistant_help-text">
                  For assistance, change the Diamond by using the
                  <div className="product-param_controls">
                    <button className="ctrl -bordered -sm">
                      <span className="ico ico-Minus"></span>
                    </button>
                    <span className="ctrl">&</span>
                    <button className="ctrl -bordered -sm">
                      <span className="ico ico-Plus"></span>
                    </button>
                  </div>
                </div>
              </h2>
            </header>
          </article>

          <Options
            choice={accent}
            className="product-page__params"
            loading={showLoader}
            sections={(this.state.diamond && this.state.diamond.sections) || []}
            addCompare={this.selectForCompare}
            sendQuery={this.updateQuery}
            findNewDiamond={this.executeNewQuery}
            reduce={this.reduce}
            increase={this.increase}
            stateOfProd={this.state}
            list={this.state.list}
            diamondCompareIconClass={diamondCompareIconClass}
            addCompareActive={this.state.addCompareActive}
            diamondHistory={this.getDiamondHistory}
            enablePrevBtn={this.state.historyLength > 0}
            enableNextBtn={this.state.futureLength > 0}
            enableTour={this.activateTour}
            handleMoreOptions={this.showMoreOptions}
            moreOptionsEnabled={this.state.showMore}
            betterDeal={this.state.betterDeal}
          />

          <div className="product-assistant_content product-page__info">
            <div className="product-page__info-text">
              {(this.state.historyLength > 0 ||
                this.state.futureLength > 0) && (
                <Fragment>
                  {(() => {
                    switch (infoTab) {
                      case assistantTitle && assistantTitle.toLowerCase():
                        return (
                          this.state.diamond.assistantText &&
                          this.state.diamond.assistantText.map((item) => (
                            <p key={item}>{item}</p>
                          ))
                        );
                      case "finance options":
                        return (
                          <>
                            <p>(Dynamically Generated Content by NLP).</p>
                            <p>Finance Options text</p>
                          </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </Fragment>
              )}
            </div>
          </div>
        </div>

        <DiamondEducationTabs
          descriptions={
            sections.find((section) => section.title === "Quality")
              ?.descriptions ?? []
          }
        />

        <SBJoolezPrDescription
          className="_product-page"
          diamondDetailsTables={this.state.diamondDetails}
        />
        {!this.state.compare && (
          <footer
            className="product-page__fixed-footer"
            style={{ display: this.state.showMore ? "none" : "" }}
          >
            <div className="container">
              <div className="product-page__fixed-footer-container">
                <div className="product-page__fixed-footer-content">
                  <ProductDescriptionIncluded className="_in-fixed-footer" />
                </div>

                <div
                  className={`product-page__fixed-footer-item ${
                    this.state.price > config.affirmLimit ? "hide" : ""
                  }`}
                >
                  <div className="product-page__fixed-footer-price">
                    <p
                      ref={this.affirmFooterRef}
                      className="affirm-as-low-as product-detail-footer-affirm"
                      data-page-type="banner"
                      data-amount={`${this.state.price * 100}`}
                      data-learnmore-show="false"
                      data-affirm-type="symbol"
                    />
                  </div>
                  <div className="product-page__fixed-footer-description">
                    or ${this.util.priceFormatter(this.state.price)}
                  </div>
                </div>
                {this.state.price > config.affirmLimit && (
                  <div className="product-page__fixed-footer-item">
                    <div className="product-page__fixed-footer-price over-limit">
                      ${this.util.priceFormatter(this.state.price)}
                    </div>
                    <div className="product-page__fixed-footer-description">
                      Best Value
                    </div>
                  </div>
                )}

                <button
                  className="btn -shadow product__select-btn product-page__fixed-footer-btn"
                  onClick={this.addToCart}
                >
                  Select diamond
                </button>
              </div>
            </div>
          </footer>
        )}
        {this.state.compare && (
          <Link
            className="btn -shadow product__select-btn product-page__fixed-footer-btn compare"
            to="/compare-diamonds"
            onClick={this.handleCompareClick}
          >
            Compare
          </Link>
        )}
        {showGiaCertModal && (
          <GiaContactForm
            diamondId={diamondId}
            hideGiaCertModal={this.hideGiaCertModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedDiamonds: state.diamond.selected_diamonds,
  diamond_id: state.diamond.diamond_id,
  cart: state.diamond.cart,
  sb_state: state.diamond.sb_state,
  sb_query: state.diamond.query,
});

const mapDispatchToProps = {
  setCompareDiamonds,
  setCart,
  setError,
  setShopByState,
  setDiamondId,
  setShopByQuery,
  setDiamondPurchased,
};

export default connect(mapStateToProps, mapDispatchToProps)(SBProductDetails);
