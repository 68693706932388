import { useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { request, gql } from "graphql-request";
import { config } from "components/configurations";
const { magentoGraphqlURL } = config;

const RING_BY_SKU_QUERY = gql`
  query($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        id
        name
        sku
        ring_options
        setting
        size_limit
        accent_stones
        diamond_shapes
        url_key
        brand
        meta_title
        meta_keyword
        meta_description
        media_gallery {
          url
        }
        thumbnail {
          url
        }
        short_description {
          html
        }
        price_range {
          minimum_price {
            regular_price {
              currency
              value
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            attribute_code
            values {
              value_index
              label
            }
          }
        }
      }
    }
  }
`;

export function useConfiguredRing(sku) {
  const previousSKU = useRef(sku);
  const queryClient = useQueryClient();
  return useQuery(
    ["configured-ring", sku],
    ({ queryKey }) => {
      const [, sku] = queryKey;
      const variables = { filter: { sku: { eq: sku } } };

      return request(magentoGraphqlURL, RING_BY_SKU_QUERY, variables).then(
        (data) => {
          const ring = data?.products.items?.[0];
          previousSKU.current = ring?.sku;
          return ring;
        }
      );
    },
    {
      placeholderData: () => {
        const sku = previousSKU.current;
        const ring = queryClient.getQueryData([
          "configured-ring",
          sku?.toLowerCase(),
        ]);
        return ring;
      },
    }
  );
}
