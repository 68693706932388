import React from "react";
import ProductDescriptionIncluded from "./joolezProductDescriptionIncluded.component";
import { JoolezInfoService } from "components/services/joolezDetails.service";

export class SBJoolezPrDescription extends React.Component {
  constructor(props) {
    super(props);
    this.info = new JoolezInfoService().getDetails();
  }

  render() {
    const { className } = this.props;

    return (
      <div className={`product-description ${className || ""}`}>
        <ProductDescriptionIncluded />
        <div className="product-description__wrapper">
          <section className="info-block product-description__assurance">
            <header className="info-block_header product-description__header">
              <h3 className="info-block_title product-description__title">
                Quality Assurance
              </h3>
            </header>

            <div className="content info-block_content product-description__content">
              <p>Every order is Verified and Secure</p>
              <ul className="-nomarks product-description__list">
                <li className="mark-ico -Check2 product-description__list-item">
                  Every diamond is inspected by a GIA certified Gemologist prior
                  to shipping
                </li>
                <li className="mark-ico -Leaf product-description__list-item">
                  Every Natural Diamond is Authentic and Conflict Free
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default SBJoolezPrDescription;
