import React from 'react';
import SBShapeSelector from "../shopbyprice/sbshapeSelector.component";
import {Link} from 'react-router-dom';
import {config} from "../../configurations";
import {connect} from 'react-redux';
import {setShopByQuery} from "../../../redux/diamond/actions";
import {diamondShapesDescription} from '../../consts';
import TagManager from "react-gtm-module";
import {gtmId} from "../../consts";

const MOCK_SHAPE_SELECTOR = config.SHAPE_SELECTOR;
export class SBShape1 extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            shape: this.getShapeObject('Round'),
            query: {shapes: ['Round']}
        }
    }

    componentDidMount() {
            const tagManagerArgsMount = {
                gtmId: gtmId,
                dataLayer: {
                    event: 'Shop By',
                    shop_by: 'Shape'

                },
            }
            TagManager.dataLayer(tagManagerArgsMount);
    }

    setSelectedShape = (shape) => {
        const shapeObj = this.getShapeObject(shape);

        const tagManagerArgsShape = {
            gtmId: 'GTM-PCLGMLR',
            dataLayer: {
                event: 'Shape Change',
                shape: shapeObj.key

            },
        }
        TagManager.dataLayer(tagManagerArgsShape);
        this.setState({shape: shapeObj, query: {shapes: [shape]}});
        this.props.setShopByQuery({shapes: [shape]});
    }

    getShapeObject = (key) => {
        return MOCK_SHAPE_SELECTOR.filter( el => el.key === key)[0] || {};
    }

    render() {
        const {
            img,
            srcSet,
        } = this.state.shape;

        let circleOfShape = MOCK_SHAPE_SELECTOR.map(el => {
			const {
				img,
				srcSet,
			} = el;
      let selected = '';
        if (this.state.shape.key === el.key) {
          selected = 'active';
        }

            return (
                <div className={`shape ${selected}`} key={el.key+'div'}>
                    <SBShapeSelector
                        img={srcSet ? srcSet[0] : img}
                        alt={el.alt}
                        id={el.key}
                        shapeSelected={this.setSelectedShape}
                    />
                </div>
            );
        });

        let trendStyle={width: this.state.shape.trend};

        return(
            <section className="page">

                <header className="page-header">
                    <h1 className="page-title">
                    What shape are you looking for?
                    </h1>
                    <div className="page-description">
                    All diamonds are GIA certified.
                    </div>
                </header>

          <form className="by-shape">

            <div className="by-shape_wrapper">
              <div className="shapes">
                <div className="shapes-inner">

                  <div className="shapes-center">
                   {circleOfShape}
                  </div>

                </div>

                <div className="shape-selected">
                  <div className="shape-selected_pic">
                    <img
                        className="shape-selected_img"
                        src={srcSet ? srcSet[0] : img}
                        alt={this.state.shape.alt}
                    />
                  </div>
                  <div className="shape-selected_descr">
                  Trending {this.state.shape.trend}
                  </div>
                  <div className="shape-selected_progress">
                    <div className="progress">
                      <div className="progress-bar" style={trendStyle}></div>
                    </div>
                  </div>
                  <div className="shape-selected_accent">
                    Hot
                    <span className="ico ico-Fire"></span>
                  </div>
                  <div className="shape-selected_label">
                    <div className="label -sm">{this.state.shape.key}</div>
                  </div>
                </div>

              </div>
              </div>
            <div className="by-shape_description">
              {diamondShapesDescription[`${this.state.shape.key.toLowerCase()}`]}
            </div>
            <footer className="by-shape_footer">
              <Link to="/shop-by-size/step1" className="btn" type="submit">Continue</Link>
            </footer>

          </form>

        </section>
        )
    }
}

const mapStateToProps = state => ({
  query: state.diamond.query
});

const mapDispatchToProps ={
   setShopByQuery
};

export default connect(mapStateToProps, mapDispatchToProps)(SBShape1);
