import React from "react";

import { ReactComponent as EducationIcon } from "../../img/education-icon.svg";

const EducationModal = (props) => {
	const {
		className,
		children,
		eduIconColor,
		title,
		text,
		close,
	} = props;

	return (
		<div className={`education-modal ${className || ""}`}>
			<div className="education-modal__title">
				<div className={`education-icon education-modal__icon ${eduIconColor || ""}`}>
					<EducationIcon/>
					<span>?</span>
				</div>
				<h2 className="title-2">
					{title}
				</h2>
			</div>
			{children}
			<p className="education-modal__p">
				{text}
			</p>
			<button className="btn education-modal__btn" onClick={close}>
				Got it
			</button>
		</div>
	)
};

export default EducationModal;