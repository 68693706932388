import React from "react";

const Radios = (props) => {
	const {
		list,
		className = "",
		checkboxClassName = "",
		name,
		checked,
		onChange,
	} = props;

	return (
		<div className={`radio-list ${className}`}>
			{list.map((radio) => (
				<div className={`checkbox ${checkboxClassName}`} key={radio.id}>
					<input
						readOnly
						style={{ display: "none" }}
						type="radio"
						id={radio.id}
						name={name}
						checked={checked === radio.id}

						onClick={onChange}
					/>
					<label className="text-1" htmlFor={radio.id}>
						<i></i>
						{radio.content &&
							<span>{radio.content}</span>
						}
					</label>
				</div>
			))}
		</div>
	);
}

export default Radios;