export class ErrorHandlingService {

    constructor() {
        this.error = ''
    }

    handleError(err) {
        this.error = err;
        console.error(this.error);

    }

    returnError() {
        return this.error;
    }


}
