import React, { Component } from 'react';
import Modal from "./modal/modal.component";
//import Select from "./select/select.component";
import { CartService } from "./services/cartService";
import { CustomerService } from "./services/customer.service";
import Loader from "./loader.component";
import { Redirect, Prompt } from "react-router-dom";
import { connect } from 'react-redux';
import { setError } from "../redux/user/actions";
import { setCart } from "../redux/diamond/actions"

import { ReactComponent as CongratulationsIcon } from "img/congratulations-1.svg";
import config from "./configurations";
import DropIn from "braintree-web-drop-in-react";


class CardHolder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cardholderName: "",
			cardNumber0: "",
			cardNumber1: "",
			cardNumber2: "",
			cardNumber3: "",
			expirationMonthOption: 0,
			expirationYearOption: 0,
			cvv: "",
			showModal: false,
			payment: false,
			email: '',
			redirect: false,
			order_id: '',
			nonce: "",
			showConfirm: false,
			shouldBlockNavigation: true
		}
	}

	componentDidMount() {
		let guestID = localStorage.getItem('guestCartId');
		let customer = new CustomerService();
		customer.getCustomer()
			.then(res => {
				let cart = new CartService();
				cart = !!res.id ? cart.getCustomerCart() : cart.getGuestCart(guestID);
				cart.then(res => {
					if (res.billing_address && res.billing_address.email) {
						this.setState({
							email: res.billing_address.email,
							emailLoaded: true
						})
					} else {
						this.setState({
							emailLoaded: false
						})
					}
				})
			})
	}

	componentWillUnmount() {
		this.props.setError('');
	}

	renderRedirect = () => {

		if (this.state.redirect) {
			return <Redirect to={{ pathname: '/order-received', state: { order_id: this.state.order_id } }} />
		}


	}

	submitOrder = (e) => {
		e.preventDefault();
		this.setState({ shouldBlockNavigation: false }, () => this.handleSubmitOrder())
	}

	handleSubmitOrder = () => {

		this.setState({ showModal: true })
		let cart = new CartService();
		cart.getCustomerCart()
			.then(res => {
				if (!!res.id) {
					cart.collectTotalsCustomer()
						.then(res => {
							cart.customerCheckout({
								email: this.state.email,
								"paymentMethod": {
									"method": "mpbraintree",
									"additional_data": {
										"payment_method_nonce": this.state.nonce,
										"storeInVaultOnSuccess": 0
									}
								}
							})
								.then(res => {
									if (res.message) {
										this.setState({ showModal: false });
										this.props.setError(res.message);
										return;
									}

									this.props.setCart([]);
									this.setState({
										redirect: true,
										order_id: res
									});
									// this.setState({
									// 	payment: true,
									// 	order_id: res
									// });


									// setTimeout(() => {
									// 	this.props.setCart([]);
									// 	this.setState({redirect: true});
									// }, 3000);
								})
						})
				} else {
					let guestId = localStorage.getItem('guestCartId');
					cart.guestCheckout(guestId, {
						email: this.state.email,
						"paymentMethod": {
							"method": "mpbraintree",
							"additional_data": {
								"payment_method_nonce": this.state.nonce,
								"storeInVaultOnSuccess": 0
							}
						}
					})
						.then(res => {
							if (res.message) {
								this.setState({ showModal: false });
								this.props.setError(res.message);
								return;
							}

							this.props.setCart([]);
							localStorage.removeItem('guestCartId');
							this.setState({
								redirect: true,
								order_id: res
							});
							// this.setState({
							// 	payment: true,
							// 	order_id: res
							// });


							// setTimeout(() => {
							// 	this.props.setCart([]);
							// 	this.setState({redirect: true});
							// }, 3000);
						})
				}
			})
	}

	async authorize() {
		// get nonce
		const { nonce } = await this.instance.requestPaymentMethod();
		this.setState({
			nonce: nonce,
			showConfirm: true
		});
	}

	handleClick(e) {
		const element = e.target;
		if (element.classList.contains("braintree-large-button")) {
			this.setState({
				showConfirm: false
			})
		}
		if (element.classList.contains("braintree-method")) {
			this.setState({
				showConfirm: true
			})
		}
	}

	render() {
		/* delete once payment method is confirmed
		const {
			cardholderName,
			expirationMonthOption,
			expirationYearOption,
			cvv,
		} = this.state;*/

		return (
			<>
				<Prompt
					when={this.state.shouldBlockNavigation}
					message={'Are you sure you want to leave?\nYou might lose your selected diamond'}
				/>
				<div onClick={this.handleClick.bind(this)} style={{ maxWidth: '425px' }}>
					<DropIn
						options={{ authorization: config.braintreeAuthorizationProd }}
						onInstance={instance => (this.instance = instance)}
					/>
					{!this.state.showConfirm &&
						<button className="btn form__btn" id="authorize-button" onClick={this.authorize.bind(this)}>Authorize</button>
					}
				</div>

				{this.renderRedirect()}
				{this.state.showModal && (
					this.state.payment ?
						<section className="success">
							<header className="success__header">
								<div className="success__ico _success">
									<CongratulationsIcon />
								</div>
								<h2 className="success__title">
									Payment successful
								</h2>
							</header>
							<div className="success__ico _ribbon">
								<span className="ico ico-Ribbon"></span>
							</div>
						</section>
						:
						<Modal canClose={this.state.payment} modalClicked={(e) => this.setState({ showModal: false })}>
							<Loader />
						</Modal>
				)}
				{this.state.showConfirm &&
					<div className="form__buttons">
						<button type="submit" className="btn form__btn" onClick={this.submitOrder}>
							Confirm payment
					</button>
					</div>
				}
			</>
			/*
			<form className="form" onSubmit={this.submitOrder}>

				<div className="form__item">
					<div className="form-control form__row">
						<label className="form-control__label" htmlFor="cardholder-name">
							Cardholders name
						</label>
						<input
							type="text"
							className="input-outline form-control__input"
							id="cardholder-name"
							name="cardholder-name"
							value={cardholderName}
							onChange={(e) => this.setState({
								cardholderName: e.target.value
							})}
						/>
					</div>
					<div className="form-control form__row">
						<label className="form-control__label" htmlFor="card-number">
							Card number
						</label>

						<input	type="text"
								className="input-outline form-control__input"
								id={`card-number-0`}
								name={`card-number-0`}
								value={this.state[`cardNumber0`]}
								onChange={(e) => {
									this.setState({
										[`cardNumber0`]: e.target.value.substr(0,16)
									});
								}}
						/>

					</div>
					<div className="form__row">
						<div className="form-control form__item fg-1">
							<label className="form-control__label">
								Expiration Date
							</label>
							<div className="form-control__row _expiration-date">
								<Select
									className="form-control__input _expiration-month"
									name="expirationMonth"
									options={expirationMonth}
									activeIndex={expirationMonthOption}
									onChange={(e) => this.setState({
										expirationMonthOption: expirationMonth.findIndex(option => option.value === e.target.value)
									})}
								/>
								<Select
									className="form-control__input _expiration-year"
									name="expirationYear"
									options={expirationYear}
									activeIndex={expirationYearOption}
									onChange={(e) => this.setState({
										expirationYearOption: expirationYear.findIndex(option => option.value === e.target.value)
									})}
								/>
							</div>
						</div>
					</div>
					<div className="form-control form__row">
						<label className="form-control__label" htmlFor="cvv">
							Cvv
						</label>
						<div className="form-control__row">
							<input
								type="text"
								className="input-outline form-control__input _cvv"
								id="cvv"
								name="cvv"
								value={cvv}
								onChange={(e) => this.setState({
									cvv: e.target.value
								})}
							/>
							<p className="form-control__comment">
								3 or 4 digit code
							</p>
						</div>
					</div>
				</div>

			</form>*/
		);
	}
}

const mapStateToProps = state => ({
	error: state.user.error
});

const mapDispatchToProps = {
	setError,
	setCart
};


export default connect(mapStateToProps, mapDispatchToProps)(CardHolder);
