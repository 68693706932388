import React from "react";
import PropTypes from "prop-types";

const Select = (props) => {
	const {
		className = "",
		placeholderClassName = "",
		name,
		options,
		activeIndex,
		placeholder,
		onChange,
		error
	} = props;

	return (
		<div className={`select ${className} ${error ? "error" : ""}`}>
			<span className={`select__placeholder ${placeholderClassName}`}>
				{(activeIndex !== undefined && options[activeIndex].title) || (placeholder ? placeholder : "Select")}
			</span>
			<select name={name} id={name} onChange={onChange}>
				<option disabled value=""> -- select an option -- </option>
				{options.map((option) => (
					<option value={option.value} key={option.value}>{option.title}</option>
				))}
			</select>
		</div>
	);
}

Select.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	activeIndex: PropTypes.number,
	onChange: PropTypes.func,
};

export default Select;