import React from "react";
import { string, func } from "prop-types";
import styles from "./text-input.module.scss";

export default function TextInput({
  name,
  label,
  className,
  onChange,
  onBlur,
  error,
  value,
  ...props
}) {
  return (
    <div className={`text-input ${className} ${error ? styles.error : ""}`}>
      <label className="form-control__label" htmlFor={name}>
        <span className={onBlur ? styles.required : ""}>{label}</span>
        {error && <span>{` -  ${error}`}</span>}
      </label>
      <input
        type="text"
        className="input-outline form-control__input"
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    </div>
  );
}

TextInput.propTypes = {
  name: string,
  label: string,
  value: string,
  onChange: func,
  className: string,
};
