import React, { useState } from "react";
import { connect } from "react-redux";
import { string, number, func, arrayOf, shape, oneOfType } from "prop-types";
import { useHistory } from "react-router-dom";
import { AddToCartUtil } from "components/utils/addtocart.util";
import { useCart } from "queries/rest/cart";
import { setCart } from "redux/diamond/actions";
import useAffirmMutationObserver from "hooks/useAffirmMutationObserver";

const cartUtil = new AddToCartUtil();

function RingFooter({
  color,
  metal,
  price,
  sizes,
  selectedSize,
  updateURLSize,
  sku,
  cart,
  setCart,
  setSelectRingError,
}) {
  const affirmRef = useAffirmMutationObserver(price);
  const history = useHistory();
  const addRingToCart = useCart(sku);

  let [loading, setLoading] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const handleSizeChange = (e) => {
    const { value } = e.target;
    const size = value === "-1" ? null : value;
    updateURLSize(size);

    if (size) {
      localStorage.setItem("ringSize", size);
      setSizeError(false);
    } else {
      localStorage.removeItem("ringSize");
    }
  };

  const selectRing = async () => {
    if (!selectedSize) {
      setSizeError(true);
    } else {
      setLoading(true);
      const { item_id: itemId, message } = await addRingToCart();
      setLoading(false);

      if (message) {
        setSelectRingError(message);
        setTimeout(() => setSelectRingError(false), 2500);
      }

      if (itemId) {
        setCart([...cart, { item_id: itemId, sku, price }]);
        history.push("/cart");
      }
    }
  };

  return (
    <>
      {/* Mobile Footer */}
      <div className="footer footer-second">
        <div className="portal-children">
          <div className="footer-mobile-container">
            <div className="button-select-size-footer">
              <div
                className={`select _rounded ${sizeError ? "error" : ""}`}
                id="select__mobile-footer"
              >
                <span
                  className={`select__placeholder ${sizeError ? "error" : ""}`}
                >
                  {selectedSize ? `Size: ${selectedSize}` : "Choose ring size"}
                </span>
                <select
                  required
                  name="Choose ring size"
                  defaultValue={selectedSize ?? "-1"}
                  onChange={handleSizeChange}
                >
                  <option value="-1"> -- choose ring size -- </option>
                  {sizes.map((size) => (
                    <option value={size.label} key={size.value_index}>
                      {size.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="button-buy-ring-footer">
              <button
                type="submit"
                value="submit"
                className="btn select-ring-form"
                onClick={selectRing}
                disabled={loading}
              >
                {loading ? "Loading" : "Select ring"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <footer className="product-page__fixed-footer ring-detail--footer">
        <div className="container">
          <div className="product-page__fixed-footer-container">
            <div className="product-page__fixed-footer-content">
              <div className="ring-footer__ring-detail color">
                <p className="ring-footer__ring-information">
                  Color: <span id="selected-color">{color}</span>
                </p>
              </div>
              <div className="ring-footer__ring-detail">
                <p className="ring-footer__ring-information">
                  Metal: <span className="selected-metal">{metal}</span>
                </p>
              </div>
            </div>
            <div className="product-page__fixed-footer-content size">
              <div className={`select _rounded ${sizeError ? "error" : ""}`}>
                <span
                  className={`select__placeholder ${sizeError ? "error" : ""}`}
                >
                  {selectedSize ? `Size: ${selectedSize}` : "Choose ring size"}
                </span>
                <select
                  required
                  name="Choose ring size"
                  defaultValue={selectedSize ?? "-1"}
                  onChange={handleSizeChange}
                >
                  <option value="-1"> -- choose ring size -- </option>
                  {sizes.map((size) => (
                    <option value={size.label} key={size.value_index}>
                      {size.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="product-page__fixed-footer-item">
              <div
                id="affirm-price"
                className="product-page__fixed-footer-price"
              >
                <p
                  ref={affirmRef}
                  className="affirm-as-low-as product-detail-footer-affirm"
                  data-page-type="banner"
                  data-amount={`${price * 100}`}
                  data-learnmore-show="false"
                  data-affirm-type="symbol"
                />
              </div>
              <div className="product-page__fixed-footer-description">
                or{" "}
                <span className="option-price">
                  {cartUtil.priceFormatter(price)}
                </span>
              </div>
            </div>
            <button
              type="submit"
              value="submit"
              className="btn -shadow product__select-btn product-page__fixed-footer-btn"
              onClick={selectRing}
              disabled={loading}
            >
              {loading ? "Loading" : "Select ring"}
              {!loading && (
                <img
                  src={require("img/ring-bag.png").default}
                  className="ring-footer-button__icon"
                  alt="ringImage"
                />
              )}
            </button>
          </div>
        </div>
      </footer>
    </>
  );
}

RingFooter.propTypes = {
  color: string,
  metal: string,
  price: oneOfType([number, string]),
  selectedSize: string,
  updateURLSize: func,
  sku: string,
  cart: arrayOf(shape({})),
  setCart: func,
  sizes: arrayOf(
    shape({
      label: string,
      value_index: number,
    })
  ),
};

const mapStateToProps = (state) => ({
  cart: state.diamond.cart,
});

export default connect(mapStateToProps, { setCart })(RingFooter);
