export const USER_TYPE_CHANGED = 'USER_TYPE_CHANGED';
export const ERROR_CHANGED = 'ERROR_CHANGED';
export const CUSTOMER_ID_CHANGED = 'CUSTOMER_ID_CHANGED';

export const setUserType = type => ({
    type: USER_TYPE_CHANGED,
    payload: type
});

export const setError = error => ({
    type: ERROR_CHANGED,
    payload: error
});
export const setCustomerId = id => ({
    type: CUSTOMER_ID_CHANGED,
    payload: id
});