import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';

export class SBPriceCategory extends React.Component {
    render() {
        return(
            <Link className="by-price_item -inverse-no-hover" 
            to={this.props.url} 
            onClick={()=> this.props.sendQuery(this.props.query)}>
                <div className="by-price_range">
                    {this.props.range}
                </div>
                <div className="by-price_descr">
                    {this.props.description}
                </div>
            </Link>
        )
    }
}

SBPriceCategory.propTypes = {
    clickHandler: PropTypes.func,
  };
export default SBPriceCategory;