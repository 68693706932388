import React, { useEffect, useRef } from "react";
import { string, number, arrayOf, shape } from "prop-types";
import RingAccordion from "components/ring.detail.component/ring.accordion.component";
import { useCustomAttributes, useDiamond } from "queries/graphql";
import { formatDiamondName } from "components/utils/formatDiamondSize";

export default function RingAndDiamondDetails({
  description,
  options,
  metals,
  sizes,
  setting,
  sizeLimit,
  accentStones,
  sku,
  selectedColor,
  diamondShapes: ringDiamondShapes,
}) {
  const videoRef = useRef();
  const sizesLabels = sizes.map((value) => value.label);
  const sizesText = `${sizesLabels[0]} - ${
    sizesLabels[sizesLabels.length - 2]
  }`;

  const diamondSKU = localStorage.getItem("diamondId");
  const { data: diamond } = useDiamond(diamondSKU);
  const { data: customAttributeData } = useCustomAttributes();

  const diamondName = formatDiamondName(diamond?.name);
  const diamondShape = customAttributeData?.diamondShapes.find(
    (shape) => +shape.value === +diamond?.nwg_diamond_shape
  )?.label;
  const diamondColor = customAttributeData?.diamondColors.find(
    (color) => +color.value === +diamond?.nwg_diamond_color
  )?.label;
  const diamondCut = customAttributeData?.diamondCuts.find(
    (cut) => +cut.value === +diamond?.nwg_diamond_cut
  )?.label;
  const diamondClarity = customAttributeData?.diamondClarities.find(
    (clarity) => +clarity.value === +diamond?.nwg_diamond_clarity
  )?.label;
  const diamondSize = diamond?.nwg_diamond_carats;

  const getVideoSource = () => {
    let url;
    try {
      url = require(`img/videos/${sku}/${sku}-${selectedColor}.mp4`).default;
    } catch {
      console.log("360 video not available");
    }
    return url;
  };

  useEffect(() => {
    videoRef.current.load();
  }, [selectedColor]);

  return (
    <div className="ring-accordion-container">
      <div className="ring-accordion-subcontainer">
        <RingAccordion
          title="Rings Details"
          icon={require("img/ring-box.png").default}
          content={`<div>${
            description ?? "There is no description for this ring"
          }</div>`}
        />
        <RingAccordion
          title="Diamond Details"
          className="diamond-details"
          icon={require("img/diamond-shape.png").default}
          content={
            diamondSKU
              ? `
            <table>
              <tr>
                <td>Description</td>
                <td>${diamondName}</td>
              </tr>
              <tr>
                <td>Stock Shape</td>
                <td>${diamondShape}</td>
              </tr>
              <tr>
                <td>Color</td>
                <td>${diamondColor}</td>
              </tr>
              <tr>
                <td>Cut</td>
                <td>${diamondCut}</td>
              </tr>
              <tr>
                <td>Clarity</td>
                <td>${diamondClarity}</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>${diamondSize}</td>
              </tr>
            </table>
          `
              : '<p><a href="/shop-by">Please select a diamond</a><p>'
          }
        />
        <RingAccordion
          title="Features"
          icon={require("img/shiny-ring.png").default}
          content={`
            <table>
              <tr>
                  <td>Ring Style</td>
                  <td id="style">${options}</td>
              </tr>
              <tr>
                  <td>Ring Metal</td>
                  <td>${metals}</td>
              </tr>
              <tr>
                  <td>Ring Size</td>
                  <td>${sizesText}</td>
              </tr>
              <tr>
                  <td>Setting</td>
                  <td>${setting}</td>
              </tr>
              <tr>
                  <td>Accent Stones</td>
                  <td>${accentStones}</td>
              </tr>
              <tr>
                  <td>Center Stone Size Limit</td>
                  <td>${sizeLimit}</td>
              </tr>
              <tr>
                  <td>Diamond Shapes</td>
                  <td>${ringDiamondShapes}</td>
              </tr>
            </table>
        `}
        />
      </div>

      <div className="ring-accordion-img-container">
        <video
          ref={videoRef}
          id="vid"
          width="300"
          height="300"
          loop
          playsInline
          autoPlay
          muted
        >
          {sku && <source src={getVideoSource()} type="video/mp4" />}
        </video>
      </div>
    </div>
  );
}

RingAndDiamondDetails.propTypes = {
  description: string,
  options: string,
  metals: string,
  setting: string,
  sizeLimit: string,
  accentStones: string,
  diamondShapes: string,
  sizes: arrayOf(
    shape({
      label: string,
      value_index: number,
    })
  ),
  sku: string,
  selectedColor: string,
};
