import React from "react";

import EducationContainer from "./education.container.component";

const EducationStep0 = (props) => (
	<EducationContainer
		footer={{
			button: {
				title: "Get Started",
				onClick: () => props.setStep(1),
			},
			step: 0,
		}}
		toggleEducation={props.toggleEducation}
		setStep={props.setStep}
	>
		<div className="education__title">
			<h2 className="title-2">
				Diamond Education
			</h2>
		</div>
		<p className="education__p _subtitle">
			Learn about the 4 Cs.
		</p>
		<div className="education__wrapper">
			<div className="education__bars">
				<div className="level">
					<span/><span/><span/><span/>
				</div>
			</div>
			<p className="education__p">
				Follow the green bars.
			</p>
		</div>
	</EducationContainer>
);

export default EducationStep0;
