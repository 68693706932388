export function formatDiamondSize(size) {
  const diamondSizeString = size.toString();

  const diamondSize =
    diamondSizeString.length <= 3 || size % 1 === 0
      ? size
      : diamondSizeString % 25 === 0
      ? size
      : diamondSizeString.slice(0, diamondSizeString.length - 1);

  return +diamondSize;
}

export function formatDiamondName(name) {
  const size = name?.match(/\d+.\d+/g)?.[0];
  return size ? name.replace(size, formatDiamondSize(size)) : name;
}
