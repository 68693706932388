import React, {Component} from 'react';
import {Link, Prompt} from 'react-router-dom';
import {DiamondSearch} from "./services/diamondsearch";
import {SBIMGSlider} from "./shop.by.component/productDetails/imgSlider.component";
import {CartService} from "./services/cartService";
import {CustomerService} from "./services/customer.service";
import {connect} from 'react-redux';
import {setDiamondId, setCart} from "../redux/diamond/actions";
import {setError} from "../redux/user/actions";
import {AddToCartUtil} from "./utils/addtocart.util"
import Hashids from 'hashids'

/*const productLabels = [
	{title: "Round",},
	{title: "Your pick", className: "-green",},
	{title: "Great choice!", className: "-rose",},
];*/

class OrderReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.cart || [],
            diamond: {},
            total: 0,
            productLabels: [],
            tax_total: 0,
            grand_total: 0,
            discount: 0,
            shouldBlockNavigation: false
        }

        this.util = new AddToCartUtil();
        this.diamondSvc = new DiamondSearch();
    }
    componentDidMount() {
        
        let guestID = localStorage.getItem('guestCartId');
        let customer = new CustomerService();
        customer.getCustomer()
        .then( res => {
            let cart = new CartService();
            let cartCall = !!res.id ? cart.getCustomerCart() : cart.getGuestCart(guestID);
            
            cartCall.then( res => {
                if (!res.items) this.props.setError('Order was not placed',res.message);
                let sum=0; res.items.map(el => sum += el.price);
                let diamondsCall = [];
                res.items.forEach( el => {
                    if (el.sku.indexOf('ring') <0)diamondsCall.push(this.diamondSvc.getDiamondById(el.sku))
                });
                Promise.all(diamondsCall)
                .then( diamonds => {
                    res.items.forEach(el => diamonds.forEach(diamond => {
                        if (diamond.diamond.diamond_id + '' === el.sku + '') el.name = diamond.diamond.size + ' Ct, ' + diamond.diamond.shape + ' Diamond, ' + diamond.diamond.color +', ' + diamond.diamond.clarity;
                    }));

                    res.items.forEach(el => {
                        if (el.sku.indexOf('ring')>-1) el.name = el.name.replace(/-/g,' ') + ' size';
                    })
                    
                     this.setState({
                        items: res.items,
                        total: sum,
                        tax_total: this.props.tax_total || localStorage.getItem('joolezTaxValue'),
                        grand_total: this.props.grand_total || localStorage.getItem('joolezGrandTotal'),
                        discount: this.props.discount || localStorage.getItem('joolezDiscount'),
                    });

                    
                    
                    this.props.setCart(res.items);
                    new DiamondSearch().getDiamondById(res.items[0].sku)
                    .then( res => {
                        if (!! res.diamond)this.prepareDiamond(res.diamond);
                    });
                })
               
            })
        })

    }

    componentWillUnmount() {
        this.props.setError('');
    }

    prepareDiamond = (diamond) => {
        diamond.image_file_url = diamond && diamond.image_file_url? 'http:'+diamond.image_file_url :  require('./pics/diamond-1.png').default;
        diamond.productLabels=[
            {title: diamond.shape},
            {title: "Your pick", className: "-green",},
            {title: "Great choice!", className: "-rose",}
        ];
        this.setState({diamond: diamond, productLabels: diamond.productLabels});
    }

    diamondRedirect = (sku) => {
        const hashids = new Hashids();
        let encoded = hashids.encode(sku);
        this.setState({shouldBlockNavigation: false}, () => {
            this.props.setDiamondId(sku);
		    this.props.history.push('/product-details/'+encoded);
        })
		
	}

    render() {

        let items = this.state.items.map((item) => {
            return (
            <div className="order-review__table-tr" key={item.item_id}>
                <div className="order-review__table-td _expand">
                    <p>Sub total</p>
                    <p>1X {item.name}</p>
                </div>
                <div className="fw-600 order-review__table-td _c-green">
                    ${this.util.priceFormatter(item.price)}
                </div>
                
            </div>
        )
        })
        return (
            <div className="order-review">
                <Prompt
					when={this.state.shouldBlockNavigation}
					message={'Are you sure you want to leave?\nYou might lose your selected diamond'}
					/>
                <SBIMGSlider imgs={[(this.state.diamond && this.state.diamond.image_file_url) || '']} 
                labels={this.state.productLabels}
                noSlider={true}/>
                <div className="text-1 fw-300 order-review__table">
                    {items}
                    <div className="order-review__table-tr">
                        <div className="order-review__table-td _expand">
                            Tax Amount
                        </div>
                        <div className="fw-600 order-review__table-td _c-green">
                             ${this.util.priceFormatter(this.state.tax_total)}
                        </div>
                    </div>
                    {this.state.discount !== 0 && <div className="order-review__table-tr">
                        <div className="order-review__table-td _expand">
                            Discount Amount
                        </div>
                        <div className="fw-600 order-review__table-td _c-green">
                             -${this.util.priceFormatter(this.state.discount).replace('-','')}
                        </div>
                    </div>}
                    <div className="order-review__table-tr">
                        <div className="order-review__table-td _expand">
                            Grand Total
                        </div>
                        <div className="fw-600 order-review__table-td _c-green">
                             ${this.util.priceFormatter(this.state.grand_total)}
                        </div>
                    </div>
                </div>
                <div className="container__buttons">
                    <Link to="/cardholder" className="btn-container">
                        <button className="btn">Continue</button>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    diamond_id: state.diamond.diamond_id,
    cart: state.diamond.cart,
    user_type: state.user.user_type,
    grand_total: state.diamond.grand_total,
    tax_total: state.diamond.tax_total,
    discount: state.diamond.discount
});

const mapDispatchToProps ={
    setDiamondId,
    setCart,
    setError
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReview);
