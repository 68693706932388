export const DIAMONDS_FOR_COMPARE_CHANGED = 'DIAMONDS_FOR_COMPARE_CHANGED';
export const DIAMOND_ID_CHANGED = 'DIAMOND_ID_CHANGED';
export const CART_CHANGED = 'CART_CHANGED';
export const SHOP_BY_QUERY_CHANGED = 'SHOP_BY_QUERY_CHANGED';
export const SHOP_BY_STATE_CHANGED = 'SHOP_BY_STATE_CHANGED';
export const SHOP_BY_ACTION_CHANGED = 'SHOP_BY_ACTION_CHANGED';
export const DIAMOND_PURCHASED = 'DIAMOND_PURCHASED';
export const GRAND_TOTAL_CHANGED = 'GRAND_TOTAL_CHANGED';
export const TAX_TOTAL_CHANGED = 'TAX_TOTAL_CHANGED';
export const DISCOUNT_CHANGED = 'DISCOUNT_CHANGED';

export const setCompareDiamonds = diamonds => ({
    type: DIAMONDS_FOR_COMPARE_CHANGED,
    payload: diamonds
});

export const setDiamondId = id => ({
    type: DIAMOND_ID_CHANGED,
    payload: id
});

export const setCart = cartItems => ({
    type: CART_CHANGED,
    payload: cartItems
});


export const setShopByQuery = query => {
    localStorage.setItem('joolezDiamondQuery',JSON.stringify(query));
    return {
    type: SHOP_BY_QUERY_CHANGED,
    payload: query
    }};

export const setShopByState = state => {
    localStorage.setItem('joolezDiamondSBState', JSON.stringify(state));
    return {
        type: SHOP_BY_STATE_CHANGED,
        payload: state
    }
};

export const setShopByAction = action => {
    localStorage.setItem('joolezDiamondAction',action);
    return {
        type: SHOP_BY_ACTION_CHANGED,
        payload: action
    }
};

export const setDiamondPurchased = purchased => ({
    type: DIAMOND_PURCHASED,
    payload: purchased
});

export const setGrandTotalChanged = total => ({
    type: GRAND_TOTAL_CHANGED,
    payload: total
});
export const setTaxChanged = tax => ({
    type: TAX_TOTAL_CHANGED,
    payload: tax
});

export const setDiscount = discount => ({
    type: DISCOUNT_CHANGED,
    payload: discount
});
