import React,{Component} from "react";

import EducationStep0 from "./education.step0.component";
import EducationStep1 from "./education.step1.component";
import EducationStep2 from "./education.step2.component";
import EducationStep3 from "./education.step3.component";
import EducationStep4 from "./education.step4.component";

class Education extends Component {
	constructor(props) {
		super();
		this.toggleEducation = props.toggleEducation;
		this.state = {
			step: undefined,
		}
	}

	setStep = (step) => {
		this.setState({step: step});
	}

	renderStep = (step) => {
		switch(step) {
			case 0:
				return <EducationStep0 toggleEducation={this.toggleEducation} setStep={this.setStep}/>;
			case 1:
				return <EducationStep1 toggleEducation={this.toggleEducation} setStep={this.setStep}/>;
			case 2:
				return <EducationStep2 toggleEducation={this.toggleEducation} setStep={this.setStep}/>;
			case 3:
				return <EducationStep3 toggleEducation={this.toggleEducation} setStep={this.setStep}/>;
			case 4:
				return <EducationStep4 toggleEducation={this.toggleEducation} setStep={this.setStep}/>;
			default:
				return <EducationStep0 toggleEducation={this.toggleEducation} setStep={this.setStep}/>;
		}
	}

	render() {
		const {
			step,
		} = this.state;

		return this.renderStep(step);
	}
}

export default Education;