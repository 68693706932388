import {
    DIAMONDS_FOR_COMPARE_CHANGED,
    DIAMOND_ID_CHANGED,
    CART_CHANGED,
    SHOP_BY_QUERY_CHANGED,
    SHOP_BY_STATE_CHANGED,
    SHOP_BY_ACTION_CHANGED,
    DIAMOND_PURCHASED,
    GRAND_TOTAL_CHANGED,
    TAX_TOTAL_CHANGED,
    DISCOUNT_CHANGED
} from "./actions";

const defaultState = {
    selected_diamonds: [],
    diamond_id: '',
    cart: [],
    query: !!localStorage.getItem('joolezDiamondQuery') ? JSON.parse(localStorage.getItem('joolezDiamondQuery')) : {
        shapes: ['Round'],
        price_total_from: 1000,
        price_total_to: 5000
    },
    sb_state: !!localStorage.getItem('joolezDiamondSBState') ? JSON.parse(localStorage.getItem('joolezDiamondSBState')):{},
    sb_action: !!localStorage.getItem('joolezDiamondAction') ? localStorage.getItem('joolezDiamondAction') : '',
    diamond_purchased: false,
    grand_total: 0,
    tax_total: 0,
    discount: 0,
}

export const DiamondReducer = (state = defaultState, action) => {
    switch (action.type) {
        case DIAMONDS_FOR_COMPARE_CHANGED:
            return {
                ...state,
                selected_diamonds: action.payload
            };
        case DIAMOND_ID_CHANGED:
            return {
                ...state,
                diamond_id: action.payload
            };
        case CART_CHANGED:
            return {
                ...state,
                cart: action.payload
            };
        case SHOP_BY_QUERY_CHANGED:
            return {
                ...state,
                query: action.payload
            };
        case SHOP_BY_STATE_CHANGED:
            return {
                    ...state,
                    sb_state: action.payload
                };
        case SHOP_BY_ACTION_CHANGED:
                    return {
                            ...state,
                            sb_action: action.payload
                        };
        case DIAMOND_PURCHASED:
                    return {
                            ...state,
                            diamond_purchased: action.payload
                    };
        case GRAND_TOTAL_CHANGED:
                        return {
                                ...state,
                                grand_total: action.payload
                        };
        case TAX_TOTAL_CHANGED:
                    return {
                            ...state,
                            tax_total: action.payload
                    };
        case DISCOUNT_CHANGED: 
                    return {
                        ...state,
                        discount: action.payload
                    }
        default: // do nothing              
    }
    return state;
}
