import React from "react";

const ProductDescriptionIncluded = props => {
	const { className } = props;
	const	openChat = () => window.FB.CustomerChat.show();

	return (
		<section className={`info-block product-description__included ${className || ""}`}>
			<div className="content info-block_content product-description__included-content">
				<header className="info-block_header product-description__included-header">
					<h3 className="info-block_title product-description__included-title">
						<a href="#" onClick={openChat}>Speak to an expert</a>
					</h3>
				</header>
				<ul className="product-description__included-list">
					<li className="offer">
						<a href="#" onClick={openChat}><b>Make an offer</b></a> 
					</li>
					<li>
						<span className="uppercase bold color-carat product-description__included-item">FREE</span> shipping
					</li>
					<li className="chat"><a href="#" onClick={openChat}><b>Need help?</b> Chat with us</a></li>
					<li>
						<span className="uppercase bold color-carat product-description__included-item">FREE</span> 14 day returns
					</li>
				</ul>
			</div>

			<div className="product-description__included-image">
				<img src={require("img/product-page-included.png").default} alt="product-page-included"/>
			</div>
		</section>
	);
}

export default ProductDescriptionIncluded;


					
