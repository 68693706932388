import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCacheDehydration } from "hooks";
import RingDiamondSelected from "./RingDiamondSelected";
import RingCard from "components/ring.detail.component/ring.card.component";
import RingColorsAvailable from "components/ring.detail.component/ring.colorsAvailable.component";
import RingOptionSelector from "components/ring.detail.component/RingOptionSelector";
import Arrow from "components/slider/slider-simple-arrow.component";
import Loader from "../loader.component";

import { useRings, useCustomAttributes } from "queries/graphql";
import useQuerySearchParam from "hooks/useQuerySearchParam";

export default function RingDetail() {
  useCacheDehydration();
  const history = useHistory();
  const [selectedOption, updateURLOption] = useQuerySearchParam("option");
  const [selectedPage, updateURLPage] = useQuerySearchParam("page");

  const { data: customAttributesData } = useCustomAttributes();
  const ringOptions = customAttributesData?.ringOptions ?? [];
  const diamondShapes = customAttributesData?.diamondShapes ?? [];
  const currentPage = selectedPage ? +selectedPage : 1;

  const selectedOptionValue =
    ringOptions?.find((option) => option.label.toLowerCase() === selectedOption)
      ?.value ?? "all";

  const { data: ringsData, isLoading } = useRings({
    filter: selectedOptionValue,
    page: currentPage,
  });
  const rings = ringsData?.rings ?? [];
  const totalPages = ringsData?.totalPages;

  const handleRingOptionSelect = (e) => {
    const { id } = e.target;
    const selectedOptionLabel =
      ringOptions.find((option) => option.value === id)?.label ?? "all";
    updateURLOption(selectedOptionLabel.toLowerCase());
  };

  const prevPageClick = () => updateURLPage(currentPage - 1);
  const nextPageClick = () => updateURLPage(currentPage + 1);

  const ringClicked = (ring) => () => {
    const urlKey = ring.url_key;
    const color = ring.configurable_options
      .find((option) => option.attribute_code === "ring_color")
      ?.values[0]?.label?.replace(" ", "-")
      .toLowerCase();
    const metal = ring.configurable_options
      .find((option) => option.attribute_code === "ring_metal")
      ?.values[0]?.label.toLowerCase();

    const size = localStorage.getItem("ringSize");
    const defaultSearch = `?color=${color}&metal=${metal}`;
    const search = size ? `${defaultSearch}&size=${size}` : defaultSearch;

    history.push({
      pathname: `/ring-builder/${urlKey}`,
      search,
      state: { ringsPage: selectedPage },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <RingDiamondSelected diamondShapes={diamondShapes} />
      <div className="product-list">
        <section className="goods">
          <header className="goods-header" id="headers-options-rings">
            <div className="goods-description">
              Quality first. Honest prices & complete services
            </div>
          </header>

          <RingOptionSelector
            options={ringOptions}
            select={handleRingOptionSelect}
            selectedOption={selectedOptionValue}
          />

          {isLoading && (
            <div className="loader-container">
              <Loader />
            </div>
          )}

          {totalPages > 0 && (
            <div className="page-info">{`Showing page ${currentPage} of ${totalPages}`}</div>
          )}

          {!isLoading && rings?.length === 0 && selectedOption && (
            <div className="empty-message">
              <div>{`There are no ${selectedOption} rings in stock right now.`}</div>
            </div>
          )}

          {rings?.length > 0 && (
            <>
              <div className="goods-list rings-list">
                {rings.map((ring) => {
                  const optionLabel =
                    ringOptions?.find(
                      (option) => +option.value === +ring.ring_options
                    )?.label ?? "N/A";
                  const colors =
                    ring.configurable_options?.find(
                      (option) => option.attribute_code === "ring_color"
                    )?.values ?? [];

                  return (
                    <RingCard
                      key={ring.id}
                      allowLike
                      label={optionLabel}
                      colors={colors}
                      ringClicked={ringClicked(ring)}
                      price={
                        ring.price_range?.minimum_price?.regular_price?.value
                      }
                      name={ring.name}
                      img={ring.thumbnail.url}
                    />
                  );
                })}
              </div>
              <div className="info-container-ring">
                <div className="info-colors-ring">
                  <RingColorsAvailable />
                </div>
                <div className="info-text-ring">
                  All rings are available in white, yellow and rose gold. Choose
                  14K, 18K or Platinum
                </div>
                {totalPages > 1 && (
                  <div className="pagination-controls">
                    <Arrow
                      direction="left"
                      onClick={prevPageClick}
                      disabled={currentPage === 1}
                    />
                    <div>{`Page ${currentPage} of ${totalPages}`}</div>
                    <Arrow
                      direction="right"
                      onClick={nextPageClick}
                      disabled={currentPage === totalPages}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
}
