import React from "react";
import {config} from "./configurations";

import {ReactComponent as ComparisonIcon} from "img/comparison/compare-diamonds.svg";
import ProductEducationModal from "./shop.by.component/productDetails/productDetailItems/productEducationModal.component";

export class ComparedDiamond extends React.Component {
	constructor(props) {
        super(props);
        this.iconMapping = config.goodsQualityIcons;
				this.state = {
					educationModal: undefined
				};
    }

    addToCart = ()=> {
        this.props.addToCart(this.props.el.diamond_id);
    }

		showEducationModal = (data) => {
			this.setState({ educationModal: data.toLowerCase() });
		}

		handleEducationModal = () => {
			this.setState({ educationModal: undefined });
		}

    render() {
	    const {
	        el
        } = this.props;
				const {
					educationModal
				} = this.state;

        let details = this.props.el.details.map((elDetails, i) => {
            let img = {};

            switch(true) {
				case /cut/i.test(elDetails.title):
				    img.type = "cut";
				    img.ext = "png";
				    img.x3 = true;
				    img.className = `_${img.type}`;
                    img.level = el.calculated_quality[img.type] <= 5 ? el.calculated_quality[img.type] : 5;
                    img.level = img.level === 0 ? 1 : img.level;
				    break;
				case /color/i.test(elDetails.title):
				    img.type = "color";
					img.ext = "jpg";
					img.x3 = false;
					img.className = `_${img.type}`;
                    img.level = el.calculated_quality[img.type] <= 5 ? el.calculated_quality[img.type] : 5;
                    img.level = img.level === 0 ? 1 : img.level;
				    break;
				case /clarity/i.test(elDetails.title):
				    img.type = "clarity";
					img.ext = "png";
					img.x3 = true;
					img.className = `_${img.type}`;
                    img.level = el.calculated_quality[img.type] <= 5 ? el.calculated_quality[img.type] : 5;
                    img.level = img.level === 0 ? 1 : img.level;
				    break;
				case /carat/i.test(elDetails.title):
				    img.type = "carat";
					img.ext = "jpg";
					img.x3 = true;
					img.className = `_${img.type}`;
                    img.level = el.calculated_quality[img.type] <= 4 ? el.calculated_quality[img.type] : 4;
                    img.level = img.level === 0 ? 1 : img.level;
				    break;
                default:
					img = undefined;
            }

            return (
                <div className="comparison__param comparison__padding" key={`${el.diamond_id}-${img.type}`}>
                    {img !== undefined && <div className="comparison__param-image">
                        <img
                            className={img.className}
                            src={require(`img/education/diamond-${img.type}-${img.level}.${img.ext}`).default}
                            srcSet={img.x3 ? `${require(`img/education/diamond-${img.type}-${img.level}@2x.${img.ext}`).default} 2x, ${require(`img/education/diamond-${img.type}-${img.level}@3x.${img.ext}`).default} 3x` : undefined}
                            alt={`${img.type}: ${el.calculated_quality[img.type]}`}
                        />
                    </div>}
                    <div className="product-details_icons comparison__param-inner">
                        {/carat/i.test(elDetails.title) ?
                            <span className="comparison__param-title">Size</span>
                            :
                            <span className={`${elDetails.chargeIcon} comparison__level animate${i+1}`}>
                                <span/><span/><span/><span/>
                            </span>
                        }
                        <span className={elDetails.iconClass1} onClick={() => this.showEducationModal(elDetails.title)}></span>
                        <span className="product-details_descr-title comparison__level-title">
                            {elDetails.title}: <span className="c-secondary">{elDetails.text}</span>
                        </span>
                    </div>
                </div>
            );
		});

        return (
            <div className={`good ${this.props.className || ""}`} key={"div"+this.props.el.diamond_id}>
                <div className="comparison__header comparison__padding">
                    <div className="good-inner -xs">
                        <div className="good-inner_header">
                            <div className="comparison__shape">
                                <div className="comparison__note">
																{this.props.accent}
                                </div>
                                <div className="label good-label">
									{this.props.el.shape}
                                </div>
                            </div>
                            <button className="comparison__gia-btn">
                                <img src={require(`img/gia.png`).default} alt=""/>
                            </button>
                        </div>
                        <div className="good-pic">
                            <img src={this.props.el.image_file_url || require('../pics/diamond-1.png').default} alt="" className="good-img"/>
                        </div>
						{this.props.index === 0 &&
                            <div className="comparison__icon">
                                <ComparisonIcon/>
                            </div>
						}
                    </div>
                    <div className="good-price comparison__price">
						{this.props.el.currency_symbol}{this.props.el.total_sales_price_in_currency}
                    </div>
                </div>
                <div className="comparison__params">
					{details}
                </div>
								{/* hiding based on jr-187
								<div className="comparison__quality">
                    <div className={`comparison__quality-level ${this.iconMapping[this.props.el.calculated_quality.overall]}`}>
                        <span/><span/><span/><span/>
                    </div>
                    <div className="comparison__quality-title">
                        Overall Quality
                    </div>
                </div>
								*/}
                <div className="comparison__cheaper">
					{/* DEMO hiding based on jr-187
					{this.props.index === 0 ?
						<React.Fragment>
                            <span>48%</span> Cheaper than Zales, Blue Nile, Rocher
                        </React.Fragment>
						:
                        <React.Fragment>
                            <span>22%</span> Cheaper than Zales, Blue Nile, Rocher
                        </React.Fragment>
					}
					 end of DEMO */}
                </div>
                <div className="comparison__btn">
                    <button className="btn -xs" onClick={this.addToCart}>
                        Select Diamond
                    </button>
                </div>
								<ProductEducationModal
									educationModal={educationModal}
									handleModalClick={this.handleEducationModal}
									details={el.details}
								/>
            </div>
        )
    }

}
export default ComparedDiamond;
