import React from "react";

export class Overlay extends React.Component {
    closeOverlay = (event) => {
        this.props.hideOverlay(false);
    }

    render() {
        const {
            className,
        } = this.props;

        let style = this.props.overlayShown ? {} : {display: 'none'};

        return(
            <div
                className={`overlay ${className}`}
                style={style}
                ref={this.setWrapperRef}
                onClick={this.closeOverlay}
            ></div>
        )
    }
}
export default Overlay;