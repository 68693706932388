export const config = {
  baseurl: " https://productapi.joolez.com/",
  username: "hhtcfmgernzbpnszhgjezapnwcxcna",
  password: "rEvVJzTI",
  // magentoURL: process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development' ? "https://stgapi.joolez.com/rest" : "https://api.joolez.com/rest", ORIGINAL URL'S
  magentoURLProd:
      process.env.NODE_ENV === "development" ||
      process.env.REACT_APP_ENV === "development"
          ? "https://api.joolez.com/rest"
          : "https://api.joolez.com/rest",
  magentoURL:
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENV === "development"
      ? "https://api.joolez.com/rest"
      : "https://api.joolez.com/rest",
  magentoGraphqlURL:
        process.env.NODE_ENV === "development" ||
        process.env.REACT_APP_ENV === "development"
            ? "https://api.joolez.com/graphql"
            : "https://api.joolez.com/graphql",
  headerstorage: "JOOL_AUTH_HEADER",
  braintreeAuthorizationSandbox: "sandbox_rzs2z9zz_4zhw3hxmv6h3x4f9",
  braintreeAuthorizationProd: "production_7bytrgb6_jywdrm8kt8996ptb",
  affirmLimit: 17500,
  ringsPageSize: 12,
  goodsQualityIcons: {
    0: "level",
    1: "level _1",
    2: "level _2",
    3: "level _3",
    4: "level _4",
    5: "level _4 _5",
  },
  countryOptions: [{ value: "US", title: "United States" }],
  stateOptions: [
    { title: "Select", value: "0" },
    { title: "AL", value: "AL" },
    { title: "AK", value: "AK" },
    { title: "AZ", value: "AZ" },
    { title: "AR", value: "AR" },
    { title: "CA", value: "CA" },
    { title: "CO", value: "CO" },
    { title: "CT", value: "CT" },
    { title: "DE", value: "DE" },
    { title: "FL", value: "FL" },
    { title: "GA", value: "GA" },
    { title: "HI", value: "HI" },
    { title: "ID", value: "ID" },
    { title: "IL", value: "IL" },
    { title: "IN", value: "IN" },
    { title: "IA", value: "IA" },
    { title: "KS", value: "KS" },
    { title: "KY", value: "KY" },
    { title: "LA", value: "LA" },
    { title: "ME", value: "ME" },
    { title: "MD", value: "MD" },
    { title: "MA", value: "MA" },
    { title: "MI", value: "MI" },
    { title: "MN", value: "MN" },
    { title: "MS", value: "MS" },
    { title: "MO", value: "MO" },
    { title: "MT", value: "MT" },
    { title: "NE", value: "NE" },
    { title: "NV", value: "NV" },
    { title: "NH", value: "NH" },
    { title: "NJ", value: "NJ" },
    { title: "NM", value: "NM" },
    { title: "NY", value: "NY" },
    { title: "NC", value: "NC" },
    { title: "ND", value: "ND" },
    { title: "OH", value: "OH" },
    { title: "OK", value: "OK" },
    { title: "OR", value: "OR" },
    { title: "PA", value: "PA" },
    { title: "RI", value: "RI" },
    { title: "SC", value: "SC" },
    { title: "SD", value: "SD" },
    { title: "TN", value: "TN" },
    { title: "TX", value: "TX" },
    { title: "UT", value: "UT" },
    { title: "VT", value: "VT" },
    { title: "VA", value: "VA" },
    { title: "WA", value: "WA" },
    { title: "WV", value: "WV" },
    { title: "WI", value: "WI" },
    { title: "WY", value: "WY" },
  ],
  stateMap: {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  stateCodeMap: {
    AL: "1",
    AK: "2",
    AS: "3",
    AZ: "4",
    AR: "5",
    AE: "10",
    AA: "7",
    AP: "11",
    CA: "12",
    CO: "13",
    CT: "14",
    DE: "15",
    DC: "16",
    FM: "17",
    FL: "18",
    GA: "19",
    GU: "20",
    HI: "21",
    ID: "22",
    IL: "23",
    IN: "24",
    IA: "25",
    KS: "26",
    KY: "27",
    LA: "28",
    ME: "29",
    MH: "30",
    MD: "31",
    MA: "32",
    MI: "33",
    MN: "34",
    MS: "35",
    MO: "36",
    MT: "37",
    NE: "38",
    NV: "39",
    NH: "40",
    NJ: "41",
    NM: "42",
    NY: "43",
    NC: "44",
    ND: "45",
    MP: "46",
    OH: "47",
    OK: "48",
    OR: "49",
    PW: "50",
    PA: "51",
    PR: "52",
    RI: "53",
    SC: "54",
    SD: "55",
    TN: "56",
    TX: "57",
    UT: "58",
    VT: "59",
    VI: "60",
    VA: "61",
    WA: "62",
    WV: "63",
    WI: "64",
    WY: "65",
  },

  SHAPE_SELECTOR: [
    {
      key: "Asscher",
      img: require("../img/shapes/Ascher.png").default,
      srcSet: [
        require("../img/shapes/Ascher@1x.png").default,
        require("../img/shapes/Ascher@2x.png").default,
        require("../img/shapes/Ascher@3x.png").default,
      ],
      alt: "Shape: Asscher",
      trend: 82.06,
    },
    {
      key: "Cushion",
      img: require("../img/shapes/Cushion.png").default,
      srcSet: [
        require("../img/shapes/Cushion@1x.png").default,
        require("../img/shapes/Cushion@2x.png").default,
        require("../img/shapes/Cushion@3x.png").default,
      ],
      alt: "Shape: Cushion",
      trend: 92.3,
    },
    {
      key: "Emerald",
      img: require("../img/shapes/Emerald.png").default,
      srcSet: [
        require("../img/shapes/Emerald@1x.png").default,
        require("../img/shapes/Emerald@2x.png").default,
        require("../img/shapes/Emerald@3x.png").default,
      ],
      alt: "Shape: Emerald",
      trend: 75.21,
    },
    {
      key: "Heart",
      img: require("../img/shapes/Heart.png").default,
      srcSet: [
        require("../img/shapes/Heart@1x.png").default,
        require("../img/shapes/Heart@2x.png").default,
        require("../img/shapes/Heart@3x.png").default,
      ],
      alt: "Shape: Heart",
      trend: 89.38,
    },
    {
      key: "Marquise",
      img: require("../img/shapes/Marquise.png").default,
      srcSet: [
        require("../img/shapes/Marquise@1x.png").default,
        require("../img/shapes/Marquise@2x.png").default,
        require("../img/shapes/Marquise@3x.png").default,
      ],
      alt: "Shape: Marquise",
      trend: 89.38,
    },
    {
      key: "Oval",
      img: require("../img/shapes/Oval.png").default,
      srcSet: [
        require("../img/shapes/Oval@1x.png").default,
        require("../img/shapes/Oval@2x.png").default,
        require("../img/shapes/Oval@3x.png").default,
      ],
      alt: "Shape: Oval",
      trend: 89.38,
    },
    {
      key: "Pear",
      img: require("../img/shapes/Pear.png").default,
      srcSet: [
        require("../img/shapes/Pear@1x.png").default,
        require("../img/shapes/Pear@2x.png").default,
        require("../img/shapes/Pear@3x.png").default,
      ],
      alt: "Shape: Pear",
      trend: 89.38,
    },
    {
      key: "Princess",
      img: require("../img/shapes/Princess.png").default,
      srcSet: [
        require("../img/shapes/Princess@1x.png").default,
        require("../img/shapes/Princess@2x.png").default,
        require("../img/shapes/Princess@3x.png").default,
      ],
      alt: "Shape: Princess",
      trend: 89.38,
    },
    {
      key: "Radiant",
      img: require("../img/shapes/Radiant.png").default,
      srcSet: [
        require("../img/shapes/Radiant@1x.png").default,
        require("../img/shapes/Radiant@2x.png").default,
        require("../img/shapes/Radiant@3x.png").default,
      ],
      alt: "Shape: Radiant",
      trend: 89.38,
    },
    {
      key: "Round",
      img: require("../img/shapes/Round.png").default,
      srcSet: [
        require("../img/shapes/Round@1x.png").default,
        require("../img/shapes/Round@2x.png").default,
        require("../img/shapes/Round@3x.png").default,
      ],
      alt: "Shape: Round",
      trend: 89.38,
    },
  ],
  shapes: [
    "Asscher",
    "Cushion",
    "Emerald",
    "Heart",
    "Marquise",
    "Oval",
    "Pear",
    "Princess",
    "Radiant",
    "Round",
  ],
  cut: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
  color: ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"],
  clarity: [
    "IF",
    "VVS1",
    "VVS2",
    "VS1",
    "VS2",
    "SI1",
    "SI2",
    "SI3",
    "I1",
    "I2",
    "I3",
  ],
  grades: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
  letters: ["VUTS", "RQPO", "NMLK", "JIHG", "FED"],
  diamondImages: {
    asscher: require("img/shapes/Asscher_product.png").default,
    cushion: require("img/shapes/Cushion_product.png").default,
    emerald: require("img/shapes/Emerald_product.png").default,
    heart: require("img/shapes/Heart_product.png").default,
    marquise: require("img/shapes/Marquise_product.png").default,
    oval: require("img/shapes/Oval_product.png").default,
    pear: require("img/shapes/Pear_product.png").default,
    princess: require("img/shapes/Princess_product.png").default,
    radiant: require("img/shapes/Radiant_product.png").default,
    round: require("img/shapes/Round_product.png").default,
  },
  defaultShippingMethod: "freeshipping",
  // facebookPageId: '1492341784416921',
  // facebookAppId: '489911488535711',
  facebookPageId: "1492341784416921",
  facebookAppId: "228218275147478",
  mailChimpURL:
    "https://joolez.us13.list-manage.com/subscribe/post?u=4b5a681132f1e58c6e16dfc88&amp;id=21592129b7",
};

export default config;
/*
stateOptions: [
        {"title":"AL","value":"AL"},
    {"title":"AK","value":"AK"},
    {"title":"AZ","value":"AZ"},
    {"title":"AR","value":"AR"},
    {"title":"CA","value":"CA"},
    {"title":"CO","value":"CO"},
    {"title":"CT","value":"CT"},
    {"title":"DE","value":"DE"},
    {"title":"FL","value":"FL"},
    {"title":"GA","value":"GA"},
    {"title":"HI","value":"HI"},
    {"title":"ID","value":"ID"},
    {"title":"IL","value":"IL"},
    {"title":"IN","value":"IN"},
    {"title":"IA","value":"IA"},
    {"title":"KS","value":"KS"},
    {"title":"KY","value":"KY"},
    {"title":"LA","value":"LA"},
    {"title":"ME","value":"ME"},
    {"title":"MD","value":"MD"},
    {"title":"MA","value":"MA"},
    {"title":"MI","value":"MI"},
    {"title":"MN","value":"MN"},
    {"title":"MS","value":"MS"},
    {"title":"MO","value":"MO"},
    {"title":"MT","value":"MT"},
    {"title":"NE","value":"NE"},
    {"title":"NV","value":"NV"},
    {"title":"NH","value":"NH"},
    {"title":"NJ","value":"NJ"},
    {"title":"NM","value":"NM"},
    {"title":"NY","value":"NY"},
    {"title":"NC","value":"NC"},
    {"title":"ND","value":"ND"},
    {"title":"OH","value":"OH"},
    {"title":"OK","value":"OK"},
    {"title":"OR","value":"OR"},
    {"title":"PA","value":"PA"},
    {"title":"RI","value":"RI"},
    {"title":"SC","value":"SC"},
    {"title":"SD","value":"SD"},
    {"title":"TN","value":"TN"},
    {"title":"TX","value":"TX"},
    {"title":"UT","value":"UT"},
    {"title":"VT","value":"VT"},
    {"title":"VA","value":"VA"},
    {"title":"WA","value":"WA"},
    {"title":"WV","value":"WV"},
    {"title":"WI","value":"WI"},
    {"title":"WY","value":"WY"}
    ],
    stateMap: {"AL":"Alabama",
    "AK":"Alaska",
    "AZ":"Arizona",
    "AR":"Arkansas",
    "CA":"California",
    "CO":"Colorado",
    "CT":"Connecticut",
    "DE":"Delaware",
    "FL":"Florida",
    "GA":"Georgia",
    "HI":"Hawaii",
    "ID":"Idaho",
    "IL":"Illinois",
    "IN":"Indiana",
    "IA":"Iowa",
    "KS":"Kansas",
    "KY":"Kentucky",
    "LA":"Louisiana",
    "ME":"Maine",
    "MD":"Maryland",
    "MA":"Massachusetts",
    "MI":"Michigan",
    "MN":"Minnesota",
    "MS":"Mississippi",
    "MO":"Missouri",
    "MT":"Montana",
    "NE":"Nebraska",
    "NV":"Nevada",
    "NH":"New Hampshire",
    "NJ":"New Jersey",
    "NM":"New Mexico",
    "NY":"New York",
    "NC":"North Carolina",
    "ND":"North Dakota",
    "OH":"Ohio",
    "OK":"Oklahoma",
    "OR":"Oregon",
    "PA":"Pennsylvania",
    "RI":"Rhode Island",
    "SC":"South Carolina",
    "SD":"South Dakota",
    "TN":"Tennessee",
    "TX":"Texas",
    "UT":"Utah",
    "VT":"Vermont",
    "VA":"Virginia",
    "WA":"Washington",
    "WV":"West Virginia",
    "WI":"Wisconsin",
    "WY":"Wyoming"},
*/
