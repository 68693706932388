import React, { Component } from "react";
import Modal from "components/modal/modal.component";
import { ReactComponent as Video360Icon } from 'img/360-degrees.svg'

export class SBMediaSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMediaIndex: 0,
      showVideoModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.diamondId !== this.props.diamondId) {
      this.setState({ activeMediaIndex: 0 })
    }
  }

  openVideoModal = () => this.setState({ showVideoModal: true })
  closeVideoModal = () => this.setState({ showVideoModal: false })

  leftClick = (event) => {
    if (this.state.activeMediaIndex > 0) {
      this.setState({ activeMediaIndex: this.state.activeMediaIndex - 1 });
    }
  };
  rightClick = (event) => {
    if (this.state.activeMediaIndex < this.props.media.length - 1) {
      this.setState({ activeMediaIndex: this.state.activeMediaIndex + 1 });
    }
  };

  render() {
    const { activeMediaIndex, showVideoModal } = this.state;
    const { className, media } = this.props;

    const video = media.find(m => m.type === 'video')
    const mediaList = video ? media.slice(1) : media;

    const selected = mediaList[activeMediaIndex];
    const showArrows = mediaList.length > 1;

    return selected ? (
      <div className={`slider ${className || ""} ${showVideoModal ? 'modal-open' : ''}`}>
        {selected.type === 'image' &&
          <div className="slider-images">
            <div className="slider-pic">
              <img
                id="main-image"
                src={selected.src}
                alt=""
                className="slider-img"
              />
            </div>
          </div>
        }
        {showArrows && (
          <>
            <button
              className="ctrl -bordered slider-ctrl -prev"
              disabled={activeMediaIndex === 0}
              onClick={this.leftClick}
            >
              <span className="ico ico-ArrowLeft" />
            </button>

            <button
              className="ctrl -bordered slider-ctrl -next"
              disabled={activeMediaIndex === media.length - 1}
              onClick={this.rightClick}
            >
              <span className="ico ico-ArrowRight" />
            </button>
          </>
        )}
        {video && <Video360Icon className="video360-icon" onClick={this.openVideoModal} />}
        {showVideoModal &&
        <Modal className="video360-modal" canClose modalClicked={this.closeVideoModal}>
          <button className="ctrl -lg video360-modal--close" onClick={this.hideGiaCert}>
            <span className="ico ico-Cross" />
          </button>
          <iframe
            id="diamond-video-iframe"
            title="Diamond 360"
            src={video.src}
            frameBorder="0"
          />
        </Modal>}
      </div>
    ) : null;
  }
}
