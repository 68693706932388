import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import { ReactComponent as ArrowIcon } from "img/common/arrow-right.svg";

class SBItem extends Component {
    addMetaData() {
        const meta = {
            title: 'Find the perfect Engagement Ring on Joolez. ',
            description: 'Engagement rings worthy of your grand gesture moment. The search for your perfect diamond ring is over. ',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'engagement rings, diamonds, diamond ring, diamond engagement ring, best engagement ring, joolez'
                }
            }
        }

        return <DocumentMeta {...meta} />;
    }

    render() {
        return (
            <div className="shop-by-item">
                {this.addMetaData()}
                <Link to={this.props.url} className="shop-by-link" onClick={()=>this.props.setAction(this.props.actionType)}>
                    <div className="shop-by-pic">
                        <img src={this.props.img} alt={this.props.name} className="shop-by-img" />
                    </div>
                    <div className="shop-by-descr">
                        <div className="shop-by-info">
                            <div className="shop-by-name">
                                {this.props.name}
                            </div>
                            <div className="shop-by-note">
                                {this.props.description}
                            </div>
                        </div>
                        <div className="shop-by-ctrls">
                            <ArrowIcon/>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

SBItem.propTypes = {};

export default SBItem;
