import React from "react";

const FooterPartners = (props) => {
  return (
    <div className="footer-partners">
      <div className="footer-partners__item">
        <img
          src={require(`img/footer-partners/norton-secured.jpg`).default}
          srcSet={`${
            require(`img/footer-partners/norton-secured@2x.jpg`).default
          } 2x, ${
            require(`img/footer-partners/norton-secured@3x.jpg`).default
          } 3x`}
          alt=""
        />
      </div>
      <div className="footer-partners__item">
        <img
          src={require(`img/footer-partners/bbb.jpg`).default}
          srcSet={`${require(`img/footer-partners/bbb@2x.jpg`).default} 2x, ${
            require(`img/footer-partners/bbb@3x.jpg`).default
          } 3x`}
          alt=""
        />
      </div>
      <div className="footer-partners__item">
        <img
          src={require(`img/footer-partners/gia.png`).default}
          srcSet={`${require(`img/footer-partners/gia@2x.png`).default} 2x, ${
            require(`img/footer-partners/gia@3x.png`).default
          } 3x`}
          alt=""
        />
      </div>
    </div>
  );
};

export default FooterPartners;
