import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import DiamondSearch from "./diamondsearch.component";
import TermsConditions from "./terms.conditions.component";
import ShopBy from "./shop.by.component/shop.by.component";
import SBSize1 from "./shop.by.component/shopbysize/shopbysize1.component";
import SBPrice1 from "./shop.by.component/shopbyprice/shopbyprice1.component";
import SBPrice2 from "./shop.by.component/shopbyprice/shopbyprice2.component";
import Landing from "./landing.component";
import SBShape1 from "./shop.by.component/shopbyshape/shopbyshape1.component";
import SBProductDetails from "./shop.by.component/productDetails/productDetail.component";
import Register from "./register.component";
import Shipping from "./shipping.component";
import Billing from "./billing.component";
import ShippingBilling from "./shipping-billing.component";
import OrderReview from "./orderreview.component";
import CardHolder from "./cardholder.component";
import CardHolderReview from "./cardholder-review.component";
import Cart from "./cart/cart.component";
import NewUser from "./newUser.component";
import DiamondsCompare from "./shop.by.component/diamonds.compare.component";
import HowItWorks from "./how-it-works.component";
import Account from "./account.component";
import AccountMarkup from "./account.markup.component";
import PasswordReset from "./reset.password.component";
import PasswordChange from "./change.password.component";
import PasswordForgot from "./forgot.password.component";
import OrderReceived from "./order-received/order-received.component";
import ContactUs from "./contact";
import RingDetail from "./ring.detail.component/RingDetail";
import RingBuilder from "./ring.builder.component/RingBuilder";

export class MainComponent extends React.Component {
	setDiamond = (obj) => {

	}

	render() {
		return (
			<div className="container">
				<Switch>
					<Route exact path='/' component={Landing} />
					<Route path='/ring-detail' component={RingDetail} />
					<Route path='/ring-builder/:ringUrlKey' component={RingBuilder} />
					<Route path='/diamond_search' component={DiamondSearch} />
					<Route path='/terms-conditions'
						render={(props) => <TermsConditions {...props} key={1} pageNumber={7} />}
					/>
					<Route path='/privacy-policy'
						render={(props) => <TermsConditions {...props} key={2} pageNumber={4} />}
					/>
					<Route path='/how-it-works' component={HowItWorks} />
					<Route path='/shop-by' component={ShopBy} />
					<Route path='/shop-by-size/step1' component={SBSize1} />
					<Route path='/shop-by-price/step1' component={SBPrice1} />
					<Route
						path='/shop-by-price/step2'
						render={(props) => <SBPrice2 {...props}
							showOverlay={this.props.showOverlay}
							overlayVisible={this.props.overlayVisible} />}
					/>
					<Route path='/shop-by-shape/step1' component={SBShape1} />
					<Route
						path='/product-details/:did'
						render={(props) => <SBProductDetails {...props} sendDiamond={this.setDiamond} />}
					/>
					<Route path='/register' component={Register} />
					<Route path='/shipping' component={Shipping} />
					<Route path='/billing' component={Billing} />
					<Route path='/order-review' component={OrderReview} />
					<Route path='/shipping-billing' component={ShippingBilling} />
					<Route path='/cardholder' component={CardHolder} />
					<Route path='/cardholder-review' component={CardHolderReview} />
					<Route path='/new-client' component={NewUser} />
					<Route
						path='/compare-diamonds'
						render={(props) => <DiamondsCompare {...props} sendDiamond={this.setDiamond} />}
					/>
					<Route path='/cart' component={Cart} />
					<Route path='/account' component={Account} />
					<Route path='/account-markup' component={AccountMarkup} />
					<Route path='/password-reset' component={PasswordReset} />
					<Route path='/password-change' component={PasswordChange} />
					<Route path='/password-forgot' component={PasswordForgot} />
					<Route path='/order-received' component={OrderReceived} />
					<Route path='/contact-us' component={ContactUs} />
				</Switch>
			</div>
		)
	}
}

MainComponent.propTypes = {
	clickHandler: PropTypes.func,
};
export default MainComponent;
