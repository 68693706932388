import { useQuery } from "react-query";
import { CustomerService } from "components/services/customer.service";
import { CartService } from "components/services/cartService";

const customerSvc = new CustomerService();
const cartSvc = new CartService();

export function useCart(sku) {
  const { data: guestCartId } = useGuestCartId();
  const { data: customerId } = useCustomerId();
  const { data: customerCartId } = useCustomerCartId();

  const cartId = customerId ? customerCartId : guestCartId;
  const payload = {
    cartItem: {
      sku,
      qty: 1,
      quote_id: cartId,
    },
  };

  return customerId
    ? () => cartSvc.addItemToCustomerCart(payload)
    : () => cartSvc.addItemToGuestCart(cartId, payload);
}

function useGuestCartId() {
  return useQuery(["guest-cart-id"], async () => {
    let guestCartId = localStorage.getItem("guestCartId");
    if (!guestCartId) {
      guestCartId = await cartSvc.createGuestCart();
      localStorage.setItem("guestCartId", guestCartId);
    }
    return guestCartId;
  }, {
    staleTime: 0
  });
}

function useCustomerId() {
  return useQuery(["customer-id"], () =>
    customerSvc.getCustomer().then((res) => res.id)
  );
}

function useCustomerCartId() {
  return useQuery(["customer-cart-id"], () =>
    cartSvc.getCustomerCart().then((res) => res.id)
  );
}
