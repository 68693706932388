import React from "react";
import { connect } from "react-redux";
import { CustomerService } from "./services/customer.service";
import { setUserType, setCustomerId } from "../redux/user/actions";
import { AuthorizedCallService } from "./services/authorizedcall.service";
import { ReactComponent as FacebookIcon } from "img/facebook.svg";
import { ReactComponent as TwitterIcon } from "img/twitter.svg";
import { ReactComponent as InstagramIcon } from "img/instagram.svg";
import { ReactComponent as LinkedIcon } from "img/linkedin.svg";
import "./nav/nav-updated.scss";

const socialLinks = [
  { icon: <FacebookIcon />, link: "https://www.facebook.com/joolezai" },
  { icon: <LinkedIcon />, link: "https://www.linkedin.com/company/joolez" },
  { icon: <TwitterIcon />, link: "https://twitter.com/joolezai" },
  { icon: <InstagramIcon />, link: "https://www.instagram.com/joolez.ai" },
];

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.localClose = false;
    this.customerSvc = new CustomerService();
    this.authSvc = new AuthorizedCallService();
  }

  crossButton = (click) => {
    this.props.crossClicked(true);
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.className !== "ico ico-Menu"
    ) {
      this.crossButton();
    }
  }

  logout = () => {
    this.customerSvc.customerSignOut(this.props.customer_id).then((res) => {
      this.authSvc.removeAuthorizationHeader();
      this.props.setCustomerId("");
      this.props.setUserType("guest");

      this.crossButton();
      this.props.signOff();
    });
  };

  handleEducation = () => {
    this.crossButton();
    this.props.toggleEducation(true);
  };

  render() {
    const { shown } = this.props;

    return (
      <div
        className={`sidepanel ${shown ? "" : "_hidden"}`}
        ref={this.setWrapperRef}
      >
        <button
          className="ctrl  -lg sidepanel-header_close"
          onClick={this.crossButton}
        >
          <span className="ico ico-Cross"></span>
        </button>
        <div className="dr-nav-holder">
          <nav className="dr-nav padding-md" aria-label="Main">
            <ul>
              <li>
                <a className="dr-nav__link" href="/" onClick={this.crossButton}>
                  <span>Home</span>
                  <span></span>
                </a>
              </li>
              <li>
                <a className="dr-nav__link" href="/how-it-works">
                  <span>How it works</span>
                  <span></span>
                </a>
              </li>
              <li>
                <a
                  className="dr-nav__link"
                  href="/about"
                  onClick={this.crossButton}
                >
                  <span>About Joolez</span>
                  <span></span>
                </a>
              </li>
              <li>
                <a
                  className="dr-nav__link"
                  href="/shop-by"
                  onClick={this.crossButton}
                >
                  <span>Find your diamond</span>
                  <span>
                    <svg
                      width="44"
                      height="34"
                      viewBox="0 0 44 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M43.2702 10.8306C41.9139 9.38393 34.8614 1.51764 34.3189 0.884716C34.1381 0.703882 33.9572 0.613464 33.686 0.613464H9.90626C9.63501 0.613464 9.45417 0.703882 9.27334 0.884716C8.73084 1.42722 1.6783 9.38393 0.32204 10.8306C0.0507885 11.1019 -0.0396286 11.6444 0.32204 12.006C4.48123 16.3461 -10.4376 0.794299 21.2084 33.7966C21.5701 34.0678 22.0222 34.0678 22.3838 33.7966C30.1597 25.659 35.6752 19.8723 43.2702 12.006C43.5414 11.7348 43.5414 11.1923 43.2702 10.8306ZM29.6172 23.7603L28.6226 22.0423L33.8668 12.2773H40.5577L29.6172 23.7603ZM2.94414 12.2773H9.63501L14.8792 22.0423L13.8846 23.7603L2.94414 12.2773ZM5.65665 7.39475L8.27875 10.5594H2.85372L5.65665 7.39475ZM15.9642 7.30433L19.4905 10.5594H12.3475L15.9642 7.30433ZM10.2679 10.1977L6.92249 6.12891L10.2679 2.60264L14.608 6.12891L10.2679 10.1977ZM17.3205 6.12891L21.7509 2.60264L26.1813 6.12891L21.7509 10.1977L17.3205 6.12891ZM20.3042 12.2773L15.8738 20.234L11.6242 12.2773H20.3042ZM21.7509 13.1814L26.6334 22.0423L21.7509 31.084L16.8684 22.0423L21.7509 13.1814ZM23.1976 12.2773H31.8776L27.628 20.234L23.1976 12.2773ZM24.0113 10.5594L27.5376 7.30433L31.0639 10.5594H24.0113ZM28.8939 6.12891L33.2339 2.60264L36.5793 6.12891L33.2339 10.1977L28.8939 6.12891ZM27.5376 4.95349L24.1922 2.33139H30.7926L27.5376 4.95349ZM15.9642 4.95349L12.6188 2.33139H19.2192L15.9642 4.95349ZM15.8738 23.8507L18.3151 28.3715L15.2409 25.1165L15.8738 23.8507ZM27.628 23.8507L28.3514 25.1165L25.2772 28.3715L27.628 23.8507ZM35.2231 10.5594L37.8452 7.39475L40.6481 10.5594H35.2231Z"
                        fill="#47E4C2"
                      />
                    </svg>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="dr-nav__link"
                  href="/ring-detail"
                  onClick={this.crossButton}
                >
                  <span>Build your ring</span>
                  <span className="ico RingIcon" />
                </a>
              </li>
              <li className="border-top margin-top-md padding-top-md">
                <a className="dr-nav__link dr-nav__link-small" href="/cart">
                  <span>Cart</span>
                  <span>
                    <span className="ico ico-Bag logo-ico">
                      <span className="cart-count">
                        {this.props.cart.length}
                      </span>
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="dr-nav__link dr-nav__link-small"
                  href="/account"
                  onClick={this.crossButton}
                >
                  <span>My Account</span>
                  <span></span>
                </a>
              </li>

              {this.props.user_type === "customer" && !!this.props.customer_id && (
                <li>
                  <a
                    className="dr-nav__link dr-nav__link-small"
                    href="#"
                    onClick={this.logout}
                  >
                    <span>Sign Out</span>
                    <span></span>
                  </a>
                </li>
              )}

              <li className="border-top margin-top-md padding-top-md">
                <a
                  className="dr-nav__link dr-nav__link-small"
                  href="/contact"
                  onClick={this.crossButton}
                >
                  <span>Contact</span>
                  <span></span>
                </a>
              </li>
              <li>
                <a
                  className="dr-nav__link dr-nav__link-small"
                  href="/privacy-policy"
                  onClick={this.crossButton}
                >
                  <span>Privacy Policy</span>
                  <span></span>
                </a>
              </li>
              <li>
                <a
                  className="dr-nav__link dr-nav__link-small"
                  href="/terms-conditions"
                  onClick={this.crossButton}
                >
                  <span>Terms of use</span>
                  <span></span>
                </a>
              </li>
            </ul>
          </nav>
          <footer className="dr-nav-footer">
            {socialLinks.map((i, j) => (
              <a
                className="dr-nav-footer-icon-link"
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                key={"link_" + j}
              >
                {i.icon}
              </a>
            ))}
          </footer>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cart: state.diamond.cart,
  customer_id: state.user.customer_id,
  user_type: state.user.user_type,
});

const mapDispatchToProps = {
  setUserType,
  setCustomerId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
