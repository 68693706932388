import React from "react";
import { Link } from "react-router-dom";

class Landing extends React.Component {
  render() {
    document.title = "Joolez";
    return (
      <div className="page welcome-page">
        <div className="welcome-text">
          Where BIG commitment meets SMART choices. Find your perfect diamond.
        </div>

        <div className="welcome-btns">
          <Link to="/shop-by" className="btn">
            Build your ring
          </Link>
        </div>

        <div className="welcome-link">
          <Link to="/how-it-works" className="-inverse">
            How it works
          </Link>
        </div>
      </div>
    );
  }
}

export default Landing;
