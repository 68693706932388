import React from "react";
import config from "../configurations";

const Cut = (props) => {
	const {
		className,
		yourDiamond,
		levelClassName = "",
	} = props;

	return (
		<div className={`cut ${className}`}>
			{config.grades.map((item, i) => (
				<div className="cut__item" key={item}>
					<div className="cut__image">
						<img
						src={require(`../../img/education/diamond-cut-${i+1}.jpg`).default}
						srcSet={`${require(`../../img/education/diamond-cut-${i+1}@2x.jpg`).default} 2x, ${require(`../../img/education/diamond-cut-${i+1}@3x.jpg`).default} 3x`} alt={`Diamond Cut ${item}`}
					/>
					</div>
					<div className={`cut__title ${yourDiamond === i ? "_accent" : ""}`}>
						<div>{item}</div>
						{yourDiamond === i && <b>Your diamond</b>}
					</div>
					<div className={`education-level ${levelClassName} cut__level _${i+1} ${i === 4 ? `_4` : ''}`}>
						<span/><span/><span/><span/>
					</div>
				</div>
			))}
		</div>
	)
};

export default Cut;