import {config} from "../../components/configurations";

export class ContentService {
  constructor() {
    this.cmsPrefix = '/V1/cmsPage/';
  }

  getPageContent = (pageId) => {
    return fetch(config.magentoURL+this.cmsPrefix + pageId)
    .then( res => res.json())
    .catch( err => this.error.handleError(err));
  }
}

export default ContentService;
