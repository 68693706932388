import {config} from "../../components/configurations";
import {ErrorHandlingService} from "../services/errorHandling.service";

export class DiamondSearch {
    constructor() {
        this.error = new ErrorHandlingService();
    }

    getDiamondById = (id) => {
        return fetch(config.baseurl+'diamonds/'+id,{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "header": {
                    "username": config.username,
                    "password": config.password
                  }
            })
        })
        .then( res => res.json())
        .catch( err => err.json());
    }

    compareDiamonds = (idsArray) => {
        return fetch(config.baseurl+'comparisons/',{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "header": {
                    "username": config.username,
                    "password": config.password
                  },
                  "diamond_ids": idsArray
            })
        })
        .then( res => res.json())
        .catch( err => this.error.handleError(err));
    }

    editDiamond = (type, payload, state, diamond) => {
        let requestBody = {
            "header": {
                "username": config.username,
                "password": config.password
              },    
              body: {diamond: diamond}          
        }
        requestBody.body[type] = payload;
        if (Object.keys(state).length > 0) {
            requestBody.body["state"] = state;
        }
        
        if (Object.keys(state).length <= 0 && Object.keys(JSON.parse(sessionStorage.getItem('diamondState'))).length > 0) {
            requestBody.body["state"] = JSON.parse(sessionStorage.getItem('diamondState'));
        }
        return fetch(config.baseurl+'rule_recommendation/',{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
        .then( res => res.json())
        .catch( err => this.error.handleError(err));

    }
}

export default DiamondSearch;