import React from "react";
import PropTypes from "prop-types";

export class DiamondSearch extends React.Component {
    render() {
        return (
            <article class="product-assistant">
            <header class="product-assistant_header">
            <h2 class="product-assistant_title">Find the Diamond is under construction</h2>
            </header>
            <div class="product-assistant_content">
            <p></p>
            <p>Once page is in place, you will be able to access Diamond Search.</p>
            </div>
            </article>
        )
    }
}
DiamondSearch.propTypes = {
    clickHandler: PropTypes.func,
  };
export default DiamondSearch;