import { config } from "../../components/configurations";
import { ErrorHandlingService } from "../services/errorHandling.service";
import { AuthorizedCallService } from "../services/authorizedcall.service";

export class RingService {
    constructor() {
        this.error = new ErrorHandlingService();
        this.customerCall = new AuthorizedCallService();
        this.ringsPrefix = `/V1/products?searchCriteria[filter_groups][0][filters][0][field]=attribute_set_id&searchCriteria[filter_groups][0][filters][0][value]=24&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][1][field]=type_id&searchCriteria[filter_groups][1][filters][1][value]=configurable&searchCriteria[filter_groups][1][filters][1][condition_type]=eq&searchCriteria[pageSize]=${config.ringsPageSize}&fields=items,total_count`;
        this.specificRingPrefix = '/V1/products/';
        this.optionsRingsPrefix = '/V1/products/attributes/ring_options/options/';
        this.getRingsByOptionsPrefix1 = '/V1/products?searchCriteria[filter_groups][0][filters][0][field]=attribute_set_id&searchCriteria[filter_groups][0][filters][0][value]=24&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][1][field]=type_id&searchCriteria[filter_groups][1][filters][1][value]=configurable&searchCriteria[filter_groups][1][filters][1][condition_type]=eq&searchCriteria[filter_groups][2][filters][2][field]=ring_options&searchCriteria[filter_groups][2][filters][2][value]='
        this.getRingsByOptionsPrefix2 = `&searchCriteria[filter_groups][2][filters][2][condition_type]=eq&searchCriteria[pageSize]=${config.ringsPageSize}&fields=items[sku],total_count`;
    }

    getRings = (page = 1) => {
        return fetch(config.magentoURLProd + this.ringsPrefix + `&searchCriteria[currentPage]=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err))
    }
    getSpecificRing = (ringSku) => {
        return fetch(config.magentoURLProd + this.specificRingPrefix + ringSku, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err))
    }

    getRingsOptions = () => {
        return fetch(config.magentoURLProd + this.optionsRingsPrefix, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err))
    }
    getRingsSkullsByOptions = (ringCategoryId, page = 1) => {
        return fetch(config.magentoURLProd + this.getRingsByOptionsPrefix1 + ringCategoryId + this.getRingsByOptionsPrefix2 + `&searchCriteria[currentPage]=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
            .then(res => res.json())
            .catch(err => this.error.handleError(err))
    }
}


export default RingService;