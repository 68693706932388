import {ErrorHandlingService} from "../services/errorHandling.service";
import {CartService} from "../services/cartService"

export class AddToCartUtil {

    constructor() {
        this.error = new ErrorHandlingService();
    }

    addDiamond = (id) => {
        let cart = new CartService();
        return cart.getCustomerCart()
        .then( res => {
            if (res.id) {
                sessionStorage.cartId = res.id;
                cart.addDiamondToCart(res.id,id)
                .then( res => true)

            } else {
            let guestId = localStorage.getItem('guestCartId');
            if (!!guestId) {
                cart.getGuestCart(guestId)
                .then( res => {
                    if (res.id) {
                    cart.addDiamondToCart(res.id,id)
                    .then( res => true)


                    } else {
                    cart.createGuestCart()
                    .then( res => {
                        localStorage.guestCartId = res;
                        cart.getGuestCart(res)
                        .then( res => {
                        cart.addDiamondToCart(res.id,id)
                        .then( res => true)
                        })
                    })
                    }

                })
            } else {
                cart.createGuestCart()
                    .then( res => {
                        localStorage.guestCartId = res;
                        cart.getGuestCart(res)
                        .then( res => {
                        cart.addDiamondToCart(res.id,id)
                        .then( res => true)
                        })
                    })
            }

            }
        })
    }

    priceFormatter = (x) => {
        let res = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (res.split('.').length === 1) res = res + '.00';
        if (res.split('.')[1] && res.split('.')[1].length === 1) res = res + '0';
        return res;
    }

    getRingSizes = () => {
        let b = 3;
        let res = [{value:'0', title: "I'm not sure"}];
        while (b<=12) {
            res.push({value:b.toString(), title: "Size "+b}); 
            b +=0.25
        }
        return res;
    }


}

export default AddToCartUtil;
