import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {CartService} from "./services/cartService";
import {CustomerService} from "./services/customer.service";
import {AuthorizedCallService} from "./services/authorizedcall.service";
import {connect} from 'react-redux';
import {setError, setCustomerId, setUserType} from "../redux/user/actions"
import {gtmId} from "./consts";
import TagManager from "react-gtm-module";

class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            redirect: false
        }
    }

    componentWillUnmount() {
        this.props.setError('');
    }

    componentDidMount() {
        let customer = new CustomerService();
        //let cart = new CartService();
        customer.getCustomer()
        .then( res => {
            if (!!res.id) {
                this.setState({redirect: true})
            }
        })

        const tagManagerViewArgs = {
            gtmId: gtmId,
            dataLayer: {
                event: 'checkout',
                eventLabel: 'Checkout View',
                ecommerce: {
                    checkout: {
                        actionField: {step: 1},
                    },
                },


            },
        };
        TagManager.dataLayer(tagManagerViewArgs);
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            let url = this.props.location.state && this.props.location.state.url ? this.props.location.state.url : '/shipping-billing';
          return <Redirect to={url} />
        }
      }

    userLogin = (e) => {
        e.preventDefault();
        let customer = new CustomerService();
        customer.customerLogin(this.state.email,this.state.password)
        .then( res => {
            if (!!res && !res.message) {
                new AuthorizedCallService().setAuthorizationHeader(res);
                
                if (!!localStorage.getItem('guestCartId')) {
                    let cartService = new CartService();
                        cartService.mergeGuestAndCustomerCart(localStorage.getItem('guestCartId'))
                        .then( res => {
                            customer.getCustomer()
                            .then( res => {
                                this.props.setUserType('customer');
                                this.props.setCustomerId(res.id);
                                this.setState({redirect:true})
                            })
                            
                        })
                } else {
                    customer.getCustomer()
                            .then( res => {
                                this.props.setUserType('customer');
                                this.props.setCustomerId(res.id);
                                this.setState({redirect:true})
                            })
                    
                }

            } else {
                this.props.setError(res.message || res);
            }
        })
    }
    /* <div className="register__or">
                    <span>Or</span>
                </div> is the code for OR separator once facebook log in will be available*/
    render() {
        const {
            email,
            password,
        } = this.state;

        return (
            <div className="register register-container">
            {this.renderRedirect()}
                <div className="register__item">
                    <h2>
                        New user
                    </h2>
                    <Link to={{pathname: "/new-client", state: {url: this.props.location.state && this.props.location.state.url}}} className="btn register__btn">
                        Create an account
                    </Link>
                    {(!this.props.location.state ||! this.props.location.state.url) && <Link to="/shipping-billing" className="btn register__btn">
                        Continue as a guest
                    </Link>}
                </div>
                <div className="register__item _mb-0">
                    <h2>
                        Access your account
                    </h2>

                </div>

                <form onSubmit={this.userLogin} className="register__form">
                    <input
                        type="text"
                        className="input register__input"
                        onChange={(e) => this.setState({
                            email: e.target.value
                        })}
                        placeholder="Enter Email address"
                        value={email}
                    />
                    <input
                        type="password"
                        className="input register__input"
                        onChange={(e) => this.setState({
                            password: e.target.value
                        })}
                        placeholder="Password"
                        value={password}
                    />
                    <button type="submit" className="btn register__submit">
                        Log In
                    </button>
                </form>
                <div class="forgot-password-holder">
                    <Link className="forgot-password-btn" to={{pathname: "/password-forgot", state: {url: this.props.location.state && this.props.location.state.url}}}>
                        Forgot password?
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    error: state.user.error
});

const mapDispatchToProps ={
    setError,
    setCustomerId,
    setUserType
};


export default connect(mapStateToProps,mapDispatchToProps)(Register);
