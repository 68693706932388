import React from "react";
const SimpleArrow = ({ className, to, onClick, direction, disabled }) => (
  <button
    type="button"
    onClick={onClick}
    className={`${className} simple-arrow ${
      direction === "left" ? "simple-arrow-prev" : "simple-arrow-next"
    }`}
    aria-label={to}
    disabled={disabled}
  >
    {direction === "left" ? (
      <img
        src={require("img/left-arrows.png").default}
        alt="leftArrow"
      />
    ) : (
      <img
        src={require("img/right-arrows.png").default}
        alt="rightArrow"
      />
    )}
  </button>
);

export default SimpleArrow;
