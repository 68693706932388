import React, { useState } from "react";
import { number, func } from "prop-types";
import { useLocation } from "react-router-dom";
import Modal from "components/modal/modal.component";
import logo from "./joolez-icon.png";

const URL = "https://api.joolez.com/rest/V1/contactus";

const STATUS_MESSAGE = {
  READY: "Send",
  ERROR: "Send",
  LOADING: "Sending",
  COMPLETE: "Sent!",
};

export default function GiaContactForm({ diamondId, hideGiaCertModal }) {
  const [status, setStatus] = useState("READY"); // READY | LOADING | ERROR | COMPLETE
  const { pathname } = useLocation();

  const handleSend = async (e) => {
    setStatus("LOADING");
    e.preventDefault();
    const { name, email } = e.target.elements;

    const data = {
      contactForm: {
        name: name.value,
        email: email.value,
        comment: `
        Diamond Cert Request
        Diamond ID — ${diamondId}
        URL — ${pathname}
        `,
      },
    };

    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      setStatus("COMPLETE");
    } else {
      setStatus("ERROR");
    }
  };

  const openCustomerChat = () => {
    hideGiaCertModal();
    window.FB.CustomerChat.show();
  };

  return (
    <Modal
      className={`gia-cert-contact-form ${
        status === "COMPLETE" ? "flip-form" : ""
      }`}
    >
      <button
        className="ctrl -lg gia-cert-modal_close"
        onClick={hideGiaCertModal}
      >
        <span className="ico ico-Cross" />
      </button>
      <img src={logo} alt="icon" />
      <div className="title">This Diamond Comes With a Certificate</div>

      <div className="flip">
        <div className="card-front">
          <div className="sub-title">
            To review a copy of the certificate, please enter your email below.
          </div>
          <form onSubmit={handleSend}>
            <label htmlFor="name">Name</label>
            <input id="name" type="text" required />
            <label htmlFor="email">Email</label>
            <input id="email" type="email" required />

            <button
              type="submit"
              disabled={status !== "READY" && status !== "ERROR"}
            >
              {STATUS_MESSAGE[status]}
            </button>

            {status === "ERROR" && (
              <span className="error">
                Something went wrong. Please try again.
              </span>
            )}
          </form>
        </div>
        <div className="card-back">
          <div className="sub-title">
            We’ve received your request. We will email you a copy of the
            certificate shortly. Thanks!
          </div>
        </div>
      </div>

      <div className="separator">
        <span>or</span>
      </div>
      <div className="chat-link" onClick={openCustomerChat}>
        Chat With A Diamond Expert
      </div>
    </Modal>
  );
}

GiaContactForm.propTypes = {
  diamondId: number,
  hideGiaCertModal: func,
};
