import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import './imgSlider.withZoom.scss';
export class SBZoomSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeImageIndex: 0,
    }
    
  }


  left = (event) => {
    if (this.state.activeImageIndex > 0) this.setState({ activeImageIndex: this.state.activeImageIndex - 1 });
  }
  right = (event) => {
    if (this.state.activeImageIndex < this.props.imgs.length - 1) this.setState({ activeImageIndex: this.state.activeImageIndex + 1 });
  }


  render() {
    const {
      className,
      labels,
      description,
      labelSize,
    } = this.props;

    return (
      <div className={`slider ${className || ""}`} onClick={this.props.clicked}>
        {labels &&
          <div className="slider-labels">
            {labels.map((item, i) => (
              <div className={`label ${labelSize ? labelSize : '-md'} slider-label ${item.className || ""}`} key={item.title + i}>
                {item.title}
              </div>
            ))}
          </div>
        }

        <div className="slider-images">
          <div className="slider-pic">
             <InnerImageZoom src={this.props.imgs[this.state.activeImageIndex]} />
          </div>
          
        </div>

        {!this.props.noSlider && <button className="ctrl -bordered slider-ctrl -prev" disabled="" onClick={this.left}>
          <span className="ico ico-ArrowLeft"></span>
        </button>}
        {!this.props.noSlider && <button className="ctrl -bordered slider-ctrl -next" onClick={this.right}>
          <span className="ico ico-ArrowRight"></span>
        </button>}

        {description}
      </div>
    )
  }
}


export default SBZoomSlider;
