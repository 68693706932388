import React from 'react';
import {Link} from 'react-router-dom';
import ContentService from './services/content.service';
import PropTypes from "prop-types";

class TermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: '',
      content: ''
    }
  }

  componentDidMount() {
    let page = new ContentService();
		page.getPageContent(this.props.pageNumber)
		.then( res => {
			this.setState({
        heading: res.content_heading,
        content: res.content
      });
		});
  }

  render() {
    return (
      <section className="text-section text-section__terms">
        <h1>{this.state.heading}</h1>
        <div dangerouslySetInnerHTML={{__html: this.state.content}} />
        <Link className="btn" to="/">
            Got it
        </Link>
      </section>
    )
  }
}

TermsConditions.propTypes = {
	pageNumber: PropTypes.number
};

export default TermsConditions;
