export const RING_ID_DETAILS = 'RING_ID_DETAILS'
export const SET_CURRENT_RING_DETAILS = 'SET_CURRENT_RING_DETAILS'
export const SET_RINGS_OPTIONS = 'SET_RINGS_OPTIONS'
export const SET_RING_INTO_CART = 'SET_RING_INTO_CART'

export const setRingId = (ringId) => ({
    type: RING_ID_DETAILS,
    payload: ringId
});

export const setCurrentRingDetails = (details) => ({
    type: SET_CURRENT_RING_DETAILS,
    payload: details
});

export const setRingsOptions = (options) => ({
    type: SET_RINGS_OPTIONS,
    payload: options
});

export const setRingIntoTheCart = (ringObject) => ({
    type: SET_RING_INTO_CART,
    payload: ringObject,
})