import { useQuery, useQueryClient } from "react-query";
import { request, gql } from "graphql-request";
import { useLocation } from "react-router-dom";
import { config } from "components/configurations";
const { magentoGraphqlURL } = config;

const RING_BY_URL_KEY_QUERY = gql`
  query($filter: ProductAttributeFilterInput) {
    products(search: "Ring", filter: $filter) {
      items {
        id
        name
        sku
        ring_options
        setting
        size_limit
        accent_stones
        diamond_shapes
        url_key
        brand
        meta_title
        meta_keyword
        meta_description
        media_gallery {
          url
        }
         thumbnail {
          url
        }
        short_description {
          html
        }
        price_range {
          minimum_price {
            regular_price {
              currency
              value
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            attribute_code
            values {
              value_index
              label
            }
          }
        }
      }
    }
  }
`;

export function useRing(urlKey) {
  const { state } = useLocation();
  const ringsPage = state?.ringsPage ?? 1;

  const queryClient = useQueryClient();

  return useQuery(
    ["ring", urlKey],
    ({ queryKey }) => {
      const [, urlKey] = queryKey;
      const variables = { filter: { url_key: { eq: urlKey } } };

      return request(magentoGraphqlURL, RING_BY_URL_KEY_QUERY, variables).then(
        (data) => data?.products.items?.[0]
      );
    },
    {
      placeholderData: () => {
        const data = queryClient.getQueryData([
          "rings",
          { filter: "all", page: +ringsPage },
        ]);

        const ring = data?.rings.find((ring) => ring.url_key === urlKey);

        return ring
          ? {
              ...ring,
              media_gallery: [{ url: ring.thumbnail.url }],
            }
          : undefined;
      },
    }
  );
}
