import React from "react";
import config from "components/configurations";

const Color = (props) => {
	const {
		className,
		yourDiamond,
		levelClassName = "",
	} = props;

	return (
		<div className={`color ${className}`}>
			{config.grades.map((item, i) => (
				<div className={`color__item ${item.toLowerCase()}`} key={item}>
					<div className="color__title">
						{item}
					</div>
					<div className="color__image">
						<img
							src={require(`img/education/diamond-color-${i+1}.png`).default}
							srcSet={`${require(`img/education/diamond-color-${i+1}@2x.png`).default} 2x, ${require(`img/education/diamond-color-${i+1}@3x.png`).default} 3x`} alt={`Diamond Color ${item}`}
						/>
					</div>
					<div className={`education-level ${levelClassName} color__level _${i+1} ${i === 4 ? `_4` : ''}`}>
						<span/><span/><span/><span/>
					</div>
					<div className="color__letters">
						{config.letters[i].split("").map(item => (
							<span className={`${yourDiamond === item ? "_accent" : ""}`} key={item}>
								{yourDiamond === item && <b>Your diamond</b>}
								{item}
							</span>
						))}
					</div>
				</div>
			))}
		</div>
	)
};

export default Color;
