import React, {Component} from 'react';
import SBItem from "./shop.by.item.component";
import {connect} from 'react-redux';
import {setShopByAction} from "../../redux/diamond/actions";

class ShopBy extends Component {
    render() {
        document.title = 'Shop By - Joolez';
        let items = MOCK_ITEMS.map(item => {
            return <SBItem img={item.img}
            name={item.name}
            description={item.description}
            url={item.url}
            key={item.id}
            actionType={item.actionType}
            setAction={(action)=> this.props.setShopByAction(action)}/>
        });
        return (
            <div className="shop-by">
                {items}
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps ={
    setShopByAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopBy);

const MOCK_ITEMS = [
    {
        id: 1,
        img: require('../../pics/shop-by-price.png').default,
        name:'Shop by price',
        description: 'Find an ideal diamond based on your budget.',
        url:'/shop-by-price/step1',
        key: 'price',
        actionType: 'price'
    },
    {
        id: 2,
        img: require('../../pics/shop-by-size.png').default,
        name:'Shop by size',
        description: 'Size matters. Find the right diamond by size.',
        url:'/shop-by-size/step1',
        key: 'size',
        actionType: 'size'
    },
    {
        id: 3,
        img: require('../../pics/shop-by-shape.png').default,
        name:'Shop by shape',
        description: 'Looking for a unique diamond shape? See what\'s trending.',
        url:'/shop-by-shape/step1',
        key:'shape',
        actionType: 'shape'
    }
];


