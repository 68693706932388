import React from 'react';
import { fb } from './utils/fb';
import {config} from "./configurations";

class CustomerChat extends React.PureComponent {
  componentDidMount() {
      fb(FB => FB.XFBML.parse());
   
  }

  componentWillUnmount() {
    // clearTimeout(this.timeout);
    // delete this.timeout;
  }

  render() {
    let fbClasses = !!this.props.hide ? "fb-customerchat fb_chat__hidden" : "fb-customerchat fb_chat__hidden";
    return (     
          <div
            className={fbClasses}
            attribution="setup_tool"
            page_id={config.facebookPageId}
            theme_color="#5F39E3"
            // logged_in_greeting="..."
            // logged_out_greeting="..."
            // greeting_dialog_display="hide"
            // greeting_dialog_delay="100"
            // minimized="true"
            // ref="..."
          />
  
    );
  }
}

export default CustomerChat;