import React from "react";
import PropTypes from "prop-types";

const Checkbox = (props) => {
	const {
		className,
		name,
		checked,
		children,
		onChange,
	} = props;

	return (
		<div className={`checkbox ${className}`}>
			<input
				style={{display: "none"}}
				type="checkbox"
				id={name}
				name={name}
				checked={checked}
				onChange={onChange}
			/>
			<label className="text-1" htmlFor={name}>
				<i></i>
				{children &&
					<span>{children}</span>
				}
			</label>
		</div>
	);
}

Checkbox.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	checked: PropTypes.bool,
	children: PropTypes.string,
	onChange: PropTypes.func,
};

export default Checkbox;