import {config} from "../../components/configurations";
import {ErrorHandlingService} from "./errorHandling.service";

export class Recommendations {
    constructor() {
        this.error = new ErrorHandlingService();
    }
    getRecommendations = (payload) => {
        return fetch(config.baseurl+'recommendations/',{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "header": {
                    "username": config.username,
                    "password": config.password
                  },
                  "body": payload
            })
        })
        .then( res => res.json())
        .catch ( err => this.error.handleError(err));
    }

    getRuleRecommendations = (payload) => {
        return fetch(config.baseurl + 'rule_recommendation/',{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "header": {
                    "username": config.username,
                    "password": config.password
                  },
                  "body": {
                    "preference": payload
                  }
            })
        })
        .then( res => res.json())
        .catch ( err => this.error.handleError(err));
    }

    getPriceRange = (payload) => {
        return fetch(config.baseurl + 'pricerange/',{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "header": {
                    "username": config.username,
                    "password": config.password
                  },
                  "body": payload
            })
        })
        .then( res => res.json())
        .catch ( err => this.error.handleError(err));
    }
}

export default Recommendations;
