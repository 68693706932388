import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

export default function useCacheDehydration() {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      const dehydratedCache = JSON.stringify(dehydrate(queryClient));
      localStorage.setItem("cache", dehydratedCache);
    };
  }, [queryClient]);

  return null;
}
