import React from "react";
import InputMask from "react-input-mask";
import styles from "./text-input.module.scss";

export default function PhoneNumberInput({
  name,
  label,
  className,
  onChange,
  onBlur,
  error,
  value,
  ...props
}) {
  return (
    <div className={`text-input ${className} ${error ? styles.error : ""}`}>
      <label className="form-control__label" htmlFor={name}>
        <span className={onBlur ? styles.required : ""}>{label}</span>
        {error && <span>{` -  ${error}`}</span>}
      </label>
      <InputMask
        mask="(999) 999-9999"
        className="input-outline form-control__input"
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
    </div>
  );
}
