import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import {CustomerService} from "./services/customer.service";
import {GuestService} from "./services/guest.service";
import Select from "./select/select.component";
import Loader from "./loader.component";
import Modal from "./modal/modal.component";
import {config} from "./configurations";
import {connect} from 'react-redux';
import {setError} from "../redux/user/actions";

const countryOptions = config.countryOptions;
const stateOptions = config.stateOptions;
const stateMap = config.stateMap;
const stateCodeMap = config.stateCodeMap;

class Shipping extends Component {
    constructor(props){
        super(props);
        this.state = {
            fullName: "",
			address: "",
			city: "",
			state: 0,
			zip: "",
            country: 0,
            type: '',
            email: '',
            phone: '',
            showModal: false,
            regions: [],
            shouldBlockNavigation: true
        }
        
        this.customer = new CustomerService();
    }
    componentDidMount() {
        if (this.props.location.state && this.props.location.state.url === '/account') this.setState({shouldBlockNavigation: false});
        let shippingAddress = JSON.parse(sessionStorage.getItem('shippingAddress'));
        if (shippingAddress && shippingAddress.sameAsBillingChecked) {
            this.setState({fullName: shippingAddress.fullName,
                address: shippingAddress.address,
                city: shippingAddress.city,
                state: shippingAddress.state,
                zip: shippingAddress.zip,
                country: shippingAddress.country,
                email: shippingAddress.email,
                phone: shippingAddress.phone
            });

        } else {
            this.customer.getCustomerBillingAddress()
            .then( res => {
                this.setState({
                    fullName: res.firstname && res.lastname?  res.firstname +' ' + res.lastname : '',
                    address: (res.street && res.street[0]) || '',
                    city: res.city,
                    state: res.region && res.region.region_code ? stateOptions.findIndex(option => option.value === res.region.region_code) : 0,
                    zip: res.postcode || "",
                    country: res.country_id ? countryOptions.findIndex(option => option.value === res.country_id) : 0,
                    type: 'customer',
                    email: '',
                    phone: res.telephone || ''
                })
            })
        }

       
    }

    componentWillUnmount() {
        this.props.setError('');
    }

    handleSubmitLogic = () => {
        let allowed = true;
        let emptyFields = '';
        Object.keys(this.state).forEach(key => {
            if (key !== 'companyName' && key !== 'apt' && this.state[key] === '' && key !== 'customerId' && key !== 'type') {
                allowed=false;
                emptyFields += key+', ';
            }
        });
        if (!allowed) {
            this.props.setError('The following mandatory fields are empty: ' + emptyFields);
            return;
        }
        this.setState({showModal: true});
        let payload = {
                "address": {
                    region: stateMap[stateOptions[this.state.state].value],
                    "region_id": stateCodeMap[stateOptions[this.state.state].value],
                    "region_code": stateOptions[this.state.state].value,
                    "country_id": countryOptions[this.state.country].value,
                    "street": [
                    this.state.address
                    ],
                    "postcode": this.state.zip,
                    "firstname": this.state.fullName.split(' ')[0],
                    "lastname": this.state.fullName.split(' ')[1]||'',
                    "city": this.state.city,
                    telephone: this.state.phone,
                    email: this.state.email
                }

            };

        let customer = new CustomerService();
        customer.getCustomer()
        .then(res => {
            if ( !!res.id ) {
                if (this.props.location.state && this.props.location.state.url === '/account') {
                    let method = this.props.location.state.hasAddress ? 'put' : 'post';
                    let address = {
                        "address": {
                                "region": {
                                    "region_code": stateOptions[this.state.state].value,
                                    "region": stateOptions[this.state.state].title,
                                    "region_id": stateCodeMap[stateOptions[this.state.state].value]
                                },
                                "countryId": countryOptions[this.state.country].value,
                                "street": [
                                this.state.address
                                ],
                                "telephone": this.state.phone,
                                "postcode": this.state.zip,
                                "firstname": this.state.fullName.split(' ')[0],
                                "lastname": this.state.fullName.split(' ')[1]||'',
                                "city": this.state.city,
    
                            "default_shipping": false,
                            "default_billing": true
                        }
                    }
                    if (method === 'put') address.address.id = this.props.location.state.addressId;
                    customer.updateCustomerAddress(address,method)
                    .then( res=> {
                        let url = this.props.location.state && this.props.location.state.url? this.props.location.state.url : "/order-review";
                        this.props.history.push(url);
                    })
                } else {
                    payload.useForShipping = false;
                    this.customer.setCustomerAddress('billing',payload)
                    .then( res => {
                        let url = this.props.location.state && this.props.location.state.url? this.props.location.state.url : "/order-review";
                        this.props.history.push(url);
                    })
                }

            }  else {
                payload.useForShipping = false;
                new GuestService(localStorage.getItem('guestCartId')).setGuestAddress('billing',payload)
                .then( res => this.props.history.push("/order-review"))
            }

        })
    }

    submitBilling = (e) => {
        e.preventDefault();
        this.setState({shouldBlockNavigation: false}, () => this.handleSubmitLogic())
       
    }

    render() {
        const {
            fullName,
			address,
			city,
			state,
			zip,
            country,
            email,
            phone
        } = this.state;

        return (
            <form className="form" onSubmit={this.submitBilling}>
                <Prompt
					when={this.state.shouldBlockNavigation}
					message={'Are you sure you want to leave?\nYou might lose your selected diamond'}
					/>
                {this.state.showModal && <Modal><Loader></Loader></Modal>}
                <div className="form__item">
                    <h4 className="title-1 form__title">
                        Billing
                    </h4>
                    <div className="form-control form__row">
                        <label className="form-control__label" htmlFor="full-name">
                            Full name
                        </label>
                        <input
                            type="text"
                            className="input-outline form-control__input"
                            id="full-name"
                            name="full-name"
                            value={fullName}
                            onChange={(e) => this.setState({
								fullName: e.target.value
                            })}
                        />
                    </div>
                    <div className="form-control form__row">
                        <label className="form-control__label" htmlFor="address">
                            Address
                        </label>
                        <input
                            type="text"
                            className="input-outline form-control__input"
                            id="address"
                            name="address"
                            value={address}
                            onChange={(e) => this.setState({
								address: e.target.value
							})}
                        />
                    </div>
                    <div className="form-control form__row">
                        <label className="form-control__label" htmlFor="city">
                            City
                        </label>
                        <input
                            type="text"
                            className="input-outline form-control__input"
                            id="city"
                            name="city"
                            value={city}
                            onChange={(e) => this.setState({
								city: e.target.value
							})}
                        />
                    </div>
                    <div className="form__row">
                        <div className="form-control form__item _state-1">
                            <label className="form-control__label">
                                Email
                            </label>
                            <input
                                type="text"
                                className="input-outline form-control__input"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => this.setState({
                                    email: e.target.value
                                })}
                            />
                        </div>
                        <div className="form-control form__item _zip-1">
                            <label className="form-control__label" htmlFor="phone">
                                Phone
                            </label>
                            <input
                                type="number"
                                className="input-outline form-control__input"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => this.setState({
									phone: e.target.value
								})}/>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form-control form__item _state-1">
                            <label className="form-control__label">
                                State
                            </label>
                            <Select
                                className="form-control__input"
                                name="state"
                                options={stateOptions}
                                activeIndex={state}
                                onChange={(e) => this.setState({
                                    state: stateOptions.findIndex(option => option.value === e.target.value)
                                })}
                            />
                        </div>
                        <div className="form-control form__item _zip-1">
                            <label className="form-control__label" htmlFor="zip">
                                Zip code
                            </label>
                            <input
                                type="number"
                                className="input-outline form-control__input"
                                id="zip"
                                name="zip"
                                value={zip}
                                onChange={(e) => this.setState({
									zip: e.target.value
								})}/>
                        </div>
                    </div>
                    <div className="form__row form-control">
                        <label className="form-control__label">
                            Country
                        </label>
                        <Select
                            className="form-control__input"
                            name="country"
                            options={countryOptions}
                            activeIndex={country}
                            onChange={(e) => this.setState({
								country: countryOptions.findIndex(option => option.value === e.target.value)
							})}
                        />
                    </div>
                </div>
                <div className="form__buttons">
                    <button type="submit" className="btn form__btn">
                        Continue
                    </button>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps ={
    setError
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Shipping));
