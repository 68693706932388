import React, {Component} from 'react';

import Collapse from "components/collapse/collapse.component";

class AccountMarkup extends Component {
	constructor(props){
		super();
	}

	render() {
		return (
			<section className="account">
				<div className="account__section">
					<h2 className="account__h2">
						My Account Dashboard
					</h2>
					<div className="account__orders">
						<div className="account__orders-list">
							{[
								{title: "Recent Orders", items: [0,1]},
								{title: "Order History", items: [0]},
							].map((list, i) => <React.Fragment>
								<h3 className="account__h3">
									{list.title}
								</h3>
								{i === 0 &&
									<div className="account__thead">
										{["order number", "date", "shipped to", "order total", "status"].map(i => (
											<div className="account__th">
												{i}
											</div>
										))}
									</div>
								}
								{list.items.map((item, i) => (
									<Collapse
										key={i}
										underLg
										className={`account__collapse ${i === 0 ? "_first" : ""}`}
										title={<header className="account__collapse-header">
											<button className="account__collapse-btn">
												Order #100032567
											</button>
										</header>}
										visibleContent={<div className="account__collapse-visible">
											<div className="account__param">
												<div className="account__param-key">
													Date
												</div>
												<div className="account__param-value">
													3/21/2019
												</div>
											</div>
										</div>}
									>
										<React.Fragment>
											<div className="account__param">
												<div className="account__param-key">
													Shipped to
												</div>
												<div className="account__param-value">
													Tom Valyer
												</div>
											</div>
											<div className="account__param">
												<div className="account__param-key">
													Order Total
												</div>
												<div className="account__param-value">
													$3,129
												</div>
											</div>
											<div className="account__param">
												<div className="account__param-key">
													Status
												</div>
												<div className="account__param-value">
													<div className="account__status _pending">
														Pending
													</div>
												</div>
											</div>
										</React.Fragment>
									</Collapse>
								))}
							</React.Fragment>)}
						</div>
					</div>
				</div>
				<div className="account__section _gray">
					<h2 className="account__h2">
						Account Info
					</h2>
					<div className="account__info">
						{[
							{title: "Contact Info", list: [
								"Haris Masood",
								"haris@nanowebgroup.com",
								<button>Change Password</button>,
							]},
							{title: "Default Billing address", list: [
								"Haris Masood",
								"NANO Web Group",
								"9707",
								"Alabama, 12312",
								"United States",
								"Phone: 310 876 3452",
								<button>Edit Address</button>,
							]},
							{title: "Default Shipping address", list: [
								"Haris Masood",
								"NANO Web Group",
								"9707",
								"Alabama, 12312",
								"United States",
								"Phone: 310 876 3452",
								<button>Edit Address</button>,
							]},
						].map((info, i) => (
							<div className="account__info-item">
								<h3 className="account__h3">
									{info.title}
								</h3>
								<p>
									{info.list.map((item, i) => (
										<span className="_d-b">
                                            {item}
                                        </span>
									))}
								</p>
							</div>
						))}
					</div>
				</div>
			</section>
		);
	}
}

export default AccountMarkup;
