import React, { useState } from "react";
import { string, number, bool, func, arrayOf, shape } from "prop-types";
import { AddToCartUtil } from "components/utils/addtocart.util";

const util = new AddToCartUtil();

export default function RingCard({
  name,
  label,
  img,
  price,
  colors,
  allowLike,
  ringClicked,
}) {
  const [isLike, setIsLike] = useState(false);

  const changeIsLikeState = (e) => {
    e.stopPropagation();
    setIsLike(!isLike);
  };

  return (
    <div className="good ring-good" onClick={ringClicked}>
      <div className="good-inner">
        <div className="ring-card-top-container">
          <div className="ring-card-top-label">{label}</div>
          {allowLike && (
            <div className="ring-card-top-icon">
              <img
                onClick={changeIsLikeState}
                height="20"
                width="20"
                src={
                  isLike
                    ? require("img/fill-heart.png").default
                    : require("img/empty-heart.png").default
                }
                alt="heartIcon"
              />
            </div>
          )}
        </div>
        <div className="good-pic">
          <img src={img} alt={`${label} Shaped of ring`} className="good-img" />
        </div>
        <div className="good-description">
          <div className="good-description_line -no-wrap">
            <div className="good-feature">{name}</div>
            <div className="good-feature_level">
              <div style={{ display: "flex" }}>
                {colors.map((color) => {
                  let colorClass = "gold";
                  if (color.label.toLowerCase() === "white gold") {
                    colorClass = "white-grey";
                  }
                  if (color.label.toLowerCase() === "rose gold") {
                    colorClass = "rose";
                  }
                  return (
                    <span key={colorClass} className={`dot ${colorClass}`} />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="good-description_line -no-wrap">
            <div className="good-price">
              {price && `$ ${util.priceFormatter(price)}`}
            </div>
            <div className="good-quality">
              <div>
                <button className="btn -width-xs -xs">See Details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RingCard.propTypes = {
  name: string,
  label: string,
  img: string,
  price: number,
  colors: arrayOf(
    shape({
      label: string,
      value_index: number,
    })
  ),
  allowLike: bool,
  ringClicked: func,
};
