export class JoolezInfoService {
    getDetails = (inp) => {
        return {            
            joolezPercent: 89,
            joolezNote: 'Better quality',
            remainingCompare: [
                {
                    name: 'Blue Nile',
                    width: 77
                },
                {
                    name: 'Zales',
                    width: 54
                },
                {
                    name: 'Rocher',
                    width: 63
                }
            ]
        }
    }
}
export default JoolezInfoService;