import {
    RING_ID_DETAILS,
    SET_CURRENT_RING_DETAILS,
    SET_RINGS_OPTIONS,
    SET_RING_INTO_CART,
} from './actions';

const defaultState = {
    ring_id: '',
    currentRingDetails: [],
    ringsOptions: [],
    ringSelected: {},
}

export const RingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RING_ID_DETAILS:
            return {
                ...state,
                ring_id: action.payload
            }
        case SET_CURRENT_RING_DETAILS:
            return {
                ...state,
                currentRingDetails: action.payload
            }
        case SET_RINGS_OPTIONS:
            return {
                ...state,
                ringsOptions: action.payload
            }
        case SET_RING_INTO_CART:
            return {
                ...state,
                ringSelected: action.payload
            }
        default:
            return state;
    }
}