import React from "react";
import styles from "./productDetailItems.module.scss";
import {diamondEducation} from "components/consts";
import Cut from "components/education.component/cut.component";
import Color from "components/education.component/color.component";
import Carat from "components/education.component/carat.component";
import Clarity from "components/education.component/clarity.component";

const ParamDescription = props => {
	const { type, el } = props;

	return (
		<div className={`${styles.paramDescription} _${type} product-details_item-description`}>
			<div className={styles.paramDescriptionLeft}>
				<header className={styles.paramDescriptionHeader}>
					<div
						className={`${styles.icon} product-details_icons`}
					>
						<span
							className={`${el.chargeIcon} _param-description product-details_level`}>
							<span/><span/><span/><span/>
						</span>
						<span className={`${el.iconClass1}`}></span>
					</div>
					<h4 className={styles.paramDescriptionTitle}>
						{diamondEducation[type].title}
					</h4>
				</header>
				<span className={styles.paramDescriptionSubtitle}>
					{diamondEducation[type].subtitle}
				</span>
				<p className={styles.paramDescriptionText}>
					{diamondEducation[type].text}
				</p>
			</div>
			<div className={styles.paramDescriptionRight}>
				{(() => {
					switch (type) {
						case 'cut':
							return <Cut className="_param-description" levelClassName="_param-description" yourDiamond={el.selected}/>;
						case 'color':
							return <Color className="_param-description" levelClassName="_param-description" yourDiamond={el.text}/>;
						case 'clarity':
							return <Clarity className="_param-description" levelClassName="_param-description" yourDiamond={el.selected}/>;
						case 'carat':
							return <Carat className="_param-description" levelClassName="_param-description"/>;
						default:
							return null;
					}
				})()}
			</div>
		</div>
	)
}


export default ParamDescription;