import React, {Component} from "react";
import {Range} from "react-range";

import styles from "./options.module.scss";

import ClosedOptions from "./closed-options/closed-options.component";

import {ReactComponent as DiamondIcon} from "img/education-icon.svg";
import {ReactComponent as CompareDiamondsIcon} from "img/comparison/compare-diamonds.svg";
import {ReactComponent as DiamondQuestionIcon} from "img/diamond-question.svg";
import {ReactComponent as DiamondHistoryIcon} from "img/common/diamond-history.svg";
import {ReactComponent as ArrowIcon} from "img/common/arrow-right-1.svg";

class Options extends Component {
	addToCompare = () => {
		this.props.addCompare();
	}

	getDiamondHistory = (data) => {
		this.props.diamondHistory(data);
	}

	showTour = () => {
		this.props.enableTour();
	}

	render() {
		const {
			choice = "",
			className = "",
			sections,
			list,
			moreOptionsEnabled,
			loading,
			betterDeal,
			stateOfProd: { diamond }
		} = this.props;

		return (
			<div className={`${styles.container} ${className}`}>
				{loading && <div className="product-param_name">
						<p>Getting next recommendation</p>
						<img className="loader" src={require("img/product-page/diamond-assistance.gif").default} alt="Diamond assistant"/>
					</div>
				}
				<div className="notice-instructions">
					<span>Use ⊝ and ⊕ to adjust price, size, and quality to find a <span className="good-deal">good deal</span>.</span>
				</div>
				{!loading && <h2 className="product-param_name">
					{diamond.diamond_id &&
						`${diamond.shape} ${diamond.size}ct ${diamond.color} ${diamond.clarity}`}
						{betterDeal && (<span className="badge">Good Deal {betterDeal}</span>)}
				</h2>}
				
				
				<ul className={styles.list}>
					{moreOptionsEnabled ? list.map(i => {
						let {
							title,
							color,
							min,
							max,
							step,
							values,
							inputs,
							scale,
						} = i;

						if(scale) {
							min = 0;
							max = scale.length;
							step = 1;
						}



						// range fill horizontal positions
						const left = values[0] / max * 100 + "%";
						const right = 100 - values[1] / max * 100 + "%";

						return (
							<li className={styles.item} key={title}>
								<h4 className={styles.title}>
									{title}
								</h4>
								<Range
									min={min}
									max={max}
									step={step}
									values={values}
									onChange={values => {
										i.values = values;
										let list = this.props.list;
										this.props.sendQuery(list,title, values);
									}}
									renderTrack={({props, children}) => (
										<div className={`${styles.rangeContainer} _${color}`}>
											<div
												className={`${styles.track}`}
												{...props}
												style={{...props.style}}
											>
												{children}
											</div>
											<div
												className={styles.fill}
												style={{
													left: left,
													right: right,
												}}
											></div>
										</div>
									)}
									renderThumb={({props}) => {
										return (
											<i className={`${styles.slider}`} {...props} style={{...props.style}}>
												<DiamondIcon/>
											</i>
										)
									}}
								/>

								{scale &&
									<ul className={styles.scale}>
										{scale.map(ii => (
											<li className={styles.scaleItem} key={ii}>
												{ii}
											</li>
										))}
									</ul>
								}

								{inputs &&
									<div className={`${styles.inputs}`}>
										<div className={styles.inputsWrapper}>
											{inputs.label &&
												<label className={styles.label}>
													{inputs.label}
												</label>
											}
											<input
												type="text"
												className={styles.input}
												value={values[0]}
												onChange={({target}) => {
													values[0] = target.value;
													let list = this.props.list;
													this.props.sendQuery(list);

												}}
											/>
										</div>

										<div className={styles.inputsWrapper}>
											{inputs.label &&
												<label className={styles.label}>
													{inputs.label}
												</label>
											}
											<input
												type="text"
												className={styles.input}
												value={values[1]}
												onChange={({target}) => {
													values[1] = target.value;
													let list = this.props.list;
													this.props.sendQuery(list);
												}}
											/>
										</div>
									</div>
								}
							</li>
						)})
						:
						<ClosedOptions
							loading={loading}
							sections={sections}
							reduce={this.props.reduce}
							increase={this.props.increase}
							stateOfProd={this.props.stateOfProd}
						/>
					}
				</ul>

				<div className={`product-params_buttons ${!moreOptionsEnabled ? "center-mobile" : ""}`}>
					{!moreOptionsEnabled &&
						<button
							className={`btn -xs -secondary visible-desktop ${this.props.addCompareActive ? "-active" : ""}`}
							onClick={this.addToCompare}
						>
							Add to compare
						</button>
					}

					<button
						className={`btn -xs -secondary`}
						onClick={() => this.props.handleMoreOptions()}
					>
						{moreOptionsEnabled ? "Close" : "More Options"}
					</button>

					{moreOptionsEnabled &&
						<button className={`btn -xs`} onClick={() => {
							this.props.handleMoreOptions();
							this.props.findNewDiamond();
						}}>
							Search
						</button>
					}
				</div>
				<div className="diamond-history-module">
					<p className="title">Diamond History</p>
					<div className="diamond-history_btns">
						<button className="previous" onClick={() => this.getDiamondHistory('back')} disabled={!this.props.enablePrevBtn}>
							<ArrowIcon/>
						</button>
						<span className="diamond-history_icon"><DiamondHistoryIcon/></span>
						<button className="next" onClick={() => this.getDiamondHistory('forward')} disabled={!this.props.enableNextBtn}>
							<ArrowIcon/>
						</button>
					</div>
				</div>
				<div className="product-params__right-buttons">
					<button className="product-params__right-btn product-info_question" onClick={this.showTour}>
						<DiamondQuestionIcon/>
					</button>
					<button className={`product-params__right-btn ctrl -compare product-info_compare ${this.props.diamondCompareIconClass}`}>
						<CompareDiamondsIcon/>
					</button>
				</div>
			</div>
		);
	}
}

export default Options;
