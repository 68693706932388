import React from "react";
import PropTypes from "prop-types";

export class SBHeader extends React.Component {
  //header used to be -centered, removing due to JR-72
    render() {
        return (
            <header className="page-header">
                  <h1 className="page-title">
                   {this.props.title}
                  </h1>
                  <div className="page-description">
                   {this.props.description}
                  </div>
                </header>
        )
    }
}
SBHeader.propTypes = {
    clickHandler: PropTypes.func,
  };
export default SBHeader;
