import React, { useState } from "react";
import SBParamSection from "components/shop.by.component/productDetails/paramSection.component";
import EducationModal from "../../productDetailItems/productEducationModal.component";

const ClosedOptions = ({
  sections,
  stateOfProd,
  increase,
  reduce,
  loading,
}) => {
  const [educationModal, setEducationModal] = useState(null);
  const closeEducationModal = () => setEducationModal(null);

  const quality = sections.find((section) => section.title === "Quality");
  const descriptions = quality && quality.descriptions;
  const details = descriptions
    ? descriptions.map((description) => ({
        ...description,
        text: description.param,
      }))
    : [];

  return (
    <>
      {sections.map((el, k) => (
        <SBParamSection
          type={el.type}
          description={el.description}
          percentage={el.percentage}
          title={el.title}
          value={el.value}
          lines={el.lines}
          descriptions={el.descriptions}
          key={"prodParam" + k}
          index={k}
          style={el.style}
          reduce={reduce}
          increase={increase}
          currency={el.currency}
          stateofprod={stateOfProd}
          loading={loading}
          openEducationModal={setEducationModal}
          closeEducationModal={closeEducationModal}
        />
      ))}
      <EducationModal
        educationModal={educationModal}
        handleModalClick={closeEducationModal}
        details={details}
      />
    </>
  );
};

export default ClosedOptions;
