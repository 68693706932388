import React from 'react';
import {Link} from 'react-router-dom';

import { ReactComponent as HowItWorksIcon1 } from "img/how-it-works/how-it-works-1.svg";
import { ReactComponent as HowItWorksIcon2 } from "img/how-it-works/how-it-works-2.svg";
import { ReactComponent as HowItWorksIcon3 } from "img/how-it-works/how-it-works-3.svg";
import { ReactComponent as DiamondIcon } from "img/education-icon.svg";


const howItWorksList = [
    {
        icon: <HowItWorksIcon1/>,
        text: "Joolez is a market place connecting diamond dealers and jewelry designers.",
    },
    {
		icon: <HowItWorksIcon2/>,
        text: "Our A.I makes recommendations based on whats important to you.",
    },
    {
		icon: <HowItWorksIcon3/>,
        text: "We deliver a complete diamond ring as unique as you are.",
    },
];

const HowItWorks = (props) => (
    <section className="how-it-works">
        <Link className="ctrl how-it-works__close" to="/">
            <span className="ico ico-Cross"></span>
        </Link>
        <h1 className="how-it-works__title">
            How It Works
        </h1>
        <ul className="how-it-works__list">
            {howItWorksList.map((item, i) => (
                <li className="how-it-works__item" key={i}>
                    <div className="how-it-works__number">
                        <DiamondIcon/>
                        <span>
                            {i+1}
                        </span>
                    </div>
                    <i className="how-it-works__icon">
                        {item.icon}
                    </i>
                    <p className="how-it-works__p">
                        {item.text}
                    </p>
                </li>
            ))}
        </ul>
        <Link className="btn how-it-works__btn" to="/">
            Got it
        </Link>
    </section>
)

export default HowItWorks;
