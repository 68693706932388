import { combineReducers } from "redux";
import { DiamondReducer } from "./diamond/reducers";
import { UserReducer } from "./user/reducers";
import { RingReducer } from "./ring/reducers";


export default combineReducers({
    diamond: DiamondReducer,
    user: UserReducer,
    ring: RingReducer,
})