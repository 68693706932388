import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux';
import {setDiamondId, setCart} from "redux/diamond/actions";

import styles from "./order-received.module.scss";

import {ReactComponent as CheckIcon} from "img/congratulations-1.svg";
import {ReactComponent as BowIcon} from "img/bow.svg";

import {ReactComponent as FacebookIcon} from "img/facebook.svg";
import {ReactComponent as TwitterIcon} from "img/twitter.svg";
import {ReactComponent as InstagramIcon} from "img/instagram.svg";
import {ReactComponent as LinkedinIcon} from "img/linkedin.svg";
import {gtmId} from "../consts";
import TagManager from "react-gtm-module";

const socialLinks = [
    { icon: <FacebookIcon/>, link: "https://www.facebook.com/joolezai" },
    { icon: <LinkedinIcon/>, link: "https://www.linkedin.com/company/joolez" },
    { icon: <TwitterIcon/>, link: "https://twitter.com/joolezai" },
    { icon: <InstagramIcon/>, link: "https://www.instagram.com/joolez.ai" },
];

export class OrderReceived extends Component {
    constructor(props) {
        super(props);
        this.state= {
            
        }
    }
    render() {
        const tagManagerViewArgs = {
            gtmId: gtmId,
            dataLayer: {
                event: 'transaction',
                eventLabel: 'Checkout Success View',
                ecommerce: {
                    purchase: {
                        actionField: {id: this.props.location.state.order_id, email: this.props.location.state.email},
                    },
                },


            },
        };
        TagManager.dataLayer(tagManagerViewArgs);
        return (
            <section className={styles.section}>
                <h1 className={styles.title}>
                    Your order has been received.
                </h1>
                <h2 className={styles.subtitle}>
                    Thank you for your purchase
                </h2>
                <i className={`${styles.icon} ${styles._1}`}>
                    <CheckIcon/>
                </i>
                <p className={styles.iconText}>
                    Payment successful
                </p>
                <i className={`${styles.icon} ${styles._2}`}>
                    <BowIcon/>
                </i>
                <p className={styles.p}>
                    You will receive an order confirmation email and a link will be sent for tracking once your order is processed and shipped.
                </p>
                <Link to="/" className={`btn ${styles.btn}`}>
                    Continue Shopping
                </Link>
                <div className={styles.social}>
                    <h4 className={styles.socialTitle}>
                        Follow us
                    </h4>
                    <div className={styles.socialList}>
                        {socialLinks.map((i,j) => (
                            <a href={i.link} rel="noopener noreferrer" target="_blank" className={styles.socialLink} key={'link_'+j}>
                                {i.icon}
                            </a>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    diamond: state.diamond,
    cart: state.diamond.cart,
    user_type: state.user.user_type
});

const mapDispatchToProps ={    
    setDiamondId,
    setCart
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReceived);