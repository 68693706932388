import React from "react";
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {DiamondSearch} from "../services/diamondsearch";
import {ComparedDiamond} from "../compared.diamond.component";
import {AddToCartUtil} from "../utils/addtocart.util";
import Modal from "../modal/modal.component";
import Loader from "../loader.component";
import {CartService} from "../services/cartService";
import {setCompareDiamonds, setDiamondId} from "../../redux/diamond/actions";
import {setError} from "../../redux/user/actions";
import {config} from "../configurations";
import Hashids from 'hashids'

export class DiamondsCompare extends React.Component {
	constructor(props) {
        super(props);
        this.iconMapping = config.goodsQualityIcons;
        this.cart = new AddToCartUtil();
        this.state= {
            showModal: false,
            redirect: false,
            diamonds: [],
            selectedId: ''
        }
	}

    componentDidMount() {
        if (this.props.selectedDiamonds.length !== 2 ) {
            this.props.setError('There were no diamonds passed');
            return;
        }
        new DiamondSearch().compareDiamonds(this.props.selectedDiamonds)
        .then( res => {
            if (res.length === 0 || !res) {
                this.props.setError('There were no diamonds passed');
                return;
            }
            this.prepareDetails(res.diamonds);

        })
    }

    componentWillUnmount() {
        this.props.setCompareDiamonds([]);
        this.props.setError('');
    }

    prepareDetails = (diamonds) => {
        let tranfsormedDiamons = [];
        diamonds.forEach(diamond => {
            diamond.diamond.details =[
                {
                    title: 'Cut',
                    text: diamond.diamond.cut || 'N/A',
                    iconClass1: 'ico ico-DiamondSimple -cut comparison__diamond',
                    chargeIcon: this.iconMapping[diamond.calculated_quality.cut],
										selected: diamond.calculated_quality.cut === 1 ? -1 : diamond.calculated_quality.cut - 1
                },
                {
                    title: 'Color',
                    text: diamond.diamond.color,
                    iconClass1: 'ico ico-DiamondSimple -color comparison__diamond',
                    chargeIcon: this.iconMapping[diamond.calculated_quality.color]
                },
                {
                    title: 'Clarity',
                    text: diamond.diamond.clarity,
                    iconClass1: 'ico ico-DiamondSimple -clarity comparison__diamond',
                    chargeIcon: this.iconMapping[diamond.calculated_quality.clarity],
										selected: diamond.calculated_quality.clarity -1
                },
                {
                    icons: 'product-details_icons-text',
                    iconText: diamond.diamond.size,
                    iconClass1: 'ico ico-DiamondSimple -carat comparison__diamond',
                    text: diamond.diamond.size,
                    title: 'Carat',
                    chargeIcon: this.iconMapping[diamond.calculated_quality.carat]

                }

              ];
              diamond.diamond.calculated_quality = diamond.calculated_quality;
							diamond.diamond.shape = diamond.diamond.shape.split(' ')[0];
              tranfsormedDiamons.push(diamond.diamond);
        });
        this.setState({diamonds: tranfsormedDiamons})
    }
    addToCart = (el) => {

        this.setState({showModal:true});
        let cart = new CartService();
        cart.getCustomerCart()
        .then( res => {
            if (res.id) {
                sessionStorage.cartId = res.id;
                cart.addDiamondToCart(res.id,el)
                .then( res => this.cartRedirect())

            } else {
            let guestId = localStorage.getItem('guestCartId');
            if (!!guestId) {
                cart.getGuestCart(guestId)
                .then( res => {
                    if (res.id) {
                    cart.addDiamondToCart(res.id,el)
                    .then( res => this.cartRedirect())


                    } else {
                    cart.createGuestCart()
                    .then( res => {
                        localStorage.guestCartId = res;
                        cart.getGuestCart(res)
                        .then( res => {
                        cart.addDiamondToCart(res.id,HTMLAudioElement)
                        .then( res => this.cartRedirect())
                        })
                    })
                    }

                })
            } else {
                cart.createGuestCart()
                    .then( res => {
                        localStorage.guestCartId = res;
                        cart.getGuestCart(res)
                        .then( res => {
                        cart.addDiamondToCart(res.id,el)
                        .then( res => this.cartRedirect())
                        })
                    })
            }

            }
        })
    }
	cartRedirect = () => {
        let selectedDiamond = this.state.diamonds.filter(el => el.diamond.diamond_id === this.state.selectedId)[0];
        this.props.sendDiamond({id:this.state.selectedId,
            price: selectedDiamond.diamond.total_sales_price_in_currency,
            size: selectedDiamond.diamond.size,
            shape: selectedDiamond.diamond.shape});
        this.setState({showModal:false,redirect:true});
    }

    renderDetailsPage = (el) => {
        this.props.setDiamondId(el);
        this.setState({selectedId: el,redirect:true});
    }

    renderRedirect = () => {
        if (this.state.redirect) {
          const hashids = new Hashids();
          let encoded = hashids.encode(this.state.selectedId);
          return <Redirect to={{
            pathname: '/product-details/'+encoded,
            state: {
                id: this.state.selectedId,
                accent: (this.props.location.state && this.props.location.state.features[this.state.selectedId]) || 'Joolez Recommend'
            }
        }} />
        }
      }

		handleBackClick = () => {
			this.props.history.goBack();
		}

	render() {
        let diamonds = this.state.diamonds.map((el, i) => (
            <ComparedDiamond
                className="comparison-item comparison__item"
                el={el}
                index={i}
                addToCart={this.renderDetailsPage}
                key={el.diamond_id}
								accent={this.props.location.state.features[this.props.location.state.ids[i]] || "Best Value"}
            />
        ));

		return (
			<React.Fragment>
                {this.renderRedirect()}
                {this.state.showModal && (
                    <Modal>
                        <Loader/>
                    </Modal>
                )}
								<h1 className="comparison__title">Comparison</h1>
                <div className="comparison">
									<button className="ctrl comparison__close" onClick={this.handleBackClick}>
										<span className="ico ico-Cross"></span>
									</button>
									{diamonds}
                </div>
            </React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
    selectedDiamonds: state.diamond.selected_diamonds
});

const mapDispatchToProps ={
    setCompareDiamonds,
    setError,
    setDiamondId
};

export default connect(mapStateToProps, mapDispatchToProps)(DiamondsCompare);
