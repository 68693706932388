import React from "react";
import { arrayOf, shape, string, bool } from "prop-types";
import Slider from "react-slick";
import { SBZoomSlider } from "components/shop.by.component/productDetails/imgSlider.withZoom.component";
import SimpleArrow from "components/slider/slider-simple-arrow.component";
import Loader from "components/loader.component";

export default function RingImageSlider({ images = [], loading }) {
  const itemsSliders = images?.map(({ url }, i) => (
    <SBZoomSlider
      key={i}
      className="slick-slider product-page__slider slick-pic-custom slick-slider-custom"
      imgs={[url]}
      noSlider
    />
  ));

  return (
    <div className="product-page__diamond ring-slider-container">
      {loading && images.length === 0 ? (
        <div className="slider-loader-container">
          <Loader />
        </div>
      ) : (
        <Slider
          adaptiveHeight
          dots
          dotsClass={"slick-dots-container slick-thumb"}
          customPaging={(i) => <img src={images[i].url} alt="thumbnail" />}
          infinite={false}
          slidesToShow={1}
          slidesToScroll={1}
          prevArrow={<SimpleArrow direction="left" to="prev" />}
          nextArrow={<SimpleArrow direction="right" to="next" />}
        >
          {itemsSliders}
        </Slider>
      )}
    </div>
  );
}

RingImageSlider.propTypes = {
  loading: bool,
  images: arrayOf(
    shape({
      url: string,
    })
  ),
};
