import React from "react";
import SBShapeSelector from "./sbshapeSelector.component";
import {config} from "../../configurations";
import TagManager from "react-gtm-module";
import {gtmId} from "../../consts";

const MOCK_SHAPE_SELECTOR = config.SHAPE_SELECTOR;

export class SBPriceFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            selectedShapes: this.props.shape
        }
    }

    editFilter = (data) => {
        this.setState({
            selectedShapes: data
        }, () => {
            this.props.setFilter(this.state.selectedShapes)
            const tagManagerArgs = {
                gtmId: gtmId,
                dataLayer: {
                    event: 'Choices - Change Shape',
                    shape: this.state.selectedShapes

                },
            }
            TagManager.dataLayer(tagManagerArgs);
        });


    }
    closeFilter = (e) => {
        this.props.closeFilter(false);
    }
    render() {
        let selection = MOCK_SHAPE_SELECTOR.map(shape => (
            <SBShapeSelector
                img={shape.img}
                srcSet={shape.srcSet}
                alt={shape.alt}
                shapeSelected={this.editFilter}
                key={shape.key}
                id={shape.key}
            />
        ));

        return(
            <div
                className="header-filter -open"
                style={this.props.filterSelection}
            >
                <div className="header-filter_inner">
                    <h4 className="header-filter_title">
                        Top 4 {/* / {this.props.total} */}
                    </h4>
                    <div className="header-filter_images">
                        <div className="header-filter_images-inner">
							{selection}
                        </div>
                        <div className="header-filter_close -over-md">
                            <button className="ctrl -md" onClick={this.closeFilter}>
                                <span className="ico ico-Cross"></span>
                            </button>
                        </div>
                    </div>
                    <div className="header-filter_close">
                        <button className="ctrl -md" onClick={this.closeFilter}>
                            <span className="ico ico-Cross"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default SBPriceFilter;
