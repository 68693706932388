import React from "react";

import EducationContainer from "./education.container.component";
import EducationInstructions from "./education-instructions.component";
import Cut from "components/education.component/cut.component";
import { ReactComponent as EducationIcon } from "../../img/education-icon.svg";

const EducationStep1 = (props) => (
	<EducationContainer
		footer={{
			button: {
				title: "Continue to color",
				onClick: () => props.setStep(2),
			},
			step: 1,
		}}
		toggleEducation={props.toggleEducation}
		setStep={props.setStep}
	>
		<div className="education__title">
			<div className="education-icon education__icon">
				<EducationIcon/>
				<span>?</span>
			</div>
			<h2 className="title-2">
				Cut
			</h2>
		</div>
		<p className="education__p _subtitle">
			How diamonds sparkle
		</p>
		<EducationInstructions />
		<div className="education__wrapper">
			<Cut className="education__type"/>
			<p className="education__p _cut">
				The better the cut the greater the stone’s brilliance. The better the cut, the better the diamond sparkles.
			</p>
		</div>
	</EducationContainer>
);

export default EducationStep1;
