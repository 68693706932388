import React from "react";

import {config} from "../../configurations";
import { ReactComponent as ArrowIcon } from "img/common/arrow-right.svg";

const MOCK_SHAPE_SELECTOR = config.SHAPE_SELECTOR;
export class SBPriceFilterClosed extends React.Component {
    getStateFromMock = (id) => {
       return MOCK_SHAPE_SELECTOR.filter(el => el.key === id)[0] || {};

    }
    filterClicked = (e) => {
        this.props.showExpandedFilter(e);
    }
    render() {
        const {
			//total,
			showFilter,
			shape,
        } = this.props;

        const image = this.getStateFromMock(shape);
        const srcSet = image.srcSet;
        const src = image.img;

        return(
            <div className="header-filter" style={showFilter}>
                <div className="header-filter_inner">
                    <h4 className="header-filter_title">
                        Top 4 {/* / {total} */}
                    </h4>
                    <button className="btn -sm -secondary header-filter_button" onClick={this.filterClicked}>
                        <span className="header-filter_button-text">
                            {this.getStateFromMock(shape).alt}
                        </span>
                        <img
                            src={srcSet ? srcSet[0] : src}
                            srcSet={srcSet && (srcSet[1] ? `${srcSet[1]} 2x` : "") + (srcSet[2] ? `, ${srcSet[2]} 3x` : "")}
                            alt={this.getStateFromMock(shape).alt}
                            className="header-filter_button-img"
                        />
                        <span className="ctrl -secondary header-filter_arrow">
                            <ArrowIcon/>
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}
export default SBPriceFilterClosed;
