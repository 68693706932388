import {config} from "../../components/configurations";
import {ErrorHandlingService} from "../services/errorHandling.service";
import {AuthorizedCallService} from "../services/authorizedcall.service";

export class CustomerService {

    constructor() {
        this.guestCartPrefix = '/V1/guest-carts/';
        this.cartsPrefix = '/V1/carts/';
        this.diamondPrefix = '/V1/diamondsearch';
        this.customersPrefix = '/V1/customers/';
        this.error = new ErrorHandlingService();
        this.customerCall = new AuthorizedCallService();
    }

    getCustomer = () => {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.customersPrefix +'me',{},'get')
        .then( res => res.json());
        // return fetch(config.magentoURL + this.customersPrefix +'me',{
        // })
        // .then( res => res.json())
        // .catch( err => this.error.handleError(err));
    }

    getCustomerAddress(type) {

        let addressLine = type === 'billing' ? 'billing-address' : 'shipping-methods';
        return this.customerCall.executeAuthorizedCall(config.magentoURL +this.cartsPrefix + 'mine/'+ addressLine,{},'get')
        .then( res => res.json());

        // return fetch(config.magentoURL + this.customersPrefix + 'me/'+ addressLine)
        // .then( res => res.json())
        // .catch( err => this.error.handleError(err));
    }

    setCustomerAddress(type,payload) {
        let addressLine = type === 'billing' ? 'billing-address' : 'shipping-information';
        return this.customerCall.executeAuthorizedCall(config.magentoURL +this.cartsPrefix + 'mine/'+ addressLine,payload,'post')
        .then( res => res.json());
        // return fetch(config.magentoURL +this.customersPrefix + 'me/'+ addressLine,{
        //     method: 'post',
        //     headers: {
        //         "Content-Type": "application/json",
        //         'Accept': 'application/json'
        //     },
        //     body: JSON.stringify(payload)
        // })
        // .then( res => res.json())
        // .catch( err => this.error.handleError(err));
    }

    customerLogin(login,passwd) {

        return fetch(config.magentoURL +'/V1/integration/customer/token',{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                username: login,
                password: passwd
            })
        })
        .then( res => res.json())
        .catch( err => this.error.handleError(err));
    }

    newCustomer(passwd, customer) {

        return fetch(config.magentoURL + this.customersPrefix,{
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json'
            },
            body: JSON.stringify({
               customer: customer,
               password: passwd
            })
        })
        .then( res => res.json())
        .catch( err => this.error.handleError(err));
    }

    getCustomerOrders(criteria) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/falcon/orders/mine?searchCriteria='+criteria,{},'get')
        .then( res => res.json())


    }

    getCustomerAccountAddress() {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/falcon/customers/me/address',{},'get')
        .then( res => res.json())
    }

    getCustomerBillingAddress() {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/customers/me/billingAddress',{},'get')
        .then ( res => res.json())
    }

    setCustomerBillingAddress(payload) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/customers/me/billingAddress',payload,'put')
        .then ( res => res.json())
    }

    updateCustomerAddress(payload,method) {
        //rest/V1/customers/me/address/
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/falcon/customers/me/address',payload,method)
        .then( res => res.json())
    }

    changePassword(oldPassword,newPassword) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + this.customersPrefix + 'me/password', {"currentPassword":oldPassword,
        "newPassword":newPassword}, 'put' )
        .then( res => res.json());
    }

    forgotPassword(email) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/customers/password', {"email":email,
        "template":"email_reset"}, 'put' )
        .then( res => res.json());
    }

    resetPassword(email, newPassword, resetToken) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/customers/resetPassword ', {"email":email,
        "resetToken":resetToken, "newPassword":newPassword}, 'post' )
        .then( res => res.json());
    }

    customerSignOut(id) {
        return this.customerCall.executeAuthorizedCall(config.magentoURL + '/V1/integration/customer/revoke',{customerId: 2},'post')
        .then(res => res.json())
    }


}

export default CustomerService;
